// @flow

import * as React from 'react';
import styled from 'styled-components';

import Spinner from './Spinner';

const ContentLoading = () => (
  <StyledContainer>
    <Spinner />
  </StyledContainer>
);

export default ContentLoading;

const StyledContainer = styled('div')`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
