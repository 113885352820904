// @flow

import type { Id } from '../../types';

import { rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Expander } from '../../components';
import { Color, Dimen } from '../../constants';
import MessageTemplateRow from './MessageTemplateRow';

type Props = $ReadOnly<{|
  messageTemplates: Array<{ name: string, id: Id }>,
  invalidate: () => void,
|}>;

export default function SectionMessageTemplates({
  messageTemplates,
  ...rest
}: Props) {
  return (
    <Expander title="Message Templates" noPadding>
      <StyledPadding>
        <p>
          Create up to 5 Message Templates to quickly send common responses to
          your guests.
        </p>
      </StyledPadding>
      <div>
        {messageTemplates
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((template) => (
            <MessageTemplateRow
              key={template.id}
              messageTemplate={template}
              {...rest}
            />
          ))}
      </div>
      {messageTemplates.length < 5 && (
        <StyledPadding>
          <Link to="/settings/message-templates/add">Add a new template</Link>
        </StyledPadding>
      )}
    </Expander>
  );
}

const StyledPadding = styled('div')`
  padding: ${rem(Dimen.spacing / 2)} ${rem(Dimen.spacing)};

  a {
    color: ${Color.vibrantBlue};
    font-size: ${rem(14)};
  }
`;
