// @flow

import type { Iri } from '../../types';

import { Api } from '@guesthug/core';
import { ellipsis, rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Modal, ModalChoice } from '../../components';
import { Color, Dimen } from '../../constants';

type Props = $ReadOnly<{|
  onSelect: (Iri) => mixed,
  selected: Iri | null,
  subTitle?: ?string,
  properties: Array<Api.Property.V_Mine>,
|}>;

export default function PropertySelect({
  selected,
  subTitle,
  onSelect,
  properties,
}: Props) {
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);

  let heading = 'Select a Listing';
  if (selected) {
    heading = properties.find((p) => p['@id'] === selected)?.name;
  }
  if (properties.length === 0) heading = 'No Listings';

  return (
    <>
      <AreaTop
        empty={!selected}
        noProperties={properties.length === 0}
        onClick={
          properties.length > 0 ? () => setModalVisible(true) : undefined
        }
      >
        <div>
          <strong>{heading}</strong>
          {subTitle && <span>{subTitle}</span>}
        </div>
        <img src={require('../../../assets/img/ui/arrow.svg')} />
      </AreaTop>

      {modalVisible && (
        <Modal
          title="Select a Listing"
          onDismiss={() => setModalVisible(false)}
        >
          {[...properties]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((property) => (
              <ModalChoice
                key={property['@id']}
                onClick={() => {
                  setModalVisible(false);
                  onSelect(property['@id']);
                }}
              >
                {property.name}
              </ModalChoice>
            ))}
        </Modal>
      )}
    </>
  );
}

const AreaTop = styled('a')`
  padding: ${rem(20)};
  border-bottom: 1px #e7ebf1 solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.25s;
  ${(p) => p.noProperties && 'cursor: default'};

  &:hover {
    ${(p) => !p.noProperties && 'background-color: #f3f3f3'};
  }

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  strong {
    font-weight: ${(p) => (p.empty ? 300 : 400)};
    font-size: ${rem(20)};
    display: block;
    margin-bottom: ${rem(Dimen.spacing / 4)};
    ${ellipsis()};
  }

  span {
    font-size: ${rem(14)};
    color: ${Color.faintBlue};
    font-weight: 300;
  }

  img {
    ${(p) => p.noProperties && 'display: none'};
    width: ${rem(18)};
    margin-left: ${rem(5)};
  }
`;
