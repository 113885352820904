// @flow

import { Domain } from '@guesthug/core/constants';
import * as Sentry from '@sentry/browser';

import * as config from './config';

export function capitalize(name: string): string {
  return name.charAt(0).toUpperCase() + name.slice(1);
}

export function calcChangePercent(current: number, last: number): number {
  let changePercent = 0;
  const change = current - last;
  if (change !== 0) {
    if (last === 0) {
      changePercent = 100; // infinite
    } else {
      changePercent = (change / last) * 100;
    }
  }
  return changePercent;
}

export const debounce = (func: Function, delay: number = 500) => {
  let timeout;
  // $FlowFixMe
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

// Like Object.entries but type hinted usefully
export function entries<T>(obj: { [key: string]: T }): Array<[string, T]> {
  return Object.keys(obj).map((k) => [k, obj[k]]);
}

export function currencySymbol(currency: string): string {
  return { usd: '$', eur: '€' }[currency] || '£';
}

/**
 * Format a positive price value.  Value is in pennies (100 = £1).
 */
export function formatPrice(
  price: number,
  currency: string,
  options: { round?: boolean } = { ...null }
): string {
  const symbol = currencySymbol(currency);
  return `${symbol}${(price / 100).toFixed(options.round ? 0 : 2)}`;
}

// https://developers.google.com/analytics/devguides/collection/analyticsjs/events
export function logAnalyticsEvent(
  category: string,
  action: string,
  label?: ?string,
  value?: ?number
): void {
  if (typeof window === 'undefined' || typeof window.ga === 'undefined') {
    return;
  }

  window.ga('send', 'event', category, action, label, value);
}

export function logError(
  err: { ... },
  type: string,
  options?: $ReadOnly<{|
    level?: 'fatal' | 'error' | 'warning' | 'info' | 'debug',
    extras?: {},
  |}>
) {
  const { level, extras } = options || {};
  if (config.APP_ENV === 'dev') {
    console.error(err); // eslint-disable-line no-console
    console.log('with options', type, options); // eslint-disable-line no-console
  } else {
    Sentry.withScope(function (scope) {
      scope.setExtra('type', type);
      level && scope.setLevel(level);
      extras &&
        Object.entries(extras).forEach(([key, val]) => {
          scope.setExtra(key, val);
        });
      Sentry.captureException(err);
    });
  }
}

export function placesPhotoUrl(photoRef: ?string): string | null {
  if (!photoRef) return null;

  // URLs can expire, so even though we used to support them, be safe here by
  // not displaying
  if (photoRef.indexOf('://') !== -1) return null;

  return `https://maps.googleapis.com/maps/api/place/photo?photoreference=${photoRef}&key=${Domain.googleKey}&maxwidth=500`;
}

export function unwrapMany<T>(items: ?$ReadOnlyArray<?T>): T[] {
  return (items || []).filter(Boolean);
}

export function unwrapConnection<T>(
  connection: ?{ +edges: ?$ReadOnlyArray<?{ +node: ?T }> }
): T[] {
  const edges = connection?.edges;
  if (!edges) return [];

  return edges.map((e) => e?.node).filter(Boolean);
}

// Like Object.values but type hinted usefully
export function values<T>(obj: { [key: string]: T }): T[] {
  return Object.keys(obj).map((k) => obj[k]);
}
