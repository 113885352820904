// @flow

import { Api } from '@guesthug/core';
import { Style } from '@guesthug/core';
import { Spacer } from '@guesthug/core/components';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { View } from 'react-native';

import {
  AddNewTile,
  ContentLoading,
  ImageTileList,
  ScreenError,
} from '../../../components';
import {
  AddPropertyModal,
  PropertiesItem,
  PropertiesModeSwitcher,
} from '../components';

export default function PropertiesTilesPage() {
  const [createModalVisible, setCreateModalVisible] =
    React.useState<boolean>(false);

  const api = Api.Property.useReadCollectionMine();

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ContentLoading />;
  const properties = api.data['hydra:member'].sort((a, b) => {
    if (a.isActive && !b.isActive) return -1;
    if (!a.isActive && b.isActive) return 1;

    if (a.isArchived && !b.isArchived) return 1;
    if (!a.isArchived && b.isArchived) return -1;

    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  });

  return (
    <>
      <Helmet>
        <title>Listings</title>
      </Helmet>
      {createModalVisible && (
        <AddPropertyModal
          onDismiss={() => setCreateModalVisible(false)}
          onSuccess={api.invalidate}
        />
      )}

      <View style={Style.alignEnd}>
        <PropertiesModeSwitcher current="tiles" />
      </View>
      <Spacer size={0.5} />
      <ImageTileList>
        <AddNewTile
          label="Add Property"
          onClick={() => setCreateModalVisible(true)}
          plusEffect
        />

        {properties.map((property) => (
          <PropertiesItem key={property.id} property={property} />
        ))}
      </ImageTileList>
    </>
  );
}
