// @flow

import type { Dispatch } from '../../types';

import queryString from 'query-string';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

type Props = $ReadOnly<{|
  location: Location,
|}>;

export default function OAuthLoginPage({ location }: Props) {
  const dispatch = useDispatch<Dispatch>();

  React.useEffect(() => {
    const { id, email, jwt } = queryString.parse(location.search);
    dispatch({ type: 'LOGIN_SUCCESS', payload: { id, email, jwt } });
  }, []);

  return <Redirect to="/" />;
}
