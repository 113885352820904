// @flow

import type { FieldProp } from 'react-typed-form';

import * as React from 'react';

import FieldInputStrict from './FieldInputStrict';

type Props = $ReadOnly<{|
  ...React.ElementConfig<typeof FieldInputStrict>,
  field: FieldProp<string | null>,
|}>;

export default function FieldInput({ field, ...rest }: Props) {
  return (
    <FieldInputStrict
      field={(field: any)}
      onChange={(ev) => {
        let value = ev.target.value;
        if (value === '') value = null;
        field.handleValueChange(value);
      }}
      {...rest}
    />
  );
}
