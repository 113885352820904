// @flow

import { Api, Color, Model, Style } from '@guesthug/core';
import { GHText, Spacer } from '@guesthug/core/components';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Image, Pressable, StyleSheet, View } from 'react-native';

import { Button, ContentLoading, ScreenError } from '../../../components';
import {
  AddPropertyModal,
  PropertiesModeSwitcher,
  PropertiesTableRow,
} from '../components';

function TableHeading({
  flex = 1,
  label,
  onPress,
  sortDir,
}: {
  flex?: number,
  label: string,
  onPress?: () => void,
  sortDir?: 'asc' | 'desc' | false,
}) {
  return (
    <View style={{ flex }}>
      <Pressable
        onPress={onPress}
        disabled={!onPress}
        style={[Style.row, Style.alignCenter]}
      >
        <GHText
          color={sortDir ? 'faint' : 'fainter'}
          size="large"
          selectable={false}
          style={styles.headingLabel}
        >
          {label}
        </GHText>
        {sortDir && (
          <>
            <Spacer size={0.5} />
            <Image
              source={require('../../../../assets/img/ui/arrow.svg')}
              style={[styles.arrow, sortDir === 'desc' && styles.arrowDesc]}
              resizeMode="contain"
            />
          </>
        )}
      </Pressable>
    </View>
  );
}

type SortKey = 'address' | 'airbnbId' | 'basePrice' | 'minStay';

export default function PropertiesTablePage() {
  const [createModalVisible, setCreateModalVisible] =
    React.useState<boolean>(false);

  const [sort, setSort] = React.useState<[SortKey, 'asc' | 'desc']>([
    'name',
    'asc',
  ]);

  const api = Api.Property.useReadCollectionMine({
    staleWhileInvalidated: true,
  });

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ContentLoading />;

  const properties = [...api.data['hydra:member']].sort((a, b) => {
    let result;
    if (sort[0] === 'name') {
      result = a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    } else if (sort[0] === 'address') {
      result = (
        Model.Property.getAddressParts(a)?.[0].toLowerCase() ?? ''
      ).localeCompare(
        Model.Property.getAddressParts(b)?.[0].toLowerCase() ?? ''
      );
    } else if (sort[0] === 'airbnbUrl') {
      result = (b.airbnbUrl ?? '').localeCompare(a.airbnbUrl ?? '');
    } else if (sort[0] === 'basePrice') {
      result = (a.baseRate ?? 0) - (b.baseRate ?? 0);
    } else if (sort[0] === 'minStay') {
      result = (a.minNights ?? 0) - (b.minNights ?? 0);
    } else if (sort[0] === 'connections') {
      result =
        b.channels.filter((c) => c.active).length -
        a.channels.filter((c) => c.active).length;
    } else if (sort[0] === 'status') {
      const statusWeight = (x: Api.Property.V_Mine) => {
        if (x.isArchived) return -1;
        return x.isActive ? 1 : 0;
      };
      result = statusWeight(b) - statusWeight(a);
    } else {
      (sort[0]: empty);
      throw new Error(`Unexpected sort: ${sort[0]}`);
    }

    return sort[1] === 'asc' ? result : result * -1;
  });

  const sortProps = (key: SortKey) => ({
    sortDir: sort[0] === key && sort[1],
    onPress: () =>
      setSort([key, sort[0] !== key || sort[1] === 'desc' ? 'asc' : 'desc']),
  });

  return (
    <>
      <Helmet>
        <title>Listings - Table</title>
      </Helmet>
      {createModalVisible && (
        <AddPropertyModal
          onDismiss={() => setCreateModalVisible(false)}
          onSuccess={api.invalidate}
        />
      )}

      <View style={[Style.row, Style.alignCenter]}>
        <Button
          label="Add Property"
          onPress={() => setCreateModalVisible(true)}
        />
        <Spacer fill />
        <PropertiesModeSwitcher current="table" />
      </View>
      <Spacer />

      <View style={styles.headingRow}>
        <TableHeading flex={2} label="Address" {...sortProps('name')} />
        <Spacer />
        {/* Swapped order with adove */}
        <View style={{ width: 140 }} />
        <Spacer />

        <TableHeading label="View Listing" {...sortProps('airbnbUrl')} />
        <Spacer />
        <TableHeading label="Base Price" {...sortProps('basePrice')} />
        <Spacer />
        <TableHeading label="Min Stay" {...sortProps('minStay')} />
        <Spacer />
        <TableHeading label="Status" {...sortProps('status')} />
        <Spacer />
        <TableHeading label="Connections" {...sortProps('connections')} />
      </View>
      {properties.map((property) => (
        <PropertiesTableRow
          key={property.id}
          property={property}
          invalidate={api.invalidate}
        />
      ))}
    </>
  );
}

const styles = StyleSheet.create({
  headingRow: {
    flexDirection: 'row',
    backgroundColor: '#fafbfd',
  },
  headingLabel: {
    ...({
      transitionProperty: 'color',
      transitionDuration: '150ms',
    }: any),
  },
  arrow: {
    width: 14,
    height: 12,
    ...({
      transitionProperty: 'transform',
      transitionDuration: '250ms',
    }: any),
  },
  arrowDesc: {
    transform: [{ rotate: '-180deg' }],
  },
});
