// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { ErrorList } from '../components';
import { Dimen } from '../constants';

type Props = $ReadOnly<{|
  errors?: string[],
|}>;

const FormErrorList = ({ errors }: Props) => (
  <StyledErrorList errors={errors} />
);

export default FormErrorList;

const StyledErrorList = styled(ErrorList)`
  margin-top: 0;
  margin-bottom: ${rem(Dimen.spacing / 2)};
`;
