// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { ContentLoading } from '../components';
import { Color } from '../constants';

const sizes = {
  small: 46,
  large: 88,
};

type Props = $ReadOnly<{|
  size: $Keys<typeof sizes>,
  user: { avatarImage: { squareSmallUrl: string, ... }, ... },
  className?: string,
  overrideSrc?: string | null,
  loading?: boolean,
|}>;

export default function Avatar({
  size,
  user,
  className,
  overrideSrc,
  loading,
}: Props) {
  const avatarPath = user.avatarImage && user.avatarImage.squareSmallUrl;

  let url = overrideSrc !== undefined ? overrideSrc : avatarPath;
  if (!url) url = require('../../assets/img/ui/avatar.png');

  return (
    <StyledAvatar
      size={sizes[size]}
      style={{
        backgroundImage: `url(${url})`,
      }}
      className={className}
    >
      {loading && (
        <div className="mask">
          <ContentLoading />
        </div>
      )}
    </StyledAvatar>
  );
}

const StyledAvatar = styled('div')`
  width: ${(p) => rem(p.size)};
  height: ${(p) => rem(p.size)};
  box-sizing: border-box;
  background-color: ${Color.imagePlaceholderBg};
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  border: ${rem(3)} solid #fff;
  box-shadow: 0 0 ${rem(8)} rgba(0, 0, 0, 0.25);
  position: relative;

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffffaa;
    display: flex;
  }
`;
