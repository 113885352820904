// @flow

import { Style, useApiFormSubmit } from '@guesthug/core';
import { GHText, Spacer } from '@guesthug/core/components';
import * as React from 'react';
import { Pressable, View } from 'react-native';
import { useForm } from 'react-typed-form';

import {
  FieldInput,
  FieldNumber,
  FormWrap,
  SubmitButton,
  createFormValidator,
} from '../../../form';
import FieldInputStrict from '../../../form/FieldInputStrict';

type Props = $ReadOnly<{|
  propertyId: string,
  start: string,
  end: string,
  invalidate: () => mixed,
  onCancel: () => void,
|}>;

export default function AddBookingForm({
  propertyId,
  start,
  end,
  invalidate,
  onCancel,
}: Props) {
  const onSubmit = useApiFormSubmit('POST', '/bookings', {
    onSuccess: invalidate,
  });

  const form = useForm<any>({
    defaultValues: {
      startDate: start,
      endDate: end,
      property: `/properties/${propertyId}`,
      providerGuestName: '',
    },
    validator: createFormValidator({
      providerGuestName: { strictPresence: true },
    }),
    onSubmit,
  });

  return (
    <FormWrap handleSubmit={form.handleSubmit}>
      <GHText size="large">Adding Direct Booking</GHText>
      <Spacer size={0.5} />

      <GHText size="small" color="faint">
        You will be able to invite your guest to this booking, but it won't be
        linked to any OTA channel.
      </GHText>
      <Spacer size={0.5} />

      <FieldInputStrict
        field={form.getField('providerGuestName')}
        label="Guest name"
        noMargin
      />
      <Spacer size={0.5} />
      <FieldNumber
        field={form.getField('numberOfGuest')}
        label="Number of guests"
        noMargin
      />
      <Spacer size={0.5} />
      <FieldInput field={form.getField('paymentMethod')} noMargin />
      <Spacer />
      <View style={[Style.row, Style.alignCenter]}>
        <SubmitButton isLoading={form.isLoading} noMargin />
        <Spacer size={0.5} />
        <Pressable onPress={onCancel}>
          <GHText size="small" color="faint">
            Cancel
          </GHText>
        </Pressable>
      </View>
    </FormWrap>
  );
}
