// @flow

import type { Dispatch, Id } from '../../types';
import type { LocalGuideType } from './typeHelpers';

import { Api } from '@guesthug/core';
import { useApiCallable } from '@guesthug/core';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { flashShow } from '../../actions/flash';
import { ContentLoading, ReturnLink, ScreenError } from '../../components';
import LocalGuideForm from './LocalGuideForm';

type Props = $ReadOnly<{|
  match: { params: { propertyId: Id, type: LocalGuideType } },
|}>;

export default function LocalGuideAddPage({ match }: Props) {
  const [created, setCreated] = React.useState<boolean>(false);

  const { propertyId, type } = match.params;
  const callApi = useApiCallable();
  const dispatch = useDispatch<Dispatch>();

  const apiProperty = Api.Property.useReadItem(propertyId);

  if (apiProperty.error) return <ScreenError {...apiProperty} />;
  if (!apiProperty.data) return <ContentLoading />;

  const property = apiProperty.data;

  if (created) {
    return <Redirect to={`/properties/${propertyId}/local-guide`} />;
  }

  const returnLink = (
    <ReturnLink to={`/properties/${propertyId}/local-guide`}>
      Return to Local Guide Overview
    </ReturnLink>
  );

  if (!property) {
    return <Redirect to={`/properties/${propertyId}/local-guide`} />;
  }

  return (
    <>
      <Helmet>
        <title>Add New Local Guide</title>
      </Helmet>
      {returnLink}
      <LocalGuideForm
        property={property}
        type={type}
        onSubmit={async (values, { addSubmitError, setLoading }) => {
          setLoading(true);
          const response = await callApi('/local-guides', {
            method: 'POST',
            jsonBody: {
              ...values,
              type,
              property: propertyId,
            },
          });
          if (response) {
            setCreated(true);
            dispatch(flashShow('Local Guide Added'));
          }
          setLoading(false);
        }}
      />
    </>
  );
}
