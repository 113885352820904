// @flow

import { Api } from '@guesthug/core';
import { iriToId } from '@guesthug/core/util';
import * as React from 'react';

import { ContentLoading, ScreenError } from '../../components';
import { useAuthUser } from '../../hooks';
import AnalyticsMain from './AnalyticsMain';

export default function AnalyticsPage() {
  const { id } = useAuthUser();

  const api = Api.Booking.useReadCollectionAnalytics();
  const apiUser = Api.User.useReadItem(id);

  if (api.error) return <ScreenError {...api} />;
  if (apiUser.error) return <ScreenError {...apiUser} />;
  if (!apiUser.data || !api.data) {
    return <ContentLoading />;
  }
  const bookings = api.data['hydra:member'];
  const user = apiUser.data;
  const { currency } = user;

  const properties = [];

  bookings.forEach((booking) => {
    const { property } = booking;
    if (!properties.some((p) => p.id === iriToId(property['@id']))) {
      properties.push({
        name: property.name,
        id: iriToId(property['@id']),
        image: property.propertyMainImage,
      });
    }
  });

  return (
    <AnalyticsMain
      currency={currency}
      bookings={bookings.sort((a, b) =>
        a.property.name.localeCompare(b.property.name)
      )}
      properties={properties.sort((a, b) => a.name.localeCompare(b.name))}
    />
  );
}
