// @flow

import type { CalendarApiPropertyCalendar } from '@guesthug/core';

import { Style, useApiCallable } from '@guesthug/core';
import { GHText, Line, PaddedArea, Spacer } from '@guesthug/core/components';
import { objectValues } from '@guesthug/core/util';
import {
  areIntervalsOverlapping,
  differenceInDays,
  parseISO,
  subDays,
} from 'date-fns';
import * as React from 'react';
import { Pressable, View } from 'react-native';
import { UntypedFormComponent } from 'react-typed-form';

import { Button } from '../../../components';
import {
  FieldMoney,
  FieldNumber,
  FieldSwitch,
  FormWrap,
  SubmitButton,
  createFormValidator,
} from '../../../form';
import FieldInputStrict from '../../../form/FieldInputStrict';
import AddBookingForm from './AddBookingForm';

type Props = $ReadOnly<{|
  start: string,
  end: string,
  propertyCalendar: CalendarApiPropertyCalendar,
  mode: 'minNights' | 'rate',
  invalidate: () => mixed,
|}>;

export default function PaneSelectedRangeLoaded({
  start,
  end,
  propertyCalendar,
  mode,
  invalidate,
}: Props) {
  const callApi = useApiCallable();

  const startDate = parseISO(start);
  const endDate = parseISO(end);
  const numDays = differenceInDays(endDate, startDate) + 1;

  const [addBookingFormShown, setAddBookingFormShown] =
    React.useState<boolean>(false);

  const allDates = objectValues(propertyCalendar.dates);
  const firstDate = objectValues(propertyCalendar.dates)[0];

  const overlapsBookingsForAdd = propertyCalendar.bookings.some(
    (b) =>
      !b.deletedAt &&
      areIntervalsOverlapping(
        { start: startDate, end: subDays(endDate, numDays > 1 ? 1 : 0) },
        { start: parseISO(b.startDate), end: subDays(parseISO(b.endDate), 1) },
        { inclusive: true }
      )
  );
  // As above, but don't sub a day from end of selection
  const overlapsBookingsForBlock = propertyCalendar.bookings.some(
    (b) =>
      !b.deletedAt &&
      areIntervalsOverlapping(
        { start: startDate, end: endDate },
        { start: parseISO(b.startDate), end: subDays(parseISO(b.endDate), 1) },
        { inclusive: true }
      )
  );

  const overlapsBlocked = allDates.some((date) => date.blocked);
  const allBlocked = allDates.every((date) => date.blocked);

  const defaultBlocked = allDates.reduce(
    (prev, date) => (date.blocked === prev ? prev : null),
    firstDate.blocked
  );

  const firstMinNights = firstDate.minNightsOverride
    ? firstDate.minNights
    : null;
  const defaultMinNights =
    firstMinNights &&
    objectValues(propertyCalendar.dates).every(
      (d) => d.minNightsOverride && d.minNights === firstMinNights
    )
      ? firstMinNights
      : undefined;

  const firstRate = firstDate.rateOverride ? firstDate.rate : null;
  const defaultRate =
    firstRate &&
    objectValues(propertyCalendar.dates).every(
      (d) => d.rateOverride && d.rate === firstRate
    )
      ? firstRate
      : null;

  const anyOverride = allDates.some((d) =>
    mode === 'rate' ? d.rateOverride : d.minNightsOverride
  );

  return (
    <View>
      {!overlapsBookingsForAdd && !overlapsBlocked && numDays > 1 && (
        <>
          <PaddedArea all>
            {addBookingFormShown ? (
              <AddBookingForm
                propertyId={propertyCalendar.propertyId}
                start={start}
                end={end}
                invalidate={invalidate}
                onCancel={() => setAddBookingFormShown(false)}
              />
            ) : (
              <Button
                colorScheme="darkBlue"
                label="Add Direct Booking"
                onPress={() => setAddBookingFormShown(true)}
              />
            )}
          </PaddedArea>
          <Line h />
        </>
      )}
      {!overlapsBookingsForBlock && !addBookingFormShown && (
        <>
          <PaddedArea all>
            <UntypedFormComponent
              defaultValues={{
                blocked: defaultBlocked,
              }}
              onSubmit={async (values, { setLoading }) => {
                setLoading(true);
                await callApi(
                  `/properties/${
                    propertyCalendar.propertyId
                  }/availability-block/${values.blocked ? 'create' : 'delete'}`,
                  {
                    method: 'POST',
                    jsonBody: { fromDate: start, toDate: end },
                  }
                );
                setLoading(false);
                invalidate();

                callApi(`/properties/${propertyCalendar.propertyId}/rad/push`, {
                  method: 'POST',
                  jsonBody: {},
                });
              }}
            >
              {({ getField, handleSubmit, isLoading }) => (
                <FormWrap handleSubmit={handleSubmit}>
                  <FieldSwitch
                    field={getField('blocked')}
                    description="Close property for specified dates"
                    noMargin
                  />
                  <Spacer size={0.5} />
                  <SubmitButton
                    isLoading={isLoading}
                    forceDisabled={defaultBlocked === getField('blocked').value}
                    noMargin
                  />
                </FormWrap>
              )}
            </UntypedFormComponent>
          </PaddedArea>
          <Line h />
        </>
      )}
      {!allBlocked && !addBookingFormShown && (
        <PaddedArea all>
          <UntypedFormComponent
            defaultValues={{
              minNights: defaultMinNights,
              rate: defaultRate,
            }}
            onSubmit={async (values, { setLoading }) => {
              setLoading(true);
              await callApi(
                `/properties/${propertyCalendar.propertyId}/manual-override/create?sentOnly=${mode}`,
                {
                  method: 'POST',
                  jsonBody: {
                    fromDate: start,
                    toDate: end,
                    ...(mode === 'rate'
                      ? { rate: values.rate }
                      : { minNights: values.minNights }),
                  },
                }
              );
              setLoading(false);
              invalidate();

              callApi(`/properties/${propertyCalendar.propertyId}/rad/push`, {
                method: 'POST',
                jsonBody: {},
              });
            }}
          >
            {({ getField, handleSubmit, isLoading, setLoading }) => (
              <FormWrap handleSubmit={handleSubmit}>
                {mode === 'rate' ? (
                  <FieldMoney
                    currency="gbp"
                    wholeNumbers
                    field={getField('rate')}
                    label="Price per night"
                    noMargin
                  />
                ) : (
                  <FieldNumber
                    field={getField('minNights')}
                    label="Minimum nights"
                    noMargin
                  />
                )}
                <Spacer />
                <View style={Style.alignStart}>
                  <SubmitButton
                    normalLabel="Save Override"
                    // Avoid changing when Delete is pressed
                    loadingLabel="Save Override"
                    isLoading={isLoading}
                    forceDisabled={
                      mode === 'rate'
                        ? defaultRate === getField('rate').value ||
                          !getField('rate').value
                        : defaultRate === getField('minNights').value ||
                          !getField('minNights').value
                    }
                    noMargin
                  />
                  {anyOverride && (
                    <>
                      <Spacer size={0.5} />
                      <Button
                        label="Clear Override"
                        colorScheme="red"
                        disabled={isLoading}
                        onPress={async () => {
                          setLoading(true);
                          await callApi(
                            `/properties/${propertyCalendar.propertyId}/manual-override/delete`,
                            {
                              method: 'POST',
                              jsonBody: {
                                fromDate: start,
                                toDate: end,
                              },
                            }
                          );
                          setLoading(false);
                          invalidate();

                          callApi(
                            `/properties/${propertyCalendar.propertyId}/rad/push`,
                            {
                              method: 'POST',
                              jsonBody: {},
                            }
                          );
                        }}
                      />
                    </>
                  )}
                </View>
              </FormWrap>
            )}
          </UntypedFormComponent>
        </PaddedArea>
      )}
    </View>
  );
}
