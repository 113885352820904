// @flow

import type { UserState } from '../reducers/auth.js';
import type { RootState } from '../types';

import { useSelector } from 'react-redux';

export default function useAuthUser(): UserState {
  const { user } = useSelector<RootState, _>((state) => state.auth);
  if (!user) {
    throw new Error(
      'useAuthUser should only be called within a navigator which guarantees auth'
    );
  }
  return user;
}
