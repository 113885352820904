// @flow

import type { Id } from '../../types';

import { Api } from '@guesthug/core';
import { useApiCallable } from '@guesthug/core';
import * as React from 'react';
import { UntypedFormComponent } from 'react-typed-form';

import { Expander, Spacer } from '../../components';
import {
  FieldSelect,
  FormWrap,
  SubmitButton,
  createFormValidator,
} from '../../form';

type Props = $ReadOnly<{|
  copyableProperties: Array<Api.Property.V_Mine>,
  propertyId: Id,
|}>;

export default function CopyFromExistingPrompt({
  copyableProperties,
  propertyId,
}: Props) {
  const callApi = useApiCallable();
  return (
    <>
      <Spacer />
      <Expander title="Copy Local Guide from another Listing">
        <p>
          Select one of your other properties to copy over all existing Local
          Guide entries, which can be used as a starting point for further
          editing.
        </p>

        <UntypedFormComponent
          validator={createFormValidator({
            propertyId: {
              strictPresence: {
                mustDefine: true,
                message: '^You must select a listing',
              },
            },
          })}
          onSubmit={async (values, { setLoading }) => {
            if (
              confirm(
                'Are you sure you want to copy all Local Guide entries?  This can only be done once, and cannot be reversed.'
              )
            ) {
              setLoading(true);
              const response = await callApi(
                `/copy-local-guides/${values.propertyId}/${propertyId}`,
                {
                  method: 'POST',
                  jsonBody: values,
                }
              );
              if (response) {
                window.location.reload();
              }
            }
          }}
        >
          {({ getField, handleSubmit, isLoading }) => (
            <FormWrap handleSubmit={handleSubmit}>
              <FieldSelect
                field={{
                  ...getField('propertyId'),
                  label: 'Property',
                }}
                choices={copyableProperties.map((copyableProperty) => ({
                  value: copyableProperty.id,
                  label: copyableProperty.name,
                }))}
              />

              <SubmitButton
                isLoading={isLoading}
                normalLabel="Copy"
                loadingLabel="Copying"
              />
            </FormWrap>
          )}
        </UntypedFormComponent>
      </Expander>
    </>
  );
}
