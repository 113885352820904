// @flow

import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  formatISO,
  isBefore,
  isSameMonth,
  isToday,
  parseISO,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { lighten, rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  currentDate: string, // 'yyyy-MM-dd'
  span?: 'month' | 'week',
  large?: boolean,
  className?: string,
  events?: $ReadOnlyArray<{|
    start: string,
    end: string,
    onClick?: () => mixed,
  |}>,
  showTrails?: boolean,
  markerType?: 'circle' | 'dot',
  showToday?: boolean,
|}>;

export default function ClassicCalendar({
  currentDate,
  span = 'month',
  large,
  className,
  events = [],
  showTrails = true,
  markerType = 'circle',
  showToday = false,
}: Props) {
  const weekOptions = { weekStartsOn: 1 }; // Monday

  const currentStart =
    span === 'week'
      ? startOfWeek(parseISO(currentDate), weekOptions)
      : startOfMonth(parseISO(currentDate));
  const currentEnd =
    span === 'week'
      ? endOfWeek(currentStart, weekOptions)
      : endOfMonth(currentStart);
  let current = currentStart;

  const weeks = [];
  current = startOfWeek(currentStart, weekOptions);
  while (isBefore(startOfWeek(current, weekOptions), currentEnd)) {
    const weekEnd = endOfWeek(current, weekOptions);
    const week = [];
    while (isBefore(current, weekEnd)) {
      week.push(current);
      current = addDays(current, 1);
    }
    weeks.push(week);
  }

  let currentEvent = null;
  return (
    <StyledContainer large={large} className={className}>
      <StyledDayHeadings>
        {'Mon|Tue|Wed|Thu|Fri|Sat|Sun'.split('|').map((day) => (
          <span key={day}>{day}</span>
        ))}
      </StyledDayHeadings>
      <StyledDaysGroup>
        {weeks.map((week, wi) => (
          <div key={wi}>
            {week.map((day, di) => {
              const dateStamp = formatISO(day, { representation: 'date' });
              const eventEnding = events.find((e) => e.end === dateStamp);
              const trailBefore = currentEvent;
              if (eventEnding) currentEvent = null;

              const eventStarting = events.find((e) => e.start === dateStamp);
              const trailAfter = currentEvent || eventStarting;
              currentEvent = currentEvent || eventStarting;

              const clickableEvent = currentEvent || trailBefore;
              const marked = !!(eventEnding || eventStarting);
              const circled =
                (showToday && isToday(day)) ||
                (markerType === 'circle' && marked);
              const dotted = markerType === 'dot' && marked;

              const withTrail = (content: React.Node) =>
                showTrails ? (
                  <StyledTrail
                    trailBefore={trailBefore}
                    trailAfter={trailAfter}
                    large={large}
                  >
                    {content}
                  </StyledTrail>
                ) : (
                  content
                );

              return (
                <StyledDay
                  key={di}
                  otherMonth={
                    span === 'month' && !isSameMonth(day, currentStart)
                  }
                  marked={circled}
                  onClick={clickableEvent && clickableEvent.onClick}
                  large={large}
                >
                  {withTrail(
                    <StyledMarker
                      large={large}
                      hasDots={markerType === 'dot' && events.length > 0}
                      circled={circled}
                      dotted={dotted}
                    >
                      <span>{format(day, 'd')}</span>
                    </StyledMarker>
                  )}
                </StyledDay>
              );
            })}
          </div>
        ))}
      </StyledDaysGroup>
    </StyledContainer>
  );
}

const MARKER_SIZE = (p) => (p.large ? 56 : 34);

const StyledContainer = styled('div')`
  display: table;
  width: 100%;
  min-width: ${(p) => rem(p.large ? 340 : 260)};
  max-width: ${(p) => rem(p.large ? 1000 : 400)};
`;

const StyledDayHeadings = styled('div')`
  display: table-row;

  > span {
    display: table-cell;
    font-size: ${rem(12)};
    color: #849bba;
    text-align: center;
    height: ${rem(32)};
    line-height: ${rem(32)};
  }
`;

const StyledDaysGroup = styled('div')`
  display: table-row-group;

  > div {
    display: table-row;
  }
`;

const StyledDay = styled('div')`
  display: table-cell;
  text-align: center;
  height: ${(p) => rem(p.large ? 70 : 38)};
  position: relative;
  ${(p) => p.otherMonth && 'color: #849bba'};
  user-select: none;
  ${(p) => p.large && `font-size: ${rem(24)}`};
  ${(p) => p.large && 'font-weight: 300'};

  ${(p) =>
    p.onClick &&
    `
    cursor: pointer;
    &:hover {
      span {
        color: ${p.marked ? lighten(0.2, '#ee4640') : '#ee4640'};
      }
    }
  `};

  span {
    position: relative;
    z-index: 3;
    transition: all 0.25s;
  }
`;

const StyledMarker = styled('div')`
  width: ${(p) => rem(MARKER_SIZE(p))};
  height: ${(p) => rem(MARKER_SIZE(p))};
  position: relative;
  margin: 0 auto;
  line-height: ${(p) => rem(MARKER_SIZE(p))};

  ${(p) =>
    p.circled &&
    `
    color: #fff;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50%;
      background-color: #3389ff;
      z-index: 1;
      ${p.hasDots && `transform: translateY(${rem(4)})`};
    }
  `};

  ${(p) =>
    p.dotted &&
    `
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      bottom: ${rem(-1)};
      transform: translateX(-50%);
      width: ${rem(6)};
      height ${rem(6)};
      border-radius: 50%;
      background-color: ${p.circled ? '#fff' : '#3389ff'};
      z-index: 2;
    }
  `};
`;

const StyledTrail = styled('div')`
  height: ${(p) => rem(MARKER_SIZE(p))};
  position: relative;
  width: 100%;

  ${(p) =>
    p.trailBefore &&
    `
    &::before {
      content: '';
      display: block;
      background: #ccdcef;
      position: absolute;
      top: 0;
      left: 0;
      right: 50%;
      bottom: 0;
    }
  `};

  ${(p) =>
    p.trailAfter &&
    `
    &::after {
      content: '';
      display: block;
      background: #ccdcef;
      position: absolute;
      top: 0;
      left: 50%;
      right: 0;
      bottom: 0;
    }
  `};
`;
