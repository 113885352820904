// @flow

import { loadCoreConfig } from '@guesthug/core';

// These have to be written out in full for webpack.DefinePlugin to work
export const APP_ENV: 'dev' | 'staging' | 'production' = (function (v) {
  if (v !== 'dev' && v !== 'staging' && v !== 'production') {
    throw new Error(`Invalid env var APP_ENV: ${v}`);
  }
  return v;
})((process.env.APP_ENV: any));

(process.env.APP_ENV: any);
export const DEEP_LINK_ROOT: string = (process.env.DEEP_LINK_ROOT: any);
export const MARKETING_ROOT: string = (process.env.MARKETING_ROOT: any);
export const SERVER_ROOT: string = (process.env.SERVER_ROOT: any);

loadCoreConfig({
  APP_ID: 'host-web',
  APP_ENV,
  SERVER_ROOT,
});
