// @flow

import type { Action } from '../types';

export type VersionsState = {
  auth: number,
  data: number,
};

const initialState: VersionsState = {
  auth: 0,
  data: 0,
};

/**
 * Keep track of internal "schema" vresions, so we can clear the store when it
 * needs to be reloaded from scratch.
 *
 * A data change will try and leave auth data intact.  An auth change will
 * completely wipe the store.
 */
export default function versions(
  state: VersionsState = initialState,
  action: Action
): VersionsState {
  switch (action.type) {
    case 'UPDATE_VERSIONS':
      return action.payload;
    default:
      return state;
  }
}
