// @flow

import type { Id } from '../../types';

import { useApiCallable } from '@guesthug/core';
import { useInvalidation } from 'api-read-hook';
import { rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Modal } from '../../components';
import { Color, Dimen } from '../../constants';
import { SubmitButton } from '../../form';

type Props = $ReadOnly<{|
  messageTemplate: { name: string, id: Id },
  invalidate: () => void,
|}>;

export default function MessageTemplateRow({
  messageTemplate,
  invalidate,
}: Props) {
  const callApi = useApiCallable();
  const { invalidateMatching } = useInvalidation();

  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] =
    React.useState<boolean>(false);

  return (
    <>
      {deleteModalVisible && (
        <Modal
          title={`Really delete ${messageTemplate.name}?`}
          padded
          onDismiss={() => setDeleteModalVisible(false)}
        >
          <StyledSubmitButton
            isLoading={isDeleting}
            normalLabel="Delete Template"
            loadingLabel="Deleting"
            onClick={async () => {
              setIsDeleting(true);
              await callApi(`/message-templates/${messageTemplate.id}`, {
                method: 'DELETE',
              });
              setIsDeleting(false);
              invalidate();
              setDeleteModalVisible(false);
              invalidateMatching('/users');
            }}
          />
        </Modal>
      )}

      <StyledRow>
        <StyledTop>{messageTemplate.name}</StyledTop>
        <StyledBottom>
          <Link to={`/settings/message-templates/${messageTemplate.id}`}>
            Edit
          </Link>{' '}
          <a onClick={() => setDeleteModalVisible(true)}>Delete</a>
        </StyledBottom>
      </StyledRow>
    </>
  );
}

const StyledRow = styled('div')`
  padding: ${rem(Dimen.spacing / 2)} ${rem(Dimen.spacing)};
  border: 0 #e7ebf1 solid;
  border-top-width: 1px;

  &:last-child {
    border-bottom-width: 1px;
  }
`;

const StyledTop = styled('div')`
  margin-bottom: ${rem(Dimen.spacing / 8)};
`;

const StyledBottom = styled('div')`
  display: flex;
  align-items: center;
  color: ${Color.faintBlue};
  font-size: ${rem(14)};

  a {
    margin-right: ${rem(Dimen.spacing / 2)};
    color: ${Color.vibrantBlue};
  }
`;

const StyledSubmitButton = styled(SubmitButton)`
  margin: 0;
  align-self: flex-start;
`;
