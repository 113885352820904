// @flow

import { rem } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default styled(Link)`
  display: block;
  font-weight: 300;
  font-size: ${rem(14)};
  color: #3389ff;
`;
