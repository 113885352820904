// @flow

import type { ViewStyleProp } from '../../../types';

import { GHText, IconChevron } from '@guesthug/core/components';
import * as React from 'react';
import { Pressable, StyleSheet } from 'react-native';

import { Color } from '../../../constants';

type Props = $ReadOnly<{|
  dir: 'left' | 'right',
  onPress: () => void,
  style?: ViewStyleProp,
|}>;

export default function ChevronButton({ dir, onPress, style }: Props) {
  return (
    <Pressable
      onPress={onPress}
      style={({ hovered }: any) => [
        styles.chevronButton,
        hovered && styles.chevronButtonHovered,
        style,
      ]}
    >
      <IconChevron dir={dir} />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  chevronButton: {
    width: 42,
    height: 42,
    marginHorizontal: -2,
    borderRadius: 24,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    ...({
      transitionProperty: 'background-color',
      transitionDuration: '250ms',
    }: any),
  },
  chevronButtonHovered: {
    backgroundColor: `${Color.vibrantBlue}11`,
  },
});
