// @flow

import type { Dispatch } from '../../../types';

import { Api } from '@guesthug/core';
import { useApiCallable } from '@guesthug/core';
import { Domain } from '@guesthug/core/constants';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { UntypedFormComponent } from 'react-typed-form';

import { flashShow } from '../../../actions/flash';
import { Expander, Hr } from '../../../components';
import {
  FieldInput,
  FieldSwitch,
  FieldYouTubeId,
  FormWrap,
  GroupPairFields,
  SubmitButton,
} from '../../../form';

type Props = $ReadOnly<{|
  property: Api.Property.V_Full,
  complete: boolean,
  invalidate: () => void,
|}>;

export default function SectionWelcomeMessage({
  property,
  complete,
  invalidate,
}: Props) {
  const callApi = useApiCallable();
  const dispatch = useDispatch<Dispatch>();
  return (
    <Expander title="Welcome Message" complete={complete}>
      <p>
        Upload your welcome video and welcome message below. We know guest's
        love it so don't be shy.
      </p>
      <UntypedFormComponent
        pristineValues={{
          ...property,
          welcomeMessage:
            property.welcomeMessage || Domain.defaultWelcomeMessage,
          autoMessageContent:
            property.autoMessageContent || Domain.defaultAutoMessage,
        }}
        onSubmit={async (values, { addSubmitError, setLoading }) => {
          setLoading(true);
          const response = await callApi(`/properties/${property.id}`, {
            method: 'PUT',
            jsonBody: values,
          });
          if (response) {
            dispatch(flashShow('Changes saved'));
            invalidate();
          }
          setLoading(false);
        }}
      >
        {({ getField, handleSubmit, isLoading }) => (
          <FormWrap handleSubmit={handleSubmit}>
            <GroupPairFields
              left={
                <FieldYouTubeId
                  field={{
                    ...getField('welcomeMessageYoutubeId'),
                    label: 'Video',
                  }}
                />
              }
              right={
                <FieldInput field={getField('welcomeMessage')} multiline />
              }
              flexRight={2}
            />
            <Hr />
            <GroupPairFields
              left={
                <FieldSwitch
                  field={getField('autoMessageEnabled')}
                  description="Send a message automatically to guest's inbox when they accept
                the booking invite."
                  alignSideBySide
                />
              }
              right={
                getField('autoMessageEnabled').value ? (
                  <FieldInput
                    field={getField('autoMessageContent')}
                    multiline
                  />
                ) : undefined
              }
              flexRight={2}
            />
            <SubmitButton isLoading={isLoading} />
          </FormWrap>
        )}
      </UntypedFormComponent>
    </Expander>
  );
}
