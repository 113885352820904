// @flow

import { Api } from '@guesthug/core';
import * as React from 'react';
import styled from 'styled-components';

import { CoverImage, ImageTile } from '../../components';

type Props = $ReadOnly<{|
  homeGuide: Api.HomeGuide.BaseView,
  propertyId: string,
|}>;

export default function HomeGuideListTile({ homeGuide, propertyId }: Props) {
  return (
    <ImageTile
      to={`/properties/${propertyId}/home-guide/${homeGuide.id}`}
      image={homeGuide.image && <StyledImage src={homeGuide.image.mediumUrl} />}
      caption={homeGuide.title}
      hoverLabel="Edit Guide"
    />
  );
}

const StyledImage = styled(CoverImage)`
  height: 100%;
`;
