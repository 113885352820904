// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Modal, TextInput } from '../components';
import { Color, Dimen } from '../constants';
import SubmitButton from './SubmitButton';

type Props = $ReadOnly<{|
  onChange: (value: string | null) => mixed,
  onDismiss: () => mixed,
|}>;

type State = {|
  input: string,
|};

export default class YouTubeModal extends React.PureComponent<Props, State> {
  state = {
    input: '',
  };

  _currentValue() {
    const { input } = this.state;
    if (typeof input !== 'string') return null;

    const check = input.trim();

    let matches = check.match(/^([^"&?/ ]{11})$/i);
    if (!matches) {
      matches = check.match(
        /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/i
      );
    }
    if (!matches) return null;

    return matches[1];
  }

  render() {
    const { onChange, onDismiss } = this.props;
    const value = this._currentValue();

    return (
      <Modal title="YouTube ID" onDismiss={onDismiss}>
        <StyledAreaForm>
          <TextInput
            label="Paste a YouTube ID or URL"
            onChange={(e) => {
              this.setState({ input: e.target.value });
            }}
          />
          <StyledPreview>
            YouTube ID: <span>{value || '(none)'}</span>
          </StyledPreview>
        </StyledAreaForm>
        <StyledSubmitButton
          onClick={() => {
            onChange(this._currentValue());
            onDismiss();
          }}
          isLoading={false}
        />
      </Modal>
    );
  }
}

const StyledAreaForm = styled('div')`
  padding: ${rem(Dimen.spacing / 2)} ${rem(Dimen.spacing)};
  border-bottom: 1px #e7ebf1 solid;
`;

const StyledPreview = styled('div')`
  flex-direction: row;
  align-items: center;
  margin: ${rem(Dimen.spacing / 2)};

  span {
    color: ${Color.faintBlue};
  }
`;

const StyledSubmitButton = styled(SubmitButton)`
  margin: ${rem(Dimen.spacing)};
  align-self: flex-start;
`;
