// @flow

import { Domain } from '@guesthug/core/constants';
import { rem } from 'polished';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { createGlobalStyle } from 'styled-components';

type Props = $ReadOnly<{|
  children: React.Node,
|}>;

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }

  body {
    background-color: #f3f3f3;
  }

  body, input, button, select, textarea {
    font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #253745;
    line-height: 1.3;
    font-size: ${rem(16)};
    font-weight: 400;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    outline: 0;
  }

  dd, dt {
    margin: 0;
    padding: 0;
  }

  input, textarea, select, div {
    outline: 0;
  }
`;

const LayoutBase = ({ children }: Props) => (
  <React.Fragment>
    <Helmet titleTemplate="%s | GuestHug Portal">
      <link
        href="https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700"
        rel="stylesheet"
      />
      {[196, 64, 32, 16].map((size) => (
        <link
          key={size}
          rel="icon"
          type="image/png"
          sizes={`${size}x${size}`}
          href={require(`../../assets/img/favicon/favicon-${size}.png`)}
        />
      ))}
      <script
        type="text/javascript"
        src={`https://maps.googleapis.com/maps/api/js?key=${Domain.googleKey}&libraries=places`}
      />
    </Helmet>
    <GlobalStyle />
    {children}
  </React.Fragment>
);

export default LayoutBase;
