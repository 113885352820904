// @flow

import type { Iri } from '../../types';

import { Api } from '@guesthug/core';
import { rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { EmptyMessageBlock } from '../../components';
import { Color } from '../../constants';
import DashboardBox from './DashboardBox';
import PropertySelect from './PropertySelect';

type Props = $ReadOnly<{|
  properties: Array<Api.Property.V_Mine>,
|}>;

export default function BoxImported({ properties }: Props) {
  const [selected, setSelected] = React.useState<Iri | null>(
    properties.length > 0 ? properties[0]['@id'] : null
  );

  let inner = (
    <EmptyMessageBlock>You haven't added any Listings yet.</EmptyMessageBlock>
  );

  const selectedProperty =
    selected && properties.find((p) => p['@id'] === selected);

  if (selectedProperty) {
    inner = (
      <StyledContainer>
        {['airbnb', 'booking.com', 'homeaway', 'tripadvisor'].map((slug) => {
          const isImported = selectedProperty.channels.some(
            (c) => c.active && c.ota === slug
          );
          return (
            <StyledLink to="/channels" key={slug}>
              <StyledLogo
                src={require(`../../../assets/img/providers/${slug
                  .replace('.', '')
                  .replace(' ', '')}.png`)}
                isImported={isImported}
              />
              <span>
                {isImported ? (
                  <StyledYes
                    src={require('../../../assets/img/ui/input_valid.svg')}
                  />
                ) : (
                  <StyledNo>+ Add Channel</StyledNo>
                )}
              </span>
            </StyledLink>
          );
        })}
      </StyledContainer>
    );
  }

  return (
    <DashboardBox tall>
      <PropertySelect
        properties={properties}
        onSelect={(id) => setSelected(id)}
        selected={selected}
      />
      {inner}
    </DashboardBox>
  );
}

const StyledContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50%;
  transition: background-color 0.25s;

  &:hover {
    background-color: #f3f3f3;
  }
`;

const StyledLogo = styled('img')`
  width: ${rem(60)};
  margin-bottom: ${rem(15)};

  ${(p) => !p.isImported && 'opacity: 0.25'};
`;

const StyledYes = styled('img')`
  width: ${rem(20)};
`;

const StyledNo = styled('span')`
  font-size: ${rem(10)};
  color: ${Color.faintBlue};
  text-transform: uppercase;
`;
