// @flow

import type { Dispatch, RootState } from '../types';

import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Dimen, Domain } from '../constants';
import { authUserSelector } from '../selectors/user';
import LayoutBase from './LayoutBase';
import MessageBlock from './MessageBlock';

type OwnProps = $ReadOnly<{|
  message: string,
  showSwitchToHost?: boolean,
|}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  email: string,
  dispatch: Dispatch,
|}>;

const UseAppPage = ({ message, showSwitchToHost, email, dispatch }: Props) => (
  <LayoutBase>
    <StyledNoSubContainer>
      <MessageBlock>
        <p>{message}</p>
        <StyledAppLinks>
          <a href={Domain.appLinkIos}>
            <img src={require('../../assets/img/app_ios.svg')} />
          </a>
          <a href={Domain.appLinkAndroid}>
            <img src={require('../../assets/img/app_android.png')} />
          </a>
        </StyledAppLinks>
        {showSwitchToHost && (
          <a
            onClick={() =>
              dispatch({ type: 'SET_OPTION_MODE', payload: 'host' })
            }
            style={{ marginRight: 20 }}
          >
            Switch to Host mode
          </a>
        )}
        <a onClick={() => dispatch({ type: 'LOGOUT' })}>Log out of {email}</a>
      </MessageBlock>
    </StyledNoSubContainer>
  </LayoutBase>
);

export default connect<Props, OwnProps, _, _, _, _>((state: RootState) => ({
  email: authUserSelector(state).email,
}))(UseAppPage);

const StyledNoSubContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledAppLinks = styled('div')`
  margin: ${rem(Dimen.spacing)} 0;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    margin: 0 ${rem(Dimen.spacing / 4)};
  }

  img {
    width: ${rem(120)};
  }
`;
