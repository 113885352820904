// @flow

import { Color, Style } from '@guesthug/core';
import { GHText } from '@guesthug/core/components';
import {
  areIntervalsOverlapping,
  endOfMonth,
  format,
  parseISO,
  setMonth,
  setYear,
  startOfMonth,
} from 'date-fns';
import * as React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import { Spacer } from '../../../components';
import ChevronButton from './ChevronButton';

type Props = $ReadOnly<{|
  focusedYear: number,
  setFocusedYear: (number) => void,
  visibleStartDate: string,
  visibleEndDate: string,
  onPressDate: (Date) => void,
|}>;

export default function MultiCalendarMonthPicker({
  focusedYear,
  setFocusedYear,
  visibleStartDate,
  visibleEndDate,
  onPressDate,
}: Props) {
  return (
    <View>
      <GHText align="center" selectable={false}>
        {focusedYear}
      </GHText>
      <Spacer size={0.25} />

      <View style={[Style.row, Style.alignCenter]}>
        <ChevronButton
          dir="left"
          onPress={() => setFocusedYear(focusedYear - 1)}
        />
        {Array(12)
          .fill('_')
          .map((_, i) => {
            const date = setYear(setMonth(new Date(), i), focusedYear);
            const isActive = areIntervalsOverlapping(
              { start: startOfMonth(date), end: endOfMonth(date) },
              {
                start: parseISO(visibleStartDate),
                end: parseISO(visibleEndDate),
              }
            );
            return (
              <Pressable
                key={i}
                onPress={() => onPressDate(startOfMonth(date))}
                style={[styles.button, isActive && styles.buttonActive]}
              >
                <GHText
                  color="invertNormal"
                  selectable={false}
                  weight="bold"
                  style={{ fontSize: 12 }}
                >
                  {format(date, 'MMM').toUpperCase()}
                </GHText>
              </Pressable>
            );
          })}
        <ChevronButton
          dir="right"
          onPress={() => setFocusedYear(focusedYear + 1)}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    flex: 1,
    height: 28,
    borderRadius: 14,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 3,
    backgroundColor: '#e2e7ec',
    transitionProperty: 'background-color',
    transitionDuration: '150ms',
  },
  buttonActive: {
    backgroundColor: Color.vibrantBlue,
  },
});
