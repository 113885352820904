// @flow

import type { FieldProp } from 'react-typed-form';

import * as React from 'react';

import { DatePicker } from '../components';

type Props = $ReadOnly<{|
  field: FieldProp<string | null>,
  label?: string | null,
  inputWidth?: number,
|}>;

export default function FieldDate({ field, label, ...rest }: Props) {
  return (
    <DatePicker
      value={field.value ?? null}
      disabled={field.isLoading}
      onChange={field.handleValueChange}
      label={label === undefined ? field.label : label}
      errors={field.errorList}
      {...rest}
    />
  );
}
