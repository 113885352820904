// @flow

import type { FieldProp } from 'react-typed-form';
import type { Id } from '../types';

import { Api } from '@guesthug/core';
import { useApiCallable } from '@guesthug/core';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { ContentLoading, ErrorList } from '../components';
import { Color, Dimen } from '../constants';

// "busy" response in a way that doesn't conflict with types
export const BUSY = '_busy';

type RenderPreview = ({
  previewSrc: string | null,
  uploading: boolean,
}) => React.Node;

type Props = $ReadOnly<{|
  field: FieldProp<typeof BUSY | Id | null>,
  className?: string,
  renderPreview?: RenderPreview,
  showLabel?: boolean,
  existingImage?: Api.Image.BaseView | null,
|}>;

export default function FieldUpload({
  className,
  existingImage,
  field,
  showLabel,
  renderPreview,
}: Props) {
  const callApi = useApiCallable();

  const [uploading, setUploading] = React.useState<boolean>(false);
  const [currentFile, setCurrentFile] = React.useState<Blob | null>(null);

  const onChange = async (e) => {
    const uploadFile = e.target.files.length === 0 ? null : e.target.files[0];
    setCurrentFile(uploadFile);
    setUploading(!!uploadFile);

    if (!uploadFile) return;

    field.handleValueChange(BUSY);

    const response = await callApi('/images', {
      method: 'POST',
      jsonBody: {},
      uploadBlob: uploadFile,
    });

    if (response) {
      field.handleValueChange(`/images/${response.data.id}`);
    }

    setUploading(false);
  };

  const onDeleteClick = () => {
    field.handleValueChange(null);
    setCurrentFile(null);
  };

  const getPreview: RenderPreview = ({ previewSrc }) =>
    previewSrc && (
      <StyledPreview>
        <img src={previewSrc} />
        {uploading && (
          <div className="mask">
            <ContentLoading />
          </div>
        )}
      </StyledPreview>
    );

  const renderImagePreview = getPreview || renderPreview;

  let previewSrc = null;
  if (currentFile) {
    previewSrc = window.URL.createObjectURL(currentFile);
  } else if (field.value && existingImage && existingImage.path) {
    previewSrc = existingImage.mediumUrl;
  }
  return (
    <StyledContainer className={className}>
      {showLabel && <StyledLabel>{field.label}</StyledLabel>}

      <StyledButtonsRow>
        <StyledButton disabled={uploading}>
          <img src={require('../../assets/img/ui/image_upload.svg')} />
          <span>{previewSrc ? 'Change' : 'Add'} Photo</span>
          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={onChange}
            disabled={uploading}
          />
        </StyledButton>
        {previewSrc && (
          <StyledButton
            disabled={uploading}
            onClick={!uploading ? onDeleteClick : undefined}
          >
            Delete
          </StyledButton>
        )}
      </StyledButtonsRow>
      {renderImagePreview({ previewSrc, uploading })}
      <ErrorList errors={field.lastErrorList} />
    </StyledContainer>
  );
}

const StyledContainer = styled('div')`
  margin-bottom: ${rem(Dimen.spacing)};
`;

const StyledLabel = styled('label')`
  display: block;
  font-size: ${rem(14)};
  font-weight: 300;
  padding: ${rem(10)} 0 0;
`;

const StyledButtonsRow = styled('div')`
  display: flex;
  align-items: center;
`;

const StyledButton = styled('label')`
  display: flex;
  align-items: center;
  height: ${rem(48)};
  color: ${Color.fainterBlue};
  transition: color 0.25s;
  margin-right: ${rem(Dimen.spacing)};
  ${(p) =>
    !p.disabled &&
    `
    cursor: pointer;
    &:hover {
      color: ${Color.vibrantBlue};
    }
  `};

  img {
    width: ${rem(24)};
    margin-right: ${rem(Dimen.spacing / 2)};
  }

  input {
    display: none;
  }
`;

const StyledPreview = styled('div')`
  position: relative;
  display: inline-block;

  img {
    max-width: ${rem(200)};
    max-height: ${rem(200)};
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffffaa;
    display: flex;
  }
`;
