// @flow

import type { Dispatch, Id } from '../../types';
import type { HomeGuideType } from './typeHelpers';

import { useApiCallable } from '@guesthug/core';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { flashShow } from '../../actions/flash';
import { ReturnLink } from '../../components';
import HomeGuideForm from './HomeGuideForm';

type Props = $ReadOnly<{|
  match: { params: { propertyId: Id, type: HomeGuideType } },
|}>;

export default function HomeGuideAddPage({ match }: Props) {
  const [created, setCreated] = React.useState<boolean>(false);

  const { propertyId, type } = match.params;
  const callApi = useApiCallable();
  const dispatch = useDispatch<Dispatch>();

  if (created) {
    return <Redirect to={`/properties/${propertyId}/home-guide`} />;
  }

  return (
    <>
      <Helmet>
        <title>Add New Home Guide</title>
      </Helmet>

      <ReturnLink to={`/properties/${propertyId}/home-guide`}>
        Return to Home Guide Overview
      </ReturnLink>
      <HomeGuideForm
        onSubmit={async (values, { addSubmitError, setLoading }) => {
          setLoading(true);
          const response = await callApi('/home-guides', {
            method: 'POST',
            jsonBody: {
              ...values,
              type,
              property: propertyId,
            },
          });
          if (response) {
            setCreated(true);
            dispatch(flashShow('Changes saved'));
          }
          setLoading(false);
        }}
      />
    </>
  );
}
