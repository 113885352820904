// @flow

import type { Dispatch } from '../../../types';

import {
  Api,
  Color,
  Model,
  Style,
  useApiCallable,
  useApiFormSubmit,
} from '@guesthug/core';
import { GHText, Line, PaddedArea, Spacer } from '@guesthug/core/components';
import * as React from 'react';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-typed-form';

import { flashShow } from '../../../actions/flash';
import { Button, Expander } from '../../../components';
import { FieldNumber, FieldRadio, FormWrap, SubmitButton } from '../../../form';
import CopyBookingSettingsForm from './CopyBookingSettingsForm';

type Props = $ReadOnly<{|
  property: Api.Property.V_Full,
  complete: boolean,
  invalidate: () => void,
|}>;

const FIELD_WIDTH = 140;

export default function SectionBookingSettings({
  property,
  complete,
  invalidate,
}: Props) {
  const callApi = useApiCallable();
  const dispatch = useDispatch<Dispatch>();

  const [copyFormVisible, setCopyFormVisible] = React.useState<boolean>(false);

  const onSubmit = useApiFormSubmit('PUT', `/properties/${property.id}`, {
    onSuccess: async () => {
      invalidate();
      dispatch(flashShow('Changes saved'));

      callApi(`/properties/${property.id}/content/push`, {
        method: 'POST',
        jsonBody: {},
      });
      callApi(`/properties/${property.id}/rad/push`, {
        method: 'POST',
        jsonBody: {},
      });
    },
  });

  const form = useForm<any>({
    defaultValues: { ...null },
    pristineValues: property,
    onSubmit,
  });

  return (
    <Expander title="Booking Settings" complete={complete}>
      <FormWrap handleSubmit={form.handleSubmit}>
        {copyFormVisible ? (
          <CopyBookingSettingsForm propertyId={property.id} />
        ) : (
          <PaddedArea bottom="half" style={Style.alignStart}>
            <Button
              colorScheme="faintBlue"
              label="Import booking settings"
              onPress={() => setCopyFormVisible(true)}
            />
          </PaddedArea>
        )}

        <Line h />

        <Spacer size={0.5} />
        <GHText size="large" weight="light">
          Minimum Length of Stay
          <GHText size="large" weight="bold" style={{ color: Color.red }}>
            {' *'}
          </GHText>
        </GHText>
        <Spacer size={0.5} />
        <View style={{ maxWidth: 440 }}>
          <GHText color="grey" size="small">
            Default minimum stay, which can be changed for specific dates with
            Manual Overrides in the Calendar.
          </GHText>
        </View>
        <Spacer size={0.5} />
        <FieldNumber
          field={form.getField('minNights')}
          label={null}
          afterInput="Nights"
          hideZero
          inputWidth={FIELD_WIDTH}
          required
          noMargin
        />
        <Spacer />
        <Line h />

        <Spacer />
        <GHText size="large" weight="light">
          Guest Numbers
          <GHText size="large" weight="bold" style={{ color: Color.red }}>
            {' *'}
          </GHText>
        </GHText>
        <Spacer size={0.5} />
        <View style={{ maxWidth: 440 }}>
          <GHText color="grey" size="small">
            Anything up to the Standard number of guests won't affect the
            booking price. Anything beyond that up to the Maximum number of
            guests will be charged at the Extra Guest Price (set from Manage
            Pricing).
          </GHText>
        </View>
        <Spacer size={0.5} />
        <FieldNumber
          field={form.getField('standardGuests')}
          label={null}
          afterInput="Standard Guests"
          hideZero
          inputWidth={FIELD_WIDTH}
          required
          noMargin
        />
        <Spacer size={0.5} />
        <FieldNumber
          field={form.getField('canSleepMax')}
          label={null}
          afterInput="Maximum Guests"
          hideZero
          inputWidth={FIELD_WIDTH}
          required
          noMargin
        />
        <Spacer />
        <Line h />

        <Spacer />
        <GHText size="large" weight="light">
          Days Before Arrival
          <GHText size="large" weight="bold" style={{ color: Color.red }}>
            {' *'}
          </GHText>
        </GHText>
        <Spacer size={0.5} />
        <View style={{ maxWidth: 440 }}>
          <GHText color="grey" size="small">
            The amount of days notice a guest must give to make a booking.
          </GHText>
        </View>
        <Spacer size={0.5} />
        <FieldNumber
          field={form.getField('daysBeforeArrival')}
          label={null}
          afterInput="Days"
          inputWidth={FIELD_WIDTH}
          required
          noMargin
        />
        <Spacer />
        <Line h />

        <Spacer />
        <FieldRadio
          field={form.getField('cancellationPolicyMode')}
          label="Cancellation policy"
          choices={[
            {
              label: 'Flexible',
              value: 'Flexible',
              hint: 'Guest can cancel until 24 hours before',
            },
            { label: 'Moderate', value: 'Moderate' },
            { label: 'Strict', value: 'Strict' },
          ]}
          required
        />

        <Spacer />
        <Line h />

        <SubmitButton
          normalLabel={Model.Property.getSubmitButtonLabel(property)}
          isLoading={form.isLoading}
        />
      </FormWrap>
    </Expander>
  );
}
