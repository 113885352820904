// @flow

import { rem } from 'polished';
import styled from 'styled-components';

export default styled('div')`
  background-color: #fff;
  border-radius: ${rem(4)};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
`;
