// @flow

import type { Dispatch, Id } from '../../../types';

import { Api } from '@guesthug/core';
import { iriToId } from '@guesthug/core/util';
import { compareAsc, compareDesc, parseISO } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Dimen } from '../../../constants';
import Bubble from './Bubble';

type Props = $ReadOnly<{|
  userId: Id,
  messages: Array<Api.Message.BaseView>,
|}>;

export default function BubblesList({ userId, messages }: Props) {
  const [prevNumMessages, setPrevNumMessages] = React.useState<number>(
    messages.length
  );

  const dispatch = useDispatch<Dispatch>();
  const listRef = React.useRef(null);

  function scrollToBottom() {
    const list = listRef.current;
    if (list) {
      list.scrollTop = list.scrollHeight;
    }
  }

  React.useEffect(() => scrollToBottom(), []);

  React.useEffect(() => {
    const recentMessagesFirst = messages.sort((a, b) =>
      compareDesc(parseISO(a.createdAt), parseISO(b.createdAt))
    );
    if (recentMessagesFirst.length > 0) {
      dispatch({
        type: 'MESSAGING_SET_LAST_READ',
        payload: {
          bookingId: iriToId(recentMessagesFirst[0].booking),
          messageId: recentMessagesFirst[0].id,
        },
      });
    }
  }, [dispatch, messages, userId]);

  React.useEffect(() => {
    // if new messages appear while screen is
    // mounted scroll to bottom
    if (messages.length !== prevNumMessages) scrollToBottom();
    setPrevNumMessages(messages.length);
  }, [prevNumMessages, messages.length]);

  const messagesAsc = messages.sort((a, b) =>
    compareAsc(parseISO(a.createdAt), parseISO(b.createdAt))
  );

  return (
    <StyledList ref={listRef}>
      {messagesAsc.map((message) => (
        <Bubble
          key={message.id}
          message={message}
          mySide={iriToId(message.sender) === userId}
        />
      ))}
      <StyledBottomPadding />
    </StyledList>
  );
}

const StyledList = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${rem(Dimen.spacing / 2)};
  overflow-y: auto;
`;

const StyledBottomPadding = styled('div')`
  height: ${rem(Dimen.spacing / 2)};
  flex-shrink: 0;
`;
