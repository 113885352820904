// @flow

import { capitalize } from '../../util';

export type LocalGuideType = 'hotspot' | 'essential' | 'delivery';

export const LOCAL_GUIDE_TYPES: LocalGuideType[] = [
  'essential',
  'hotspot',
  'delivery',
];

export function typeToTitle(type: LocalGuideType): string {
  if (type === 'delivery') return 'Delivery/Takeaway';
  return capitalize(type);
}

export function typeToPluralTitle(type: LocalGuideType): string {
  return typeToTitle(type) + 's';
}

export const categories = {
  sightseeing: {
    label: 'Sightseeing',
  },
  shopping: {
    label: 'Shopping',
  },
  cafe: {
    label: 'Café',
  },
  food: {
    label: 'Food',
  },
  activities: {
    label: 'Activities',
  },
  nightlife: {
    label: 'Drinks & Nightlife',
  },
  nature: {
    label: 'Parks & Nature',
  },
  culture: {
    label: 'Arts & Culture',
  },
  sport: {
    label: 'Sport & Fitness',
  },
};
