// @flow

import type { FieldProp } from 'react-typed-form';

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Modal, TextInput } from '../components';
import { Dimen } from '../constants';

export type Choice = $ReadOnly<{|
  value: mixed,
  label: string,
  iconSource?: string,
|}>;

export type Props = $ReadOnly<{|
  ...React.ElementConfig<typeof TextInput>,
  // TODO: Type-safe string or number?
  field: FieldProp<any>, // eslint-disable-line flowtype/no-weak-types
  choices: $ReadOnlyArray<Choice>,
|}>;

type State = {|
  modalVisible: boolean,
|};

/**
 * Selection from a fixed set of choices
 */
export default class FieldSelect extends React.PureComponent<Props, State> {
  state = {
    modalVisible: false,
  };

  render() {
    const { field, choices, ...rest } = this.props;
    const { modalVisible } = this.state;

    const currentChoice = choices.find(
      (choice) => choice.value === field.value
    );
    const currentLabel = currentChoice ? currentChoice.label : '';
    const anyIcons = choices.some((choice) => choice.iconSource);

    return (
      <>
        <TextInput
          label={field.label}
          value={currentLabel}
          disabled={field.isLoading}
          onClick={
            field.isLoading
              ? undefined
              : () => this.setState({ modalVisible: true })
          }
          onChange={field.handleValueChange}
          errors={field.lastErrorList}
          showSelectArrow
          {...rest}
        />

        {modalVisible && (
          <Modal
            title="Select an option"
            onDismiss={() => this.setState({ modalVisible: false })}
          >
            {choices.map(({ label, value, iconSource }, i) => (
              <StyledChoice
                key={label}
                onClick={() => {
                  field.handleValueChange(value);
                  this.setState({ modalVisible: false });
                }}
                first={i === 0}
              >
                {anyIcons && <img src={iconSource} />}
                <span>{label}</span>
              </StyledChoice>
            ))}
          </Modal>
        )}
      </>
    );
  }
}

const StyledChoice = styled('a')`
  display: flex;
  align-items: center;
  padding: ${rem(Dimen.spacing / 2)} ${rem(Dimen.spacing)};
  transition: background-color 0.25s;

  ${(p) => !p.first && `border-top: 1px #e7ebf1 solid`};

  &:hover {
    background-color: #f8f8f8;
  }

  img {
    margin-right: ${rem(Dimen.spacing / 4)};
    height: ${rem(24)};
    width: ${rem(24)};
  }
`;
