// @flow

import type { Dispatch } from '../../types';

import { Api, RuDictionary } from '@guesthug/core';
import { useApiCallable } from '@guesthug/core';
import { useInvalidation } from 'api-read-hook';
import { rem } from 'polished';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { FormComponent } from 'react-typed-form';
import styled from 'styled-components';

import { flashShow } from '../../actions/flash';
import {
  Avatar,
  ContentBox,
  ContentLoading,
  Hr,
  ReturnLink,
  ScreenError,
  Spacer,
} from '../../components';
import { Dimen } from '../../constants';
import {
  FieldInput,
  FieldSelect,
  FieldSwitch,
  FieldUpload,
  GroupPairFields,
  SubmitButton,
  createFormValidator,
} from '../../form';
import { useAuthUser } from '../../hooks';

export default function EditProfilePage() {
  const callApi = useApiCallable();
  const { invalidateMatching } = useInvalidation();

  const { id } = useAuthUser();
  const dispatch = useDispatch<Dispatch>();

  const api = Api.User.useReadItemMe(id);

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ContentLoading />;

  const user = api.data;

  const countryChoices = RuDictionary.ruCountries
    .sort((a, b) => a.localeCompare(b))
    .map((x) => ({
      label: x,
      value: x,
    }));

  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>

      <ReturnLink to="/">Return to Dashboard</ReturnLink>
      <ContentBox>
        <FormComponent
          defaultValues={{
            language: user.language ?? 'English',
            isCompany: user.isCompany ?? false,
          }}
          pristineValues={user}
          validator={createFormValidator({
            email: { email: true },
            plainPassword: {
              passwordFormat: true,
              passwordLength: true,
            },
          })}
          onSubmit={async (values, { addSubmitError, setLoading }) => {
            setLoading(true);
            const response = await callApi(
              `/users/${id}/profile`,
              {
                method: 'PUT',
                jsonBody: values,
              },
              { addSubmitError }
            );
            if (response) {
              invalidateMatching('/users');
              dispatch(flashShow('Changes saved'));
              if (values.email) {
                // Must auto log out as the jwt is now stale
                dispatch({ type: 'LOGOUT' });
              }
            }
            setLoading(false);
          }}
        >
          {({ getField, handleSubmit, isLoading }) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <AreaTop>
                <h1>My Account</h1>
                <div>
                  <StyledFieldUpload
                    field={getField('avatarImage')}
                    existingImage={user.avatarImage || null}
                    renderPreview={({ previewSrc, uploading }) => (
                      <Avatar
                        user={user}
                        size="large"
                        overrideSrc={previewSrc}
                        loading={uploading}
                      />
                    )}
                  />
                </div>
              </AreaTop>
              <GroupPairFields
                left={<FieldInput field={getField('firstName')} required />}
                right={<FieldInput field={getField('lastName')} required />}
              />
              <GroupPairFields
                left={
                  <FieldInput
                    field={getField('email')}
                    required
                    hint="Please be aware that changing your email address will require you to log in to GuestHug again."
                  />
                }
                right={
                  <FieldSwitch
                    field={getField('acceptsMarketing')}
                    description="I’d like to receive marketing and policy communications from GuestHug and it’s partners."
                    alignSideBySide
                  />
                }
              />
              <GroupPairFields
                left={<FieldInput field={getField('phone')} required />}
              />
              <Hr />
              <GroupPairFields
                left={
                  <FieldInput
                    field={{
                      ...getField('plainPassword'),
                      label: 'New Password',
                    }}
                    secureTextEntry
                  />
                }
              />
              <Hr />

              <GroupPairFields
                left={
                  <GroupPairFields
                    left={
                      <FieldInput
                        field={getField('addressHouseNameNumber')}
                        label="Building Name / Number"
                        required
                      />
                    }
                    right={
                      <FieldInput
                        field={getField('addressStreet')}
                        label="Street"
                        required
                      />
                    }
                  />
                }
                right={
                  <FieldInput
                    field={getField('addressCity')}
                    label="Town / City"
                    required
                  />
                }
              />
              <GroupPairFields
                left={
                  <FieldInput
                    field={getField('addressPostcode')}
                    label="Post Code / Zip Code"
                    required
                  />
                }
                right={
                  <FieldSelect
                    field={getField('addressCountry')}
                    label="Country"
                    required
                    choices={countryChoices}
                  />
                }
              />
              <GroupPairFields
                left={
                  <FieldSelect
                    field={getField('language')}
                    required
                    choices={RuDictionary.ruLanguages
                      .sort((a, b) => a.localeCompare(b))
                      .map((x) => ({
                        label: x,
                        value: x,
                      }))}
                  />
                }
              />
              <Hr />

              <FieldSwitch
                field={getField('isCompany')}
                description="I am acting as a Company"
                alignSideBySide
                noMargin
              />
              <Spacer />

              <SubmitButton isLoading={isLoading} />
            </form>
          )}
        </FormComponent>
      </ContentBox>

      {user.isCompany && (
        <ContentBox>
          <FormComponent
            pristineValues={user}
            onSubmit={async (values, { addSubmitError, setLoading }) => {
              setLoading(true);
              const response = await callApi(
                `/users/${id}/company`,
                {
                  method: 'PUT',
                  jsonBody: values,
                },
                { addSubmitError }
              );
              if (response) {
                invalidateMatching('/users');
                dispatch(flashShow('Changes saved'));
              }
              setLoading(false);
            }}
          >
            {({ getField, handleSubmit, isLoading }) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <AreaTop>
                  <h1>Company Profile</h1>
                </AreaTop>
                <GroupPairFields
                  left={<FieldInput field={getField('companyName')} required />}
                  right={
                    <FieldInput field={getField('companyWebsite')} required />
                  }
                />
                <GroupPairFields
                  left={
                    <GroupPairFields
                      left={
                        <FieldInput
                          field={getField('companyAddressHouseNameNumber')}
                          label="Building Name / Number"
                          required
                        />
                      }
                      right={
                        <FieldInput
                          field={getField('companyAddressStreet')}
                          label="Street"
                          required
                        />
                      }
                    />
                  }
                  right={
                    <FieldInput
                      field={getField('companyAddressCity')}
                      label="Town / City"
                      required
                    />
                  }
                />
                <GroupPairFields
                  left={
                    <FieldSelect
                      field={getField('companyAddressCountry')}
                      label="Country"
                      required
                      choices={countryChoices}
                    />
                  }
                />
                <GroupPairFields
                  left={
                    <FieldInput
                      field={getField('companyMerchantName')}
                      label="Merchant Name"
                      required
                      hint="This should match the merchant name (statement descriptor) provided to your payment processor and is the business name that appears on your customer's credit card statements."
                    />
                  }
                />

                <SubmitButton isLoading={isLoading} />
              </form>
            )}
          </FormComponent>
        </ContentBox>
      )}
    </>
  );
}

const AreaTop = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${rem(Dimen.spacing / 2)};

  h1 {
    font-size: ${rem(30)};
    font-weight: normal;
    margin: 0;
  }
`;

const StyledFieldUpload = styled(FieldUpload)`
  display: flex;
  margin-bottom: 0;
`;
