// @flow

import type { Id } from '../../../types';

import { Api } from '@guesthug/core';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { EmptyMessageBlock } from '../../../components';
import { Dimen } from '../../../constants';
import ListThread from './ListThread';

type Props = $ReadOnly<{|
  userId: Id,
  onSelectId: (Id | null) => mixed,
  hostMessages: Api.User.V_Host,
  guestMessages: Api.User.V_Guest,
|}>;

export default function RecipientModal({
  userId,
  onSelectId,
  hostMessages,
  guestMessages,
}: Props) {
  const ownerBookings =
    (hostMessages.properties &&
      hostMessages.properties.reduce(
        (carry, p) => [...carry, ...p.bookings],
        []
      )) ||
    [];
  const guestBookings = guestMessages.mainGuestBookings;
  const bookings = [...ownerBookings, ...guestBookings];

  return (
    <StyledList>
      {bookings.length === 0 && (
        <EmptyMessageBlock>
          You don't have any bookings yet. Import a Booking Calendar to begin
          sending messages.
        </EmptyMessageBlock>
      )}
      {bookings.map((booking: any) => {
        const owner = booking.property ? booking.property.owner : null;
        const mainGuest = booking.mainGuest ?? null;
        let otherUser = null;
        if (owner && owner !== userId) {
          otherUser = owner;
        } else if (mainGuest && mainGuest.id !== userId) {
          otherUser = mainGuest;
        }
        if (!otherUser) return null;

        return (
          <ListThread
            key={booking.id}
            booking={booking}
            otherUser={otherUser}
            onSelectId={onSelectId}
          />
        );
      })}
    </StyledList>
  );
}

const StyledList = styled('div')`
  flex: 1;
  overflow-y: auto;
  margin-bottom: ${rem(Dimen.spacing / 2)};
`;
