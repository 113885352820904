// @flow

import type { Action } from '../types';

export type Flash = $ReadOnly<{|
  key: string,
  message: string,
  firedAt: string,
|}>;

export type FlashState = $ReadOnly<{|
  flashes: $ReadOnlyArray<Flash>,
|}>;

const initialState = {
  flashes: [],
};

export default (
  state: FlashState = initialState,
  action: Action
): FlashState => {
  switch (action.type) {
    case 'FLASH_SHOW':
      return { flashes: [...state.flashes, action.payload] };
    default:
      return state;
  }
};
