// @flow

import type { FieldProp } from 'react-typed-form';

import * as React from 'react';

import { TextInput } from '../components';

type Props = $ReadOnly<{|
  ...React.ElementConfig<typeof TextInput>,
  field: FieldProp<number | null>,
  hideZero?: boolean,
  allowNegative?: boolean,
|}>;

export default function FieldNumber({
  field,
  allowNegative,
  hideZero,
  ...rest
}: Props) {
  const [lastTypedValue, setLastTypedValue] = React.useState<string | void>();

  let displayValue =
    field.value === 0 && hideZero ? '' : field.value?.toString() ?? '';
  if (['', '-'].includes(lastTypedValue)) {
    displayValue = lastTypedValue;
  }

  return (
    <TextInput
      label={field.label}
      value={displayValue}
      onChange={(ev) => {
        const value = ev.target.value.replace(
          allowNegative ? /[^\d-]/g : /[^\d]/g,
          ''
        );
        setLastTypedValue(value);

        if (value === '' || value === '-' || isNaN(value) || !isFinite(value)) {
          field.handleValueChange(null);
          return;
        }

        field.handleValueChange(parseInt(value, 10));
      }}
      disabled={field.isLoading}
      errors={field.lastErrorList}
      {...rest}
    />
  );
}
