// @flow

import { Api } from '@guesthug/core';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Box } from '../../components';
import { Dimen } from '../../constants';
import SectionChart from './SectionChart';
import SectionRatings from './SectionRatings';
import SectionServicesRevenue from './SectionServicesRevenue';
import SectionTransactions from './SectionTransactions';

type Props = $ReadOnly<{|
  bookings: Array<Api.Booking.V_Analytics>,
  currency: string,
|}>;

export default function AnalyticsProperty({ bookings, currency }: Props) {
  const purchases = [];

  bookings.forEach((booking) => {
    booking.purchases.forEach((purchase) => {
      purchases.push({
        ...purchase,
        name: booking.property.name,
        mainGuest: booking.mainGuest,
      });
    });
  });

  return (
    <Box>
      <StyledColsTop>
        <SectionServicesRevenue
          period="month"
          purchases={purchases}
          currency={currency}
        />
        <SectionServicesRevenue
          period="year"
          purchases={purchases}
          currency={currency}
        />
      </StyledColsTop>
      <StyledColsBottom>
        <div>
          <SectionChart purchases={purchases} currency={currency} />
          <SectionRatings bookings={bookings} />
        </div>
        <div>
          <SectionTransactions purchases={purchases} />
        </div>
      </StyledColsBottom>
    </Box>
  );
}

const StyledColsTop = styled('div')`
  display: flex;
  align-items: stretch;
  border-bottom: 2px #f2f4f8 solid;
`;

const StyledColsBottom = styled('div')`
  display: flex;
  align-items: stretch;

  > div {
    box-sizing: border-box;
    padding: ${rem(Dimen.spacing)};

    &:first-child {
      flex: 1;
      border-right: 2px #f2f4f8 solid;
    }

    &:last-child {
      width: 40%;
      max-width: ${rem(330)};
      padding: ${rem(Dimen.spacing / 2)};
    }
  }
`;
