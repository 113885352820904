// @flow

import * as React from 'react';
import { Pressable } from 'react-native';
import { useHistory } from 'react-router';

type Props = {
  ...React.ElementConfig<typeof Pressable>,
  to: string | null | void,
  disabled?: boolean,
  children:
    | React.Node
    | (({ hovered: boolean, pressed: boolean }) => React.Node),
};

export default function PressableLink({
  to,
  disabled,
  children,
  ...rest
}: Props) {
  const history = useHistory();
  const finalDisabled = disabled || !to;

  return (
    <Pressable
      href={finalDisabled ? undefined : to}
      onPress={
        finalDisabled
          ? undefined
          : (ev) => {
              ev.preventDefault();
              history.push(to);
            }
      }
      disabled={finalDisabled}
      accessibilityRole="link"
      {...rest}
    >
      {(children: any)}
    </Pressable>
  );
}
