// @flow

import { Api } from '@guesthug/core';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Switch } from '../../../components';
import { Color, Dimen } from '../../../constants';
import { formatPrice } from '../../../util';

type Props = $ReadOnly<{|
  service: Api.Service.BaseView,
  enabled: boolean,
  onChange: (boolean) => mixed,
|}>;

export default function ServiceRow({ service, enabled, onChange }: Props) {
  return (
    <StyledRow>
      <strong>{service.title}</strong>
      <span>{formatPrice(service.price, service.currency)}</span>
      <Switch value={enabled} onValueChange={onChange} />
    </StyledRow>
  );
}

const StyledRow = styled('div')`
  display: flex;
  align-items: center;
  padding: ${rem(Dimen.spacing / 2)} ${rem(Dimen.spacing)};
  border: 0 #e7ebf1 solid;
  border-bottom-width: 1px;

  &:first-of-type {
    border-top-width: 1px;
  }

  strong {
    flex: 5;
    font-weight: 400;
  }

  span {
    color: ${Color.faintBlue};
    font-weight: 300;
    margin-right: ${rem(Dimen.spacing)};
  }
`;
