// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Dimen } from '../constants';

type Props = $ReadOnly<{|
  left?: React.Node,
  right?: React.Node,
  flexLeft?: number,
  flexRight?: number,
|}>;

const LayoutPairFields = ({ left, right, flexLeft, flexRight }: Props) => (
  <StyledContainer>
    <StyledLeft style={flexLeft && { flex: flexLeft }}>{left}</StyledLeft>
    <StyledRight style={flexRight && { flex: flexRight }}>{right}</StyledRight>
  </StyledContainer>
);

export default LayoutPairFields;

const StyledContainer = styled('div')`
  display: flex;
  align-items: flex-start;
`;

const StyledLeft = styled('div')`
  flex: 1;
`;

const StyledRight = styled('div')`
  flex: 1;
  padding-left: ${rem(Dimen.spacing)};
`;
