// @flow

import * as React from 'react';

import MessageBlock from './MessageBlock';

type Props = $ReadOnly<{|
  children: React.Node,
|}>;

const EmptyMessageBlock = ({ children }: Props) => (
  <MessageBlock type="empty">{children}</MessageBlock>
);
export default EmptyMessageBlock;
