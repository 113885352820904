// @flow

import { rem } from 'polished';
import styled from 'styled-components';

import { Dimen } from '../constants';
import Box from './Box';

export default styled(Box)`
  padding: ${rem(Dimen.spacing)};
  margin: ${rem(Dimen.spacing)} 0 0;
`;
