// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Color, Dimen } from '../constants';

type Props = $ReadOnly<{|
  title: string,
  children: React.Node,
|}>;

type State = {|
  open: boolean,
|};

export default class HelpPanel extends React.PureComponent<Props, State> {
  state = {
    open: false,
  };

  render() {
    const { title, children } = this.props;
    const { open } = this.state;
    return (
      <StyledContainer open={open}>
        <StyledAreaTop>
          <span>{title}</span>
          <a onClick={() => this.setState({ open: !open })}>
            {open ? 'Close' : 'Open'}
          </a>
        </StyledAreaTop>
        <StyledAreaMain>{children}</StyledAreaMain>
      </StyledContainer>
    );
  }
}

const TOP_HEIGHT = 50;
const MAIN_HEIGHT = 240;

const StyledContainer = styled('div')`
  position: fixed;
  left: ${rem(Dimen.hostWebLayoutPanelWidth)};
  right: ${rem(Dimen.hostWebLayoutPanelWidth)};
  bottom: ${(p) => rem(p.open ? 0 : -MAIN_HEIGHT)};
  background-color: #546775;
  transition: bottom 0.25s;
  z-index: 5;
`;

const StyledAreaTop = styled('div')`
  height: ${rem(TOP_HEIGHT)};
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 300;

  span {
    flex: 1;
    text-align: center;
  }

  a {
    text-transform: uppercase;
    font-size: ${rem(12)};
    padding: 0 ${rem(Dimen.spacing)};
    height: ${rem(TOP_HEIGHT)};
    line-height: ${rem(TOP_HEIGHT)};
    transition: color 0.25s;

    &:hover {
      color: ${Color.vibrantBlue};
    }
  }
`;

const StyledAreaMain = styled('div')`
  height: ${rem(MAIN_HEIGHT)};
  box-sizing: border-box;
  display: flex;
  padding: ${rem(Dimen.spacing / 2)} ${rem(Dimen.spacing / 2)};
  overflow-x: auto;
`;
