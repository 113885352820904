// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  count: number,
  className?: string,
|}>;

const Stars = ({ count, className }: Props) => (
  <StyledContainer className={className}>
    {[1, 2, 3, 4, 5].map((i) => (
      <Star key={i} on={count >= i} />
    ))}
  </StyledContainer>
);

export default Stars;

const StyledContainer = styled('div')`
  flex-direction: row;
  align-items: center;
  user-select: none;
`;

const Star = styled('img').attrs((p) => ({
  src: require(`../../assets/img/ui/star_${p.on ? 'on' : 'off'}.svg`),
}))`
  width: ${rem(16)};
  margin-right: ${rem(3)};
`;
