// @flow

import { Api } from '@guesthug/core';
import * as React from 'react';

import { Figure } from '../../components';
import { formatPrice } from '../../util';
import DashboardBox from './DashboardBox';

type Props = {
  bookings: Array<Api.Booking.V_Dashboard>,
  currency: string,
};

export default function BoxServicesRevenue({ bookings, currency }: Props) {
  let totalPrice = 0;

  bookings.forEach((booking) => {
    booking.purchases.forEach((purchase) => {
      purchase.lineItems?.forEach((lineItem) => {
        totalPrice += lineItem.price * lineItem.quantity;
      });
    });
  });

  return (
    <DashboardBox title="Total Revenue From Services" padded>
      <Figure
        value={formatPrice(totalPrice, currency, {
          round: true,
        })}
      />
    </DashboardBox>
  );
}
