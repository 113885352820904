// @flow

import type { FieldProp } from 'react-typed-form';

import * as React from 'react';

import { TextInput } from '../components';

type Props = $ReadOnly<{|
  ...React.ElementConfig<typeof TextInput>,
  field: FieldProp<string>,
|}>;

export default function FieldInputStrict({ field, ...rest }: Props) {
  return (
    <TextInput
      label={field.label}
      value={field.value || ''}
      onChange={(ev) => {
        const value = ev.target.value;
        field.handleValueChange(value);
      }}
      disabled={field.isLoading}
      errors={field.lastErrorList}
      {...rest}
    />
  );
}
