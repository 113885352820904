// @flow

import { rem } from 'polished';
import styled from 'styled-components';

import { Dimen } from '../constants';

export default styled('hr')`
  height: 2px;
  border: 0;
  background-color: #22448811;
  margin: ${rem(Dimen.spacing)} 0;
`;
