// @flow

import { Api } from '@guesthug/core';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Stars } from '../../components';
import { Color, Dimen } from '../../constants';
import { entries } from '../../util';

type Props = $ReadOnly<{|
  bookings: Array<Api.Booking.V_Analytics>,
|}>;

export default function SectionRatings({ bookings }: Props) {
  const types = ['Cleanliness', 'Condition', 'App', 'Overall'];
  const averages = {};
  types.forEach((type) => {
    const ratings = bookings
      .map((b) => b[`rating${type}`])
      .filter((r) => r != null);
    averages[type] = ratings.length ? ratings.reduce((c, r) => c + r, 0) : 0;
  });

  if (Object.values(averages).reduce((carry, r) => r || carry) === 0) {
    return null;
  }

  return (
    <StyledContainer>
      <span>Overall Listing Ratings</span>
      <div>
        {entries(averages).map(([key, value]) =>
          value === 0 ? null : (
            <StyledRating key={key}>
              <span>{key}</span>
              <Stars count={value} />
            </StyledRating>
          )
        )}
      </div>
    </StyledContainer>
  );
}

const StyledContainer = styled('div')`
  font-weight: 300;
  font-size: ${rem(14)};
  color: ${Color.faintBlue};
  margin-top: ${rem(Dimen.spacing)};

  > span {
    display: block;
    margin-bottom: ${rem(Dimen.spacing)};
  }

  > div {
    display: flex;
    flex-wrap: wrap;
  }
`;

const StyledRating = styled('div')`
  width: 50%;
  display: flex;
  margin-bottom: ${rem(Dimen.spacing / 4)};

  > span {
    flex: 2;
  }

  > div {
    flex: 3;
  }
`;
