// @flow

import * as React from 'react';

import FormErrorList from './FormErrorList';

type Props = $ReadOnly<{|
  handleSubmit: () => mixed,
  formErrorList?: string[],
  className?: string,
  children: React.Node,
|}>;

const FormWrap = ({
  handleSubmit,
  formErrorList,
  className,
  children,
}: Props) => (
  <form
    className={className}
    onSubmit={(e) => {
      e.preventDefault();
      handleSubmit();
    }}
  >
    <FormErrorList errors={formErrorList} />
    {children}
  </form>
);

export default FormWrap;
