// @flow

import type { $Optional, FormObject } from 'react-typed-form';

import { Api } from '@guesthug/core';
import * as React from 'react';
import { UntypedFormComponent } from 'react-typed-form';
import styled from 'styled-components';

import { ContentBox, Hr } from '../../components';
import { Color } from '../../constants';
import {
  FieldInput,
  FieldMoney,
  FieldUpload,
  FormWrap,
  GroupPairFields,
  SubmitButton,
  createFormValidator,
} from '../../form';

type FT = $Optional<Api.Service.BaseView>;

type Props = $ReadOnly<{|
  service?: Api.Service.BaseView,
  onSubmit: (FT, FormObject<FT>) => mixed,
  onDeleteClick?: () => mixed,
  currency: string,
|}>;

export default function ServiceForm({
  service,
  currency,
  onSubmit,
  onDeleteClick,
}: Props) {
  return (
    <UntypedFormComponent
      defaultValues={service ? {} : { price: 0, currency: currency }}
      pristineValues={service}
      validator={createFormValidator({
        title: { strictPresence: { mustDefine: !service } },
        price: { strictPresence: { mustDefine: !service }, price: true },
        image: { upload: true },
      })}
      onSubmit={onSubmit}
    >
      {({ getField, handleSubmit, isLoading }) => (
        <ContentBox>
          {onDeleteClick && (
            <StyledDeleteLink>
              <a onClick={onDeleteClick}>Delete this service</a>
            </StyledDeleteLink>
          )}
          <FormWrap handleSubmit={handleSubmit}>
            <GroupPairFields
              left={<FieldInput field={getField('title')} />}
              right={
                <FieldMoney field={getField('price')} currency={currency} />
              }
            />
            <FieldInput field={getField('description')} multiline />
            <FieldUpload
              field={getField('image')}
              existingImage={service ? service.image : null}
            />
            <Hr />
            <FieldInput field={getField('terms')} multiline />
            <SubmitButton isLoading={isLoading} />
          </FormWrap>
        </ContentBox>
      )}
    </UntypedFormComponent>
  );
}

const StyledDeleteLink = styled('div')`
  display: flex;
  justify-content: flex-end;
  a {
    transition: color 0.25s;
    &:hover {
      color: ${Color.vibrantBlue};
    }
  }
`;
