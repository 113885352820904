// @flow

import type { Dispatch } from '../../../types';

import { Api } from '@guesthug/core';
import { useApiCallable } from '@guesthug/core';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { UntypedFormComponent } from 'react-typed-form';

import { flashShow } from '../../../actions/flash';
import { Hr } from '../../../components';
import {
  FieldInput,
  FieldUpload,
  FieldYouTubeId,
  FormWrap,
  SubmitButton,
  createFormValidator,
} from '../../../form';

type Props = $ReadOnly<{|
  property: Api.Property.V_Full,
  invalidate: () => void,
|}>;

export default function CheckOutGuide({ property, invalidate }: Props) {
  const dispatch = useDispatch<Dispatch>();
  const callApi = useApiCallable();

  return (
    <UntypedFormComponent
      pristineValues={property}
      validator={createFormValidator({
        departBefore: { time: true },
        checkOutStep1Image: { upload: true },
        checkOutStep2Image: { upload: true },
        checkOutStep3Image: { upload: true },
      })}
      onSubmit={async (values, { addSubmitError, setLoading }) => {
        setLoading(true);
        const response = await callApi(`/properties/${property.id}`, {
          method: 'PUT',
          jsonBody: values,
        });
        if (response) {
          dispatch(flashShow('Changes saved'));
          invalidate();
        }
        setLoading(false);

        callApi(`/properties/${property.id}/content/push`, {
          method: 'POST',
          jsonBody: {},
        });
      }}
    >
      {({ getField, handleSubmit, isLoading }) => (
        <FormWrap handleSubmit={handleSubmit}>
          <FieldInput
            field={{
              ...getField('departBefore'),
              label: 'Depart before (HH:MM)*',
            }}
          />

          <Hr />
          <p>
            Fill out your three steps below to describe your check-out process,
            and if you'd really like to help your guests, provide a video guide.
          </p>
          <FieldYouTubeId
            field={{
              ...getField('checkOutYoutubeId'),
              label: 'Add video guide',
            }}
          />

          <Hr />
          <FieldInput
            field={{
              ...getField('checkOutStep1Description'),
              label: 'Step 1: Describe the first step',
            }}
            multiline
          />
          <FieldUpload
            field={getField('checkOutStep1Image')}
            existingImage={property.checkOutStep1Image || null}
          />

          <Hr />
          <FieldInput
            field={{
              ...getField('checkOutStep2Description'),
              label: 'Step 2: Describe the second step',
            }}
            multiline
          />
          <FieldUpload
            field={getField('checkOutStep2Image')}
            existingImage={property.checkOutStep2Image || null}
          />

          <Hr />
          <FieldInput
            field={{
              ...getField('checkOutStep3Description'),
              label: 'Step 3: Describe the third step',
            }}
            multiline
          />
          <FieldUpload
            field={getField('checkOutStep3Image')}
            existingImage={property.checkOutStep3Image || null}
          />

          <SubmitButton isLoading={isLoading} />
        </FormWrap>
      )}
    </UntypedFormComponent>
  );
}
