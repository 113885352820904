// @flow

import { useApiCallable } from '@guesthug/core';
import { useInvalidation } from 'api-read-hook';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';

import { ReturnLink } from '../../components';
import MessageTemplateForm from './MessageTemplateForm';

export default function MessageTemplateAddPage() {
  const [created, setCreated] = React.useState<boolean>(false);
  const callApi = useApiCallable();
  const { invalidateMatching } = useInvalidation();

  if (created) {
    return <Redirect to={'/settings'} />;
  }

  return (
    <>
      <Helmet>
        <title>Add New Message Template</title>
      </Helmet>
      <ReturnLink to={'/settings'}>Return to Settings</ReturnLink>
      <MessageTemplateForm
        onSubmit={async (values, { addSubmitError, setLoading }) => {
          setLoading(true);
          const response = await callApi('/message-templates', {
            method: 'POST',
            jsonBody: values,
          });
          if (response) {
            setCreated(true);
            invalidateMatching('/users');
          }
          setLoading(false);
        }}
      />
    </>
  );
}
