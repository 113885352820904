// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { TitledBox } from '../../components';
import { Dimen } from '../../constants';

type Props = $ReadOnly<{|
  title?: string,
  tall?: boolean,
  padded?: boolean,
  noStyling?: boolean,
  children?: React.Node,
|}>;

const DashboardBox = (props: Props) => <StyledBox {...props} />;

export default DashboardBox;

// eslint-disable-next-line no-unused-vars
const StyledBox = styled(({ noStyling, tall, ...rest }) =>
  React.createElement(noStyling ? 'div' : TitledBox, rest)
)`
  height: ${(p) => rem(p.tall ? 380 : 110)};
  margin: ${rem(Dimen.spacing / 2)} ${rem(Dimen.spacing / 2)}
    ${rem(Dimen.spacing * 0.75)};
  width: calc(25% - (${rem(Dimen.spacing)}));
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (max-width: 1700px) {
    width: calc(50% - (${rem(Dimen.spacing)}));
  }
`;
