// @flow

import type { Dispatch, Id } from '../../types';

import { Api } from '@guesthug/core';
import { useApiCallable } from '@guesthug/core';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { UntypedFormComponent } from 'react-typed-form';

import { flashShow } from '../../actions/flash';
import {
  ContentBox,
  ContentLoading,
  ReturnLink,
  ScreenError,
} from '../../components';
import {
  FieldInput,
  FormWrap,
  GroupPairFields,
  SubmitButton,
} from '../../form';

type Props = $ReadOnly<{|
  match: { params: { propertyId: Id, id: Id } },
|}>;

export default function HomeGuideEditPage({ match }: Props) {
  const { propertyId } = match.params;
  const callApi = useApiCallable();
  const dispatch = useDispatch<Dispatch>();

  const [saved, setSaved] = React.useState<boolean>(false);

  const api = Api.Property.useReadItem(propertyId);

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ContentLoading />;

  const property = api.data;

  if (saved) {
    return <Redirect to={`/properties/${propertyId}/home-guide`} />;
  }

  const returnLink = (
    <ReturnLink to={`/properties/${propertyId}/home-guide`}>
      Return to Home Guide Overview
    </ReturnLink>
  );

  if (!propertyId) return <Redirect to={'/properties'} />;

  return (
    <>
      <Helmet>
        <title>Edit Wifi</title>
      </Helmet>
      {returnLink}
      <UntypedFormComponent
        pristineValues={property}
        onSubmit={async (values, { setLoading, addSubmitError }) => {
          setLoading(true);
          const response = await callApi(`/properties/${propertyId}`, {
            method: 'PUT',
            jsonBody: values,
          });
          if (response) {
            dispatch(flashShow('Changes saved'));
            setSaved(true);
          }
          setLoading(false);
        }}
      >
        {({ getField, handleSubmit, isLoading }) => (
          <ContentBox>
            <FormWrap handleSubmit={handleSubmit}>
              <GroupPairFields
                left={
                  <FieldInput field={getField('wifiName')} maxLength={50} />
                }
                right={
                  <FieldInput field={getField('wifiCode')} maxLength={50} />
                }
              />
              <FieldInput
                field={getField('wifiDetails')}
                multiline
                maxLength={255}
              />
              <SubmitButton isLoading={isLoading} />
            </FormWrap>
          </ContentBox>
        )}
      </UntypedFormComponent>
    </>
  );
}
