// @flow

import * as React from 'react';
import { Image, StyleSheet, View } from 'react-native';

import { Color } from '../constants';

const PROVIDER_ICONS = {
  airbnb: require(`../../assets/img/providers/airbnb.png`),
  bookingcom: require(`../../assets/img/providers/bookingcom.png`),
  'booking.com': require(`../../assets/img/providers/bookingcom.png`),
  homeaway: require(`../../assets/img/providers/homeaway.png`),
  tripadvisor: require(`../../assets/img/providers/tripadvisor.png`),
  'trip advisor': require(`../../assets/img/providers/tripadvisor.png`),
};

type Props = $ReadOnly<{|
  providerSlug: string | null,
  size: number,
|}>;

export default React.memo<Props>(function ProviderIcon({
  providerSlug,
  size,
}: Props) {
  const containerStyles = [
    styles.container,
    { width: size, height: size, borderRadius: size / 2 },
  ];

  if (!providerSlug || !(providerSlug in PROVIDER_ICONS)) {
    return (
      <View style={[containerStyles, { backgroundColor: Color.red }]}>
        <Image
          source={require('../../assets/img/calendar/icon_direct_booking.svg')}
          style={{
            width: size * 0.56,
            height: size * 0.56,
          }}
        />
      </View>
    );
  }

  return (
    <Image source={PROVIDER_ICONS[providerSlug]} style={containerStyles} />
  );
});

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
