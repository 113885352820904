// @flow

import styled from 'styled-components';

import { Color } from '../constants';

export default styled('div').attrs((p) => ({
  style: { backgroundImage: `url(${p.src})` },
}))`
  background-color: ${Color.imagePlaceholderBg};
  background-size: cover;
  background-position: center center;
`;
