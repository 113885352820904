// @flow

import type { Dispatch } from '../../../types';

import { Api } from '@guesthug/core';
import { useApiCallable } from '@guesthug/core';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { UntypedFormComponent } from 'react-typed-form';

import { flashShow } from '../../../actions/flash';
import { Hr } from '../../../components';
import {
  FieldInput,
  FieldUpload,
  FieldYouTubeId,
  FormWrap,
  GroupPairFields,
  SubmitButton,
  createFormValidator,
} from '../../../form';

type Props = $ReadOnly<{|
  property: Api.Property.V_Full,
  invalidate: () => void,
|}>;

export default function CheckInGuide({ property, invalidate }: Props) {
  const callApi = useApiCallable();
  const dispatch = useDispatch<Dispatch>();

  return (
    <UntypedFormComponent
      pristineValues={property}
      validator={createFormValidator({
        arriveAfter: { time: true },
        arriveBefore: { time: true },
        checkInStep1Image: { upload: true },
        checkInStep2Image: { upload: true },
        checkInStep3Image: { upload: true },
      })}
      onSubmit={async (values, { addSubmitError, setLoading }) => {
        setLoading(true);
        const response = await callApi(`/properties/${property.id}`, {
          method: 'PUT',
          jsonBody: values,
        });
        if (response) {
          dispatch(flashShow('Changes saved'));
          invalidate();
        }
        setLoading(false);

        callApi(`/properties/${property.id}/content/push`, {
          method: 'POST',
          jsonBody: {},
        });
      }}
    >
      {({ getField, handleSubmit, isLoading }) => (
        <FormWrap handleSubmit={handleSubmit}>
          <GroupPairFields
            left={
              <FieldInput
                field={{
                  ...getField('arriveAfter'),
                  label: 'Arrive after (HH:MM)*',
                }}
              />
            }
            right={
              <FieldInput
                field={{
                  ...getField('arriveBefore'),
                  label: 'Arrive before (HH:MM)*',
                }}
              />
            }
          />

          <Hr />
          <p>
            Fill out your three steps below to describe your check-in process,
            and if you'd really like to help your guests, provide a video guide.
          </p>
          <FieldYouTubeId
            field={{
              ...getField('checkInYoutubeId'),
              label: 'Add video guide',
            }}
          />

          <Hr />
          <FieldInput
            field={{
              ...getField('checkInStep1Description'),
              label: 'Step 1: Describe the first step',
            }}
            multiline
          />
          <FieldUpload
            field={getField('checkInStep1Image')}
            existingImage={property.checkInStep1Image || null}
          />

          <Hr />
          <FieldInput
            field={{
              ...getField('checkInStep2Description'),
              label: 'Step 2: Describe the second step',
            }}
            multiline
          />
          <FieldUpload
            field={getField('checkInStep2Image')}
            existingImage={property.checkInStep2Image || null}
          />

          <Hr />
          <FieldInput
            field={{
              ...getField('checkInStep3Description'),
              label: 'Step 3: Describe the third step',
            }}
            multiline
          />
          <FieldUpload
            field={getField('checkInStep3Image')}
            existingImage={property.checkInStep3Image || null}
          />

          <SubmitButton isLoading={isLoading} />
        </FormWrap>
      )}
    </UntypedFormComponent>
  );
}
