// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Color, Dimen } from '../constants';

type Props = $ReadOnly<{|
  errors?: string[],
  className?: string,
|}>;

const ErrorList = ({ errors, className }: Props) => {
  if (!errors || errors.length === 0) {
    return null;
  }

  return (
    <StyledList className={className}>
      {errors.map((errorMessage, i) => (
        <div key={i}>{errorMessage}</div>
      ))}
    </StyledList>
  );
};

export default ErrorList;

const StyledList = styled('div')`
  margin-top: ${rem(Dimen.spacing / 2)};
  margin-left: ${rem(20)};
  margin-right: ${rem(20)};

  > div {
    margin-bottom: ${rem(Dimen.spacing / 2)};
    color: ${Color.textError};
  }
`;
