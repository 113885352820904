// @flow

import type { Action, Id } from '../../types';

export type MessagingLastReadState = $ReadOnly<{
  byBooking: { [key: Id]: Id },
}>;

const initialState = {
  byBooking: {},
};

export default (
  state: MessagingLastReadState = initialState,
  action: Action
): MessagingLastReadState => {
  switch (action.type) {
    case 'MESSAGING_SET_LAST_READ': {
      const { bookingId, messageId } = action.payload;
      return { byBooking: { ...state.byBooking, [bookingId]: messageId } };
    }
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
};
