// @flow

import type { FieldProp } from 'react-typed-form';

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Switch } from '../components';
import { Color } from '../constants';

type Props = $ReadOnly<{|
  field: FieldProp<boolean | null>,
  description?: string,
  className?: string,
  alignSideBySide?: boolean,
  noMargin?: boolean,
|}>;

const FieldSwitch = ({
  field,
  description,
  className,
  alignSideBySide,
  noMargin,
}: Props) => (
  <StyledContainer>
    {description && (
      <StyledDescription alignSideBySide={alignSideBySide} $noMargin={noMargin}>
        {description}
      </StyledDescription>
    )}
    <Switch
      value={field.value || false}
      onValueChange={field.handleValueChange}
      className={className}
    />
  </StyledContainer>
);
export default FieldSwitch;

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledDescription = styled('p')`
  color: ${Color.faintBlue};
  font-size: ${rem(14)};
  font-weight: 300;
  ${(p) =>
    !p.$noMargin && `margin: ${rem(p.alignSideBySide ? 35 : 20)} 0 ${rem(5)};`};
`;
