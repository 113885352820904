// @flow

import {
  Api,
  ApiError,
  Dimen,
  Model,
  Style,
  useApiCallable,
} from '@guesthug/core';
import { GHText, Spacer } from '@guesthug/core/components';
import * as React from 'react';
import { Image, Pressable, StyleSheet, View } from 'react-native';
import styled from 'styled-components';

import {
  Modal,
  PressableLink,
  PropertyFeaturedImage,
  ProviderIcon,
  Switch,
} from '../../../components';
import { capitalize } from '../../../util';

type Props = $ReadOnly<{|
  property: Api.Property.V_Mine,
  invalidate: () => mixed,
|}>;

type ActiveFailureResponse = {
  '@context': '/contexts/ConstraintViolationList',
  '@type': 'ConstraintViolationList',
  'hydra:title': string,
  'hydra:description': string,
  violations: Array<{
    propertyPath: string,
    message: string,
    code: null | string,
  }>,
};

export default React.memo<Props>(function PropertiesTableRow({
  property,
  invalidate,
}: Props) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [activeFailureResponse, setActiveFailureResponse] =
    React.useState<null | ActiveFailureResponse>(null);
  const callApi = useApiCallable();

  const linkTo = `/properties/${property.id}`;

  function cleanViolation(message: string): string {
    return capitalize(
      message.replace(
        /([a-z])([A-Z])/g,
        (_, ...matches) => `${matches[0]} ${matches[1].toLowerCase()}`
      )
    );
  }

  const numOtherChannels = property.channels.filter(
    (c) => !['airbnb', 'booking.com', 'homeaway', 'tripadvisor'].includes(c.ota)
  ).length;

  return (
    <>
      <View style={[styles.row, property.isArchived && { opacity: 0.5 }]}>
        <PressableLink to={linkTo}>
          <StyledImage property={property} filter="medium" />
        </PressableLink>
        <Spacer />
        <View style={[{ flex: 2 }, Style.alignStart]}>
          <PressableLink to={linkTo}>
            <GHText>{property.name}</GHText>
            {Model.Property.getAddressParts(property)?.map((x, i) => (
              <React.Fragment key={i}>
                {i !== 0 && <Spacer size={0.125} />}
                <GHText size="small" color="faint">
                  {x}
                </GHText>
              </React.Fragment>
            )) ?? null}
          </PressableLink>
        </View>
        <Spacer />
        <View style={{ flex: 1 }}>
          {property.airbnbUrl && (
            <Pressable
              href={property.airbnbUrl}
              hrefAttrs={{ target: '_blank', rel: 'noreferer noopener' }}
              target="_blank"
              style={({ hovered }) => [
                styles.viewLink,
                hovered && styles.viewLinkHovered,
              ]}
            >
              <View style={[Style.row, Style.alignCenter]}>
                <Image
                  source={require('../../../../assets/img/ui/eye.svg')}
                  style={{ width: 20, height: 20 }}
                />
                <Spacer size={0.25} />
                <GHText size="small" color="interactive">
                  View
                </GHText>
              </View>
            </Pressable>
          )}
        </View>
        <Spacer />
        <View style={{ flex: 1 }}>
          <GHText>
            {property.baseRate ? `£${property.baseRate} per night` : null}
          </GHText>
        </View>
        <Spacer />
        <View style={{ flex: 1 }}>
          <GHText>
            {property.minNights ? `${property.minNights} nights` : null}
          </GHText>
        </View>
        <Spacer />
        <View style={[{ flex: 1 }, Style.row, Style.alignCenter]}>
          {!property.isArchived && (
            <>
              <Switch
                value={property.isActive ?? false}
                disabled={loading}
                onValueChange={async (value) => {
                  setLoading(true);
                  const response = await callApi(
                    `/properties/${property.id}/activation/switch`,
                    { method: 'POST', jsonBody: {} },
                    {
                      errorHandler: (err, originalHandler) => {
                        if (
                          err instanceof ApiError &&
                          'data' in err.response &&
                          err.response.status === 422
                        ) {
                          setActiveFailureResponse(err.response.data);
                        } else {
                          originalHandler(err);
                        }
                      },
                    }
                  );
                  if (response) {
                    invalidate();
                  }
                  setLoading(false);
                }}
              />
              <Spacer size={0.5} />
              <GHText color={property.isActive ? 'faint' : 'fainter'}>
                {property.isActive ? 'Open' : 'Closed'}
              </GHText>
            </>
          )}
        </View>
        <Spacer />
        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
          {property.channels.some((c) => c.active && c.ota === 'airbnb') && (
            <>
              <ProviderIcon providerSlug="airbnb" size={28} />
              <Spacer size={0.3} />
            </>
          )}
          {property.channels.some(
            (c) => c.active && c.ota === 'booking.com'
          ) && (
            <>
              <ProviderIcon providerSlug="bookingcom" size={28} />
              <Spacer size={0.3} />
            </>
          )}
          {property.channels.some((c) => c.active && c.ota === 'homeaway') && (
            <>
              <ProviderIcon providerSlug="homeaway" size={28} />
              <Spacer size={0.3} />
            </>
          )}
          {property.channels.some(
            (c) => c.active && c.ota === 'tripadvisor'
          ) && (
            <>
              <ProviderIcon providerSlug="tripadvisor" size={28} />
              <Spacer size={0.3} />
            </>
          )}
          {numOtherChannels > 0 && <GHText>+{numOtherChannels}</GHText>}
        </View>
      </View>

      {activeFailureResponse && (
        <Modal
          title="Could not Open property"
          onDismiss={() => setActiveFailureResponse(null)}
          padded
        >
          <GHText>
            The property "{property.name}" cannot be activated for syncing until
            the following issues are corrected:
          </GHText>
          <ul>
            {activeFailureResponse.violations.map((violation, i) => (
              <li key={i}>
                <GHText>
                  {cleanViolation(violation.propertyPath)}: {violation.message}
                </GHText>
              </li>
            ))}
          </ul>
        </Modal>
      )}
    </>
  );
});

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: Dimen.spacing * 0.75,
    ...Style.borderTopObject,
  },
  viewLink: {
    transitionProperty: 'opacity',
    transitionDuration: '0.25s',
  },
  viewLinkHovered: {
    opacity: 0.8,
  },
});

const StyledImage = styled(PropertyFeaturedImage)`
  width: 140px;
  height: 80px;
  border-radius: 8px;
`;
