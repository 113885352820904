// @flow

import type { FieldProp } from 'react-typed-form';

import * as React from 'react';

import { TextInput } from '../components';
import YouTubeModal from './YouTubeModal';

type Props = $ReadOnly<{|
  field: FieldProp<string | null>,
|}>;

type State = {|
  modalVisible: boolean,
|};

export default class FieldYouTubeId extends React.PureComponent<Props, State> {
  state = {
    modalVisible: false,
  };

  render() {
    const { field } = this.props;
    const { modalVisible } = this.state;

    return (
      <>
        <TextInput
          label={`${field.label} (YouTube ID)`}
          value={field.value}
          disabled={field.isLoading}
          errors={field.lastErrorList}
          onClick={
            field.isLoading
              ? () => {}
              : () => this.setState({ modalVisible: true })
          }
        />

        {modalVisible && (
          <YouTubeModal
            onChange={field.handleValueChange}
            onDismiss={() => this.setState({ modalVisible: false })}
          />
        )}
      </>
    );
  }
}
