// @flow

type Plan = $ReadOnly<{|
  name: string,
  skuAndroid: string | null,
  skuIos: string | null,
  skuStripeTest: string | null,
  skuStripeLive: string | null,
  skuStripeIdentifier: string | null,
|}>;

export function planForSku(sku: string): Plan | null {
  for (const plan of PLANS) {
    if (
      [
        plan.skuAndroid,
        plan.skuIos,
        plan.skuStripeTest,
        plan.skuStripeLive,
        plan.skuStripeIdentifier,
      ].includes(sku)
    ) {
      return plan;
    }
  }
  return null;
}

export const PLANS: Plan[] = [
  {
    name: 'Starter',
    skuAndroid: 'com.guesthug.host.standard.199gbp',
    skuIos: 'com.guesthug.host.standard',
    skuStripeTest: 'price_1JDVeVHxWFEblETzxd0V2z58',
    skuStripeLive: '_',
    skuStripeIdentifier: 'starter',
  },
  {
    name: 'Premium',
    skuAndroid: null,
    skuIos: null,
    skuStripeTest: 'price_1JDViOHxWFEblETz4TuR11Lk',
    skuStripeLive: '_',
    skuStripeIdentifier: 'premium',
  },
  {
    name: 'Professional',
    skuAndroid: 'com.guesthug.host.pro',
    skuIos: 'com.guesthug.host.pro',
    skuStripeTest: 'price_1JDVfIHxWFEblETzjPaRHghw',
    skuStripeLive: '_',
    skuStripeIdentifier: 'professional',
  },
  {
    name: 'Business',
    skuAndroid: 'com.guesthug.host.business',
    skuIos: 'com.guesthug.host.business',
    skuStripeTest: 'price_1JDVfzHxWFEblETzXexRgPrc',
    skuStripeLive: '_',
    skuStripeIdentifier: 'business',
  },
  {
    name: 'Enterprise',
    skuAndroid: 'com.guesthug.host.enterprise',
    skuIos: 'com.guesthug.host.enterprise',
    skuStripeTest: null,
    skuStripeLive: null,
    skuStripeIdentifier: null,
  },
];
