// @flow

import type { RouterHistory } from 'react-router-dom';

import { darken, ellipsis, rem } from 'polished';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Color, Dimen } from '../constants';
import Box from './Box';

type Props = $ReadOnly<{|
  title: string,
  complete?: boolean,
  noPadding?: boolean,
  children?: React.Node,
  /**
   * Allow URLs with a hash fragment to cause a section to be expanded when the
   * page first opens.
   */
  expandOnUrlHash?: string,
  history: RouterHistory,
|}>;

type State = {|
  expanded: boolean,
|};

class Expander extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { hash } = props.history.location;
    this.state = {
      expanded: Boolean(
        props.expandOnUrlHash && hash === `#${props.expandOnUrlHash}`
      ),
    };
  }

  collapse() {
    this.setState({ expanded: false });
  }

  render() {
    const { title, complete, noPadding, children } = this.props;
    const { expanded } = this.state;

    return (
      <StyledBox>
        <AreaTop onClick={() => this.setState({ expanded: !expanded })}>
          <h2>{title}</h2>
          {complete && (
            <img src={require('../../assets/img/ui/input_valid.svg')} />
          )}
          <Toggle>{expanded ? <>&ndash;</> : '+'}</Toggle>
        </AreaTop>
        {expanded && <AreaBottom noPadding={noPadding}>{children}</AreaBottom>}
      </StyledBox>
    );
  }
}

export default withRouter(Expander);

const StyledBox = styled(Box)`
  margin-bottom: ${rem(12)};
`;

const Toggle = styled('div')`
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(65)};
  font-weight: bold;
  font-size: ${rem(30)};
  background-color: #849bba;
  color: #fff;
  transition: background-color 0.25s;
`;

const AreaTop = styled('a')`
  display: flex;
  height: ${rem(65)};
  align-items: center;
  cursor: pointer;
  transition: background-color 0.25s;
  user-select: none;

  &:hover {
    background-color: #f8f8f8;

    > ${Toggle} {
      background-color: ${darken(0.1, '#849bba')};
    }
  }

  h2 {
    font-weight: 300;
    font-size: ${rem(20)};
    margin: 0 ${rem(Dimen.spacing)};
    ${ellipsis()};
    flex: 1;
  }

  img {
    width: ${rem(26)};
    margin-right: ${rem(Dimen.spacing)};
  }
`;

const AreaBottom = styled('div')`
  ${(p) =>
    !p.noPadding &&
    `margin: ${rem(Dimen.spacing / 2)} ${rem(Dimen.spacing)} ${rem(
      Dimen.spacing
    )}`};

  p {
    font-size: ${rem(14)};
    color: ${Color.faintBlue};
  }
`;
