// @flow

import type { Dispatch } from '../../../types';

import { Api, Model, useApiCallable } from '@guesthug/core';
import { GHText, Line, Spacer } from '@guesthug/core/components';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useFormGroup } from 'react-typed-form';

import { flashShow } from '../../../actions/flash';
import { Expander } from '../../../components';
import { FormWrap, SubmitButton } from '../../../form';
import { entries } from '../../../util';
import PhotoForm from './PhotoForm';

type Props = $ReadOnly<{|
  property: Api.Property.V_Full,
  complete: boolean,
  invalidate: () => void,
|}>;

function blobToBase64(blob: Blob): Promise<string | null> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export default function SectionPhotos({
  property,
  complete,
  invalidate,
}: Props) {
  const callApi = useApiCallable();
  const dispatch = useDispatch<Dispatch>();

  const [uploading, setUploading] = React.useState<boolean>(false);
  const [creations, setCreations] = React.useState<{
    [key: string]: { path: string, previewUri: string | null },
  }>({});

  const formGroup = useFormGroup({
    initialKeys: property.images.map((image) => image['@id']),
  });

  async function onFile(ev) {
    const uploadFile = ev.target.files.length === 0 ? null : ev.target.files[0];
    if (!uploadFile) return;

    setUploading(true);
    const response = await callApi(`/upload`, {
      method: 'POST',
      body: uploadFile,
    });
    if (!response) return;

    const previewUri = await blobToBase64(uploadFile);

    const createKey = `create-${Math.floor(Math.random() * 10000)}`;

    setCreations((prevCreations) =>
      Object.fromEntries([
        ...entries(prevCreations),
        [createKey, { path: response.data.path, previewUri }],
      ])
    );

    formGroup.addKey(createKey);
    setUploading(false);
  }

  return (
    <Expander title="Photos" complete={complete}>
      <p>Minimum 10 photos</p>

      <FormWrap
        handleSubmit={() =>
          formGroup.submit({
            onFinish: async ({ hasErrors }) => {
              formGroup.setLoading(true);
              const toDelete = property.images
                .map((x) => x['@id'])
                .filter((iri) => !formGroup.hasKeyOrAlias(iri));
              for (const iri of toDelete) {
                await callApi(iri, { method: 'DELETE' });
              }
              formGroup.setLoading(false);

              if (!hasErrors) {
                dispatch(flashShow('Changes saved'));
              }
              invalidate();

              callApi(`/properties/${property.id}/content/push`, {
                method: 'POST',
                jsonBody: {},
              });
            },
          })
        }
      >
        {formGroup.keys.map((key) => (
          <React.Fragment key={key}>
            <PhotoForm
              propertyIri={property['@id']}
              existingOrCreation={
                property.images.find((image) => image['@id'] === key) ??
                creations[key]
              }
              formGroup={formGroup}
              formGroupKey={key}
            />
            <Spacer />
            <Line h />
            <Spacer />
          </React.Fragment>
        ))}

        <Spacer size={0.25} />

        {uploading ? (
          <GHText color="interactive" style={{ opacity: 0.5 }}>
            Uploading...
          </GHText>
        ) : (
          <label style={{ cursor: 'pointer' }}>
            <GHText color="interactive">+ Upload another Photo</GHText>
            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={onFile}
              style={{ display: 'none' }}
            />
          </label>
        )}

        <Spacer />
        <Line h />
        <SubmitButton
          normalLabel={Model.Property.getSubmitButtonLabel(property)}
          isLoading={formGroup.isLoading}
        />
      </FormWrap>
    </Expander>
  );
}
