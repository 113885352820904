// @flow

import type { Id } from '../../types';

import { Api } from '@guesthug/core';
import { rem } from 'polished';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import {
  AddNewTile,
  ContentLoading,
  ImageTileList,
  ReturnLink,
  ScreenError,
} from '../../components';
import { Dimen } from '../../constants';
import CopyFromExistingPrompt from './CopyFromExistingPrompt';
import { LocalGuideListTile } from './LocalGuideListTile';
import {
  LOCAL_GUIDE_TYPES,
  typeToPluralTitle,
  typeToTitle,
} from './typeHelpers';

type Props = $ReadOnly<{|
  match: { params: { propertyId: Id } },
|}>;

export default function LocalGuideListPage({ match }: Props) {
  const { propertyId } = match.params;

  const returnLink = (
    <ReturnLink to={`/properties/${propertyId}`}>
      Return to Property Overview
    </ReturnLink>
  );

  const api = Api.Property.useReadItemFull(propertyId);
  const apiMine = Api.Property.useReadCollectionMine();

  if (api.error) return <ScreenError {...api} />;
  if (apiMine.error) return <ScreenError {...apiMine} />;
  if (!api.data || !apiMine.data) return <ContentLoading />;

  const property = api.data;
  const userProperties = apiMine.data['hydra:member'];

  const localGuides = property.localGuides.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <>
      <Helmet>
        <title>{`${property.name} - Local Guides`}</title>
      </Helmet>

      {returnLink}
      {localGuides.length === 0 && (
        <CopyFromExistingPrompt
          copyableProperties={userProperties.filter((p) => p.localGuideCount)}
          propertyId={propertyId}
        />
      )}

      {LOCAL_GUIDE_TYPES.map((type) => (
        <div key={type}>
          <StyledTypeTitle>{typeToPluralTitle(type)}</StyledTypeTitle>
          <ImageTileList>
            <AddNewTile
              label={`Add New ${typeToTitle(type)}`}
              to={`/properties/${propertyId}/local-guide/add/${type}`}
            />
            {localGuides
              .filter((x) => x.type === type)
              .map((localGuide) => (
                <LocalGuideListTile
                  key={localGuide.id}
                  localGuide={localGuide}
                  propertyId={propertyId}
                />
              ))}
          </ImageTileList>
        </div>
      ))}
    </>
  );
}

const StyledTypeTitle = styled('h2')`
  margin: ${rem(Dimen.spacing)} 0 ${rem(Dimen.spacing / 4)};
  font-size: ${rem(20)};
  font-weight: 300;
  border-bottom: 1px #e7ebf1 solid;
  padding-bottom: ${rem(Dimen.spacing / 4)};
  display: flex;
  align-items: center;

  img {
    margin-right: ${rem(Dimen.spacing / 2)};
  }
`;
