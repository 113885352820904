// @flow

import type { FieldProp } from 'react-typed-form';

import * as React from 'react';

import FieldNumber from './FieldNumber';

type Props = $ReadOnly<{|
  ...React.ElementConfig<typeof FieldNumber>,
  field: FieldProp<number>,
|}>;

export default function FieldNumberStrict({ field, ...props }: Props) {
  const { allowNegative, hideZero } = props;

  const [lastTypedValue, setLastTypedValue] = React.useState<string | void>();

  let displayValue =
    field.value === 0 && hideZero ? '' : field.value?.toString() ?? '';
  if (['', '-'].includes(lastTypedValue)) {
    displayValue = lastTypedValue;
  }

  return (
    <FieldNumber
      value={displayValue}
      onChange={(ev) => {
        const value = ev.target.value.replace(
          allowNegative ? /[^\d-]/g : /[^\d]/g,
          ''
        );
        setLastTypedValue(value);

        if (value === '' || value === '-' || isNaN(value) || !isFinite(value)) {
          field.handleValueChange(0);
          return;
        }

        field.handleValueChange(parseInt(value, 10));
      }}
      field={(field: any)}
      {...props}
    />
  );
}
