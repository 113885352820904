// @flow

import { Api } from '@guesthug/core';
import { compareDesc, parseISO } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Avatar, EmptyMessageBlock, Stars } from '../../components';
import { Color, Dimen } from '../../constants';
import DashboardBox from './DashboardBox';

type Props = $ReadOnly<{|
  bookings: Array<Api.Booking.V_Dashboard>,
|}>;

export default function BoxLatestFeedback({ bookings }: Props) {
  const feedbacks = [];
  bookings
    .filter((b) => b.ratingOverall && b.mainGuest)
    .sort((a, b) => compareDesc(parseISO(a.startDate), parseISO(b.startDate)))
    .forEach((booking) => {
      if (feedbacks.length < 3) feedbacks.push(booking);
    });

  return (
    <DashboardBox title="Latest Feedback" tall>
      {feedbacks.length === 0 && (
        <EmptyMessageBlock>
          You haven't received any feedback yet.
        </EmptyMessageBlock>
      )}
      {feedbacks.map((booking) => {
        if (!booking.ratingOverall || !booking.mainGuest) return null;
        return (
          <StyledItem key={booking.id}>
            <Avatar user={booking.mainGuest} size="small" />
            <div>
              <span>
                {booking.mainGuest.firstName} {booking.mainGuest.lastName}
              </span>
              <strong>{booking.property.name}</strong>
              <Stars count={booking.ratingOverall} />
            </div>
          </StyledItem>
        );
      })}
    </DashboardBox>
  );
}

const StyledItem = styled('div')`
  display: flex;
  align-items: flex-start;
  padding: ${rem(Dimen.spacing / 2)};
  border-bottom: 1px #e7ebf1 solid;

  > div:last-child {
    margin-left: ${rem(Dimen.spacing / 2)};

    span {
      font-size: ${rem(14)};
      color: ${Color.faintBlue};
    }

    strong {
      display: block;
      margin: ${rem(4)} 0 ${rem(6)};
      font-size: ${rem(12)};
    }
  }
`;
