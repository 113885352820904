// @flow

import { lighten, rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { TextInput } from '../components';
import { Color, Dimen } from '../constants';
import { debounce, logError } from '../util';

type Props = $ReadOnly<{|
  /**
   * Establishment seems to cut out a lot of things, e.g. parks, "London Eye",
   * "Brighton Pier".  Seems better to leave out in some cases.
   */
  placeType?: 'geocode' | 'establishment',
  onPredictionPress: (placeId: string) => mixed,
  /**
   * The point around which to receive location information
   */
  location?: $ReadOnly<{| latitude: number, longitude: number |}>,
  /**
   * Distance (in meters) within which to return place results.  Just a
   * guideline, not strictly enforces
   */
  radius?: number,
  initialQuery?: string,
  className?: string,
|}>;

type Result = {|
  placeId: string,
  description: string,
  mainText: string,
  secondaryText: string,
|};

type State = {|
  query: string,
  selectedName: string | null,
  loading: boolean,
  results: $ReadOnlyArray<Result>,
  hasFocus: boolean,
|};

const labels = {
  geocode: 'Post code / ZIP',
  establishment: 'Search by Name',
  default: 'Search by Name',
};

export default class GooglePlacesLookup extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      query: props.initialQuery || '',
      selectedName: null,
      loading: false,
      results: [],
      hasFocus: false,
    };
  }

  componentDidMount() {
    if (this.state.query) {
      this.findPlace();
    }
  }

  findPlace = debounce(async () => {
    const { placeType, location, radius } = this.props;
    const { query, selectedName } = this.state;

    if (selectedName) {
      return;
    }

    this.setState({ loading: true });

    const service = new google.maps.places.AutocompleteService();
    const request = {
      input: query,
      types: placeType && [placeType],
      location:
        location &&
        new google.maps.LatLng({
          lat: location.latitude,
          lng: location.longitude,
        }),
      radius,
    };
    service.getPlacePredictions(request, (data, status) => {
      let predictions = data;
      if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        predictions = [];
      } else if (status !== google.maps.places.PlacesServiceStatus.OK) {
        logError(
          new Error(`getPlacePredictions returned ${status}`),
          'GooglePlacesLookup',
          { extras: { data } }
        );
        return;
      }

      this.setState({
        loading: false,
        results: predictions.map((prediction) => ({
          placeId: prediction.place_id,
          description: prediction.description,
          mainText: prediction.structured_formatting.main_text,
          secondaryText: prediction.structured_formatting.secondary_text,
        })),
      });
    });
  }, 500);

  render() {
    const { placeType, onPredictionPress, className } = this.props;
    const { selectedName, query, results, hasFocus } = this.state;

    let content = null;
    if (results.length === 0) {
      content = <StyledZeroResults>No results found</StyledZeroResults>;
    } else if (!selectedName) {
      content = results.map((result) => (
        <StyledResult
          key={result.placeId}
          onClick={() => {
            this.setState({ selectedName: result.mainText });
            onPredictionPress(result.placeId);
          }}
        >
          <strong>{result.mainText}</strong>
          <span>{result.secondaryText}</span>
        </StyledResult>
      ));
    }

    return (
      <StyledContainer className={className}>
        <TextInput
          label={labels[placeType || 'default']}
          value={selectedName || query}
          onChange={(e) => {
            this.setState({ selectedName: null, query: e.target.value });
            this.findPlace();
          }}
          onFocus={() => this.setState({ hasFocus: true })}
          onBlur={() =>
            // Needs a bit of delay otherwise clicks would never be registered
            setTimeout(() => this.setState({ hasFocus: false }), 250)
          }
        />
        {content && hasFocus && (
          <StyledDropdown>
            {content}
            <span className="powered">Powered by Google</span>
          </StyledDropdown>
        )}
      </StyledContainer>
    );
  }
}

const StyledContainer = styled('div')`
  position: relative;
`;

const StyledDropdown = styled('div')`
  position: absolute;
  top: ${rem(108)};
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #fff;
  border: 1px #e7ebf1 solid;
  box-shadow: 0 0 ${rem(5)} rgba(0, 0, 0, 0.2);

  .powered {
    display: block;
    font-size: ${rem(12)};
    color: ${Color.faintBlue};
    text-align: right;
    padding: ${rem(Dimen.spacing / 8)};
  }
`;

const StyledZeroResults = styled('div')`
  padding: ${rem(Dimen.spacing / 2)};
  color: ${Color.faintBlue};
`;

const StyledResult = styled('a')`
  display: block;
  background-color: #fff;
  padding: ${rem(Dimen.spacing / 2)};
  border-bottom: 1px #e7ebf1 solid;
  transition: background-color 0.25s;

  strong {
    font-weight: 400;
    margin-right: ${rem(Dimen.spacing / 4)};
  }

  span {
    font-weight: 300;
    color: ${Color.faintBlue};
  }

  &:hover {
    background-color: ${lighten(0.2, Color.fainterBlue)};
  }
`;
