// @flow

import type { Dispatch, Id } from '../../../types';

import { Api } from '@guesthug/core';
import { useApiCallable } from '@guesthug/core';
import { rem } from 'polished';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormComponent } from 'react-typed-form';
import styled from 'styled-components';

import { flashShow } from '../../../actions/flash';
import { EmptyMessageBlock, Expander } from '../../../components';
import { Dimen } from '../../../constants';
import { FormWrap, SubmitButton } from '../../../form';
import ServiceRow from './ServiceRow';

type Props = $ReadOnly<{|
  services: Array<Api.Service.BaseView>,
  complete: boolean,
  invalidate: () => void,
  property: Api.Property.V_Full,
|}>;

export default function SectionServices({
  services,
  complete,
  invalidate,
  property,
}: Props) {
  const callApi = useApiCallable();
  const dispatch = useDispatch<Dispatch>();

  const allServices = services.sort((a, b) => a.title.localeCompare(b.title));

  const activeServices = allServices
    .filter((s) => property.services.includes(s['@id']))
    .map((s) => s['@id']);

  const inner =
    allServices.length === 0 ? (
      <EmptyMessageBlock>
        You haven't created any <Link to="/services">Services</Link> yet.
      </EmptyMessageBlock>
    ) : (
      <FormComponent
        defaultValues={{ services: activeServices }}
        onSubmit={async (values, { addSubmitError, setLoading }) => {
          setLoading(true);
          const response = await callApi(`/properties/${property.id}`, {
            method: 'PUT',
            jsonBody: {
              services: values.services,
            },
          });
          if (response) {
            dispatch(flashShow('Changes saved'));
            invalidate();
          }
          setLoading(false);
        }}
      >
        {({ getField, handleSubmit, isLoading }) => {
          const selected = getField('services').value;

          return (
            <FormWrap handleSubmit={handleSubmit}>
              {allServices.map((service) => (
                <ServiceRow
                  key={service['@id']}
                  service={service}
                  enabled={selected.includes(service['@id'])}
                  onChange={(value: boolean) => {
                    let newSelected;
                    if (value) {
                      newSelected = [...selected, service['@id']];
                    } else {
                      newSelected = selected.filter(
                        (item) => item !== service['@id']
                      );
                    }
                    getField('services').handleValueChange(newSelected);
                  }}
                />
              ))}
              <StyledFormBottom>
                <SubmitButton isLoading={isLoading} />
              </StyledFormBottom>
            </FormWrap>
          );
        }}
      </FormComponent>
    );

  return (
    <Expander title="Guest Services" complete={complete} noPadding>
      {inner}
    </Expander>
  );
}

const StyledFormBottom = styled('div')`
  padding: 0 ${rem(Dimen.spacing)} ${rem(Dimen.spacing)};
`;
