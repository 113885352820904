// @flow

import type { Id } from '../../../types';

import { useApiCallable } from '@guesthug/core';
import { useInvalidation } from 'api-read-hook';
import { rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Color, Dimen } from '../../../constants';

type Props = $ReadOnly<{|
  bookingId: Id,
  invalidate: () => void,
  messageTemplates: Array<{
    '@id': string,
    '@type': 'MessageTemplate',
    id: Id,
    name: string,
    content: string,
  }>,
|}>;

export default function Compose({
  bookingId,
  messageTemplates,
  invalidate,
}: Props) {
  const contentRef: { current: null | React$ElementRef<string> } =
    React.useRef(null);
  const callApi = useApiCallable();
  const { invalidateMatching } = useInvalidation();

  const [text, setText] = React.useState<string>('');
  const [sending, setSending] = React.useState<boolean>(false);
  const [templatesExpanded, setTemplatesExpanded] =
    React.useState<boolean>(false);

  async function onSend() {
    const content = text?.trim();
    if (content?.length === 0) return;
    setSending(true);

    await callApi('/messages', {
      method: 'POST',
      jsonBody: { booking: bookingId, content },
    });

    const contentNode = contentRef.current;
    if (contentNode) contentNode.innerText = '';
    invalidate();
    invalidateMatching('/messages');
    setSending(false);
    setText('');
  }

  function onInput() {
    const contentNode = contentRef.current;
    if (!contentNode) return;
    const inputText = contentNode.innerText;
    if (inputText) setText(inputText);
  }

  function setTextFromTemplate(templateText) {
    const contentNode = contentRef.current;
    if (!contentNode) return;
    contentNode.innerText = templateText;
    setText(templateText);
  }

  return (
    <>
      <StyledContainer>
        <StyledInput sending={sending}>
          <div contentEditable={!sending} ref={contentRef} onInput={onInput} />
          <StyledSend onClick={sending ? undefined : onSend} sending={sending}>
            <img src={require('../../../../assets/img/messaging/send.svg')} />
          </StyledSend>
        </StyledInput>
      </StyledContainer>

      <StyledTemplatesPrompt
        onClick={() => setTemplatesExpanded(!templatesExpanded)}
      >
        <img src={require('../../../../assets/img/ui/arrow.svg')} />
        <span>Message templates</span>
      </StyledTemplatesPrompt>

      {templatesExpanded && (
        <StyledTemplates>
          {messageTemplates
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((template) => (
              <a
                key={template.id}
                onClick={() => setTextFromTemplate(template.content)}
              >
                {template.name}
              </a>
            ))}
          <Link to="/settings">Manage message templates</Link>
        </StyledTemplates>
      )}
    </>
  );
}

const StyledContainer = styled('div')`
  padding: ${rem(Dimen.spacing / 2)} ${rem(Dimen.spacing / 2)};
  background-color: #ccd2d8;
`;

const StyledInput = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(p) => (p.sending ? '#eee' : '#fff')};
  border-radius: ${rem(10)};

  > div {
    flex: 1;
    padding: ${rem(Dimen.spacing / 4)} ${rem(Dimen.spacing / 2)};
    font-size: ${rem(14)};
    min-height: ${rem(18)};
    max-height: ${rem(160)};
    overflow: auto;
  }
`;

const StyledSend = styled('a')`
  cursor: default;

  img {
    width: ${rem(20)};
    padding: ${rem(5)} ${rem(10)} ${rem(3)} 0;
    transition: opacity 0.25s;
  }

  ${(p) =>
    !p.sending &&
    `
    &:hover {
      cursor: pointer;
      color: ${Color.vibrantBlue};
      opacity: 0.75;
    }
  `};
`;

const StyledTemplatesPrompt = styled('a')`
  display: block;
  padding: ${rem(Dimen.spacing / 4)} ${rem(Dimen.spacing / 2)};
  font-size: ${rem(13)};
  background-color: #e2e7ec;
  color: ${Color.fainterBlue};
  transition: all 0.25s;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    color: ${Color.vibrantBlue};
  }

  img {
    width: ${rem(12)};
    margin-right: ${rem(8)};
  }
`;

const StyledTemplates = styled('div')`
  background-color: #fff;

  a {
    display: block;
    padding: ${rem(Dimen.spacing / 4)} ${rem(Dimen.spacing / 2)};
    border-bottom: #ddd 1px solid;
    color: ${Color.faintBlue};
    font-size: ${rem(13)};
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: ${Color.vibrantBlue};
    }
  }

  a:last-child {
    color: ${Color.fainterBlue};
    background-color: #fafafa;
  }
`;
