// @flow

import type { Id } from '../../../types';

import { Api } from '@guesthug/core';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { ContentLoading, ScreenError } from '../../../components';
import { Color, Dimen } from '../../../constants';
import BubblesList from './BubblesList';
import Compose from './Compose';

type Props = $ReadOnly<{|
  bookingId: Id,
  userId: Id,
  onSelectId: (Id | null) => mixed,
|}>;

export default function MessagingThread({
  bookingId,
  userId,
  onSelectId,
}: Props) {
  const api = Api.Message.useReadCollectionBookingSub(bookingId, undefined, {
    staleWhileInvalidated: true,
    invalidateAge: 10,
  });
  const apiUser = Api.User.useReadItemFull(userId);

  if (api.error) return <ScreenError {...api} />;
  if (apiUser.error) return <ScreenError {...apiUser} />;
  if (!api.data || !apiUser.data) return <ContentLoading />;

  const messages = api.data['hydra:member'];
  const user = apiUser.data;

  const { messageTemplates } = user;

  return (
    <>
      <StyledAreaBack>
        <a onClick={() => onSelectId(null)}>
          <img src={require('../../../../assets/img/ui/arrow.svg')} />
        </a>
        {user.firstName} {user.lastName}
      </StyledAreaBack>
      <BubblesList messages={messages} userId={userId} />
      <Compose
        bookingId={bookingId}
        messageTemplates={messageTemplates}
        invalidate={api.invalidate}
      />
    </>
  );
}

const StyledAreaBack = styled('div')`
  background-color: #fff;

  a {
    display: inline-block;
    padding: ${rem(Dimen.spacing / 4)} ${rem(Dimen.spacing / 2)};
    transition: color 0.25s;
    &:hover {
      color: ${Color.vibrantBlue};
    }
  }

  img {
    width: 14px;
    transform: rotate(90deg);
    padding-bottom: 2px;
  }
`;
