//@flow

import { Api } from '@guesthug/core';
import { useApiCallable } from '@guesthug/core';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Modal } from '../../../components';
import { Color, Dimen } from '../../../constants';
import { SubmitButton } from '../../../form';

type Props = $ReadOnly<{|
  calendar: {
    '@id': string,
    '@type': 'Calendar',
    id: string,
    lastSyncedAt: string | null,
    provider: Api.Provider.BaseView,
    url: string,
  },
  property: Api.Property.V_Full,
  invalidate: () => void,
|}>;

export default function ImportedCalendarRow({
  calendar,
  property,
  invalidate,
}: Props) {
  const callApi = useApiCallable();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] =
    React.useState<boolean>(false);

  const bookings = property.bookings.filter(
    (b) => b.calendar?.id === calendar.id
  );
  const numConfirmed = bookings.filter((b) => b.mainGuest).length;

  return (
    <>
      <StyledRow>
        <img
          src={require(`../../../../assets/img/providers/${calendar.provider.slug}.png`)}
        />
        <div>
          <StyledTop>{calendar.url}</StyledTop>
          <StyledBottom>
            <span>{bookings.length} bookings</span>
            <span>{numConfirmed} confirmed</span>
            {calendar.lastSyncedAt && (
              <span>
                Last synced{' '}
                {formatDistanceToNow(parseISO(calendar.lastSyncedAt), {
                  addSuffix: true,
                })}
              </span>
            )}
            <a>Sync now</a>
            {!loading && numConfirmed === 0 && (
              <a onClick={() => setDeleteModalVisible(true)}>Delete</a>
            )}
          </StyledBottom>
        </div>
      </StyledRow>

      {deleteModalVisible && (
        <Modal
          title="Really delete?"
          padded
          onDismiss={() => setDeleteModalVisible(false)}
        >
          <StyledP>
            If you delete this calendar, all associated bookings invites will
            also be deleted, even if you've already shared them.
          </StyledP>

          <StyledSubmitButton
            isLoading={loading}
            normalLabel="Delete Calendar"
            loadingLabel="Deleting"
            onClick={async () => {
              setLoading(true);
              const response = await callApi(`/calendars/${calendar.id}`, {
                method: 'DELETE',
              });
              if (response) {
                setDeleteModalVisible(false);
                invalidate();
              }
              setLoading(false);
            }}
          />
        </Modal>
      )}
    </>
  );
}

const StyledRow = styled('div')`
  padding: ${rem(Dimen.spacing / 2)} ${rem(Dimen.spacing)};
  border-top: 1px #e7ebf1 solid;
  display: flex;
  align-items: center;
  overflow: hidden;

  > img {
    width: ${rem(42)};
    margin-right: ${rem(Dimen.spacing / 2)};
    flex-shrink: 0;
  }

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
`;

const StyledTop = styled('div')`
  margin-bottom: ${rem(Dimen.spacing / 8)};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledBottom = styled('div')`
  display: flex;
  align-items: center;
  color: ${Color.faintBlue};
  font-size: ${rem(14)};

  span {
    margin-right: ${rem(Dimen.spacing / 2)};
  }

  a {
    margin-right: ${rem(Dimen.spacing / 2)};
    color: ${Color.vibrantBlue};
  }
`;

const StyledP = styled('p')`
  margin-top: 0;
`;

const StyledSubmitButton = styled(SubmitButton)`
  align-self: flex-start;
`;
