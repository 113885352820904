// @flow

import type { Dispatch } from '../../../types';

import { useApiCallable } from '@guesthug/core';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { FormComponent } from 'react-typed-form';

import { flashShow } from '../../../actions/flash';
import { Modal, PaddedArea } from '../../../components';
import {
  FieldInput,
  FormWrap,
  SubmitButton,
  createFormValidator,
} from '../../../form';

type Props = $ReadOnly<{|
  onDismiss: () => mixed,
  onSuccess: () => mixed,
|}>;

export default function AddPropertyModal({ onDismiss, onSuccess }: Props) {
  const callApi = useApiCallable();
  const dispatch = useDispatch<Dispatch>();

  return (
    <Modal title="Add Property" onDismiss={onDismiss}>
      <FormComponent
        validator={createFormValidator({
          name: { strictPresence: { mustDefine: true } },
        })}
        onSubmit={async (values, { addSubmitError, setLoading }) => {
          setLoading(true);
          const response = await callApi(
            '/properties',
            {
              method: 'POST',
              jsonBody: values,
            },
            { addSubmitError }
          );
          if (response) {
            dispatch(flashShow('Listing Added'));
            onSuccess();
          }
          setLoading(false);
          onDismiss();
        }}
      >
        {({ getField, handleSubmit, isLoading }) => (
          <FormWrap handleSubmit={handleSubmit}>
            <PaddedArea all>
              <FieldInput
                field={getField('name')}
                hint="Maximum 50 characters for Airbnb"
              />
              <SubmitButton isLoading={isLoading} />
            </PaddedArea>
          </FormWrap>
        )}
      </FormComponent>
    </Modal>
  );
}
