// @flow

import type { Dispatch } from '../../../types';

import { Api, Model, Style, usePricingControls } from '@guesthug/core';
import { GHText, Line, PaddedArea, Spacer } from '@guesthug/core/components';
import * as React from 'react';
import { Pressable, View } from 'react-native';
import { useDispatch } from 'react-redux';

import { flashShow } from '../../../actions/flash';
import { Button, Expander } from '../../../components';
import {
  FieldMoney,
  FieldMoneyStrict,
  FieldNumber,
  FieldNumberStrict,
  FormWrap,
  SubmitButton,
} from '../../../form';
import CopyPricingForm from './CopyPricingForm';
import PricingControl from './PricingControl';
import SeasonalRateForm from './SeasonalRateForm';

type Props = $ReadOnly<{|
  property: Api.Property.V_Full,
  complete: boolean,
  invalidate: () => void,
|}>;

const FIELD_WIDTH = 240;

export default function SectionPricing({
  property,
  complete,
  invalidate,
}: Props) {
  const dispatch = useDispatch<Dispatch>();

  const pricingControls = usePricingControls(property);
  const { form, formGroup } = pricingControls;

  const [copyFormVisible, setCopyFormVisible] = React.useState<boolean>(false);
  const [securityDepositHintVisible, setSecuritDepositHintVisible] =
    React.useState<boolean>(false);

  return (
    <Expander
      title="Manage Pricing"
      complete={complete}
      expandOnUrlHash="pricing"
      noPadding
    >
      <FormWrap
        handleSubmit={() =>
          formGroup.submit({
            onFinish: async ({ hasErrors }) => {
              pricingControls.onFinishGroupSave();

              if (!hasErrors) {
                dispatch(flashShow('Changes saved'));
              }
              invalidate();
            },
          })
        }
      >
        {copyFormVisible ? (
          <CopyPricingForm propertyId={property.id} />
        ) : (
          <PaddedArea horizontal vertical="half" style={Style.alignStart}>
            <Button
              colorScheme="faintBlue"
              label="Import pricing"
              onPress={() => setCopyFormVisible(true)}
            />
          </PaddedArea>
        )}

        <Line h />

        <PricingControl {...pricingControls.baseRate}>
          <FieldMoney
            currency="gbp"
            field={form.getField('baseRate')}
            label={null}
            wholeNumbers
            inputWidth={FIELD_WIDTH}
            noMargin
          />
        </PricingControl>

        <PricingControl {...pricingControls.lengthOfStay}>
          <FieldNumberStrict
            field={form.getField('weeklyDiscount')}
            label={null}
            afterInput="% - Weekly"
            hideZero
            inputWidth={FIELD_WIDTH}
            noMargin
          />
          <Spacer size={0.5} />
          <FieldNumberStrict
            field={form.getField('fortnightlyDiscount')}
            label={null}
            afterInput="% - Fortnightly"
            hideZero
            inputWidth={FIELD_WIDTH}
            noMargin
          />
          <Spacer size={0.5} />
          <FieldNumberStrict
            field={form.getField('monthlyDiscount')}
            label={null}
            afterInput="% - Monthly"
            hideZero
            inputWidth={FIELD_WIDTH}
            noMargin
          />
          <Spacer size={0.5} />
          <FieldNumberStrict
            field={form.getField('quarterlyDiscount')}
            label={null}
            afterInput="% - Quarterly"
            hideZero
            inputWidth={FIELD_WIDTH}
            noMargin
          />
        </PricingControl>

        <PricingControl {...pricingControls.earlyBird}>
          <GHText color="faint">If booking is at least</GHText>
          <Spacer size={0.5} />
          <FieldNumber
            field={form.getField('earlyBirdMinDaysBeforeArrival')}
            label={null}
            afterInput="days in advance"
            inputWidth={FIELD_WIDTH}
            noMargin
          />
          <Spacer size={0.5} />

          <GHText color="faint">then discount by</GHText>
          <Spacer size={0.5} />
          <FieldNumberStrict
            field={form.getField('earlyBirdDiscount')}
            label={null}
            afterInput="%"
            hideZero
            inputWidth={FIELD_WIDTH}
            noMargin
          />
        </PricingControl>

        <PricingControl {...pricingControls.lastMinute}>
          <GHText color="faint">If booking is no more than</GHText>
          <Spacer size={0.5} />
          <FieldNumber
            field={form.getField('lastMinuteMaxDaysBeforeArrival')}
            label={null}
            afterInput="days before arrival"
            inputWidth={FIELD_WIDTH}
            noMargin
          />
          <Spacer size={0.5} />

          <GHText color="faint">then discount by</GHText>
          <Spacer size={0.5} />
          <FieldNumberStrict
            field={form.getField('lastMinuteDiscount')}
            label={null}
            afterInput="%"
            hideZero
            inputWidth={FIELD_WIDTH}
            noMargin
          />
        </PricingControl>

        <PricingControl {...pricingControls.weekend}>
          <FieldNumber
            field={form.getField('weekendMarkup')}
            label={null}
            afterInput="%"
            hideZero
            inputWidth={FIELD_WIDTH}
            noMargin
          />
        </PricingControl>

        <PricingControl {...pricingControls.orphanDay}>
          <GHText color="faint">
            On Orphan Days, reduce Minimum Nights to
          </GHText>
          <Spacer size={0.5} />
          <FieldNumber
            field={form.getField('orphanNightsMinNights')}
            label={null}
            afterInput="nights"
            inputWidth={FIELD_WIDTH}
            noMargin
          />
          <Spacer size={0.5} />

          <GHText color="faint">with a markup of</GHText>
          <Spacer size={0.5} />
          <FieldNumber
            field={form.getField('orphanNightsMarkup')}
            label={null}
            afterInput="%"
            inputWidth={FIELD_WIDTH}
            noMargin
          />
        </PricingControl>

        <PricingControl {...pricingControls.seasonal}>
          {formGroup.keys
            .filter((key) => key !== 'property')
            .map((key) => (
              <SeasonalRateForm
                key={key}
                propertyIri={property['@id']}
                existing={property.seasonalRates.find(
                  (sr) => sr['@id'] === key
                )}
                formGroup={formGroup}
                formGroupKey={key}
              />
            ))}

          <Spacer size={0.25} />
          <Pressable
            onPress={() =>
              formGroup.addKey(`create-${Math.floor(Math.random() * 10000)}`)
            }
          >
            <GHText color="interactive">+ Add another Seasonal Rate</GHText>
          </Pressable>
        </PricingControl>

        <PricingControl {...pricingControls.extraCharges}>
          <Spacer size={0.5} />
          <FieldMoneyStrict
            field={form.getField('securityDeposit')}
            label="Security Deposit"
            wholeNumbers
            currency={property.owner.currency}
            inputWidth={FIELD_WIDTH}
            hideZero
            noMargin
          />
          <Spacer size={0.5} />
          {securityDepositHintVisible ? (
            <View style={{ maxWidth: 640 }}>
              <GHText color="faint" size="small">
                Security deposits follow the policies of the OTAs, for example:
              </GHText>
              <Spacer size={0.5} />
              <GHText color="faint" size="small">
                <GHText color="faint" size="small" weight="bold">
                  Airbnb
                </GHText>
                {' - '}
                Damage claims need to be submitted within 14 days of checkout.
              </GHText>
              <Spacer size={0.25} />
              <GHText color="faint" size="small">
                <GHText color="faint" size="small" weight="bold">
                  Booking.com
                </GHText>
                {' - '}
                Your guests don't pay you a damage deposit. If something a your
                property is damaged, you report it to Booking.com, providing
                full details about the damage, and Booking.com ask the guest to
                pay. You should receive this amount in your next payout.
              </GHText>
            </View>
          ) : (
            <Pressable onPress={() => setSecuritDepositHintVisible(true)}>
              <GHText color="interactive" size="small">
                Show security deposit OTA details
              </GHText>
            </Pressable>
          )}
          <Spacer />
          <Line h />
          <Spacer />
          <FieldMoneyStrict
            field={form.getField('cleaningFee')}
            label="Cleaning Fee"
            wholeNumbers
            currency={property.owner.currency}
            inputWidth={FIELD_WIDTH}
            hideZero
            noMargin
          />
          <Spacer />
          <FieldMoneyStrict
            field={form.getField('linenFee')}
            label="Linen Fee"
            wholeNumbers
            currency={property.owner.currency}
            inputWidth={FIELD_WIDTH}
            hideZero
            noMargin
          />
          <Spacer />
          <FieldMoneyStrict
            field={form.getField('managementFee')}
            label="Management Fee"
            wholeNumbers
            currency={property.owner.currency}
            inputWidth={FIELD_WIDTH}
            hideZero
            noMargin
          />
          <Spacer />
          <FieldMoneyStrict
            field={form.getField('cityTax')}
            label="Community Taxes"
            wholeNumbers
            currency={property.owner.currency}
            inputWidth={FIELD_WIDTH}
            hideZero
            noMargin
          />
          <Spacer />
          <FieldMoneyStrict
            field={form.getField('extraGuestPrice')}
            label="Extra Guest Price"
            wholeNumbers
            currency={property.owner.currency}
            inputWidth={FIELD_WIDTH}
            hideZero
            noMargin
          />
        </PricingControl>
        <PaddedArea horizontal>
          <Spacer size={0.75} />
          <View style={Style.alignStart}>
            <SubmitButton
              normalLabel={Model.Property.getSubmitButtonLabel(property)}
              isLoading={form.isLoading}
              noMargin
            />
            {form.hasLastErrors && (
              <>
                <Spacer size={0.5} />
                <GHText color="error">
                  Your pricing updates could not be submitted. Please correct
                  the errors above and try again.
                </GHText>
              </>
            )}
          </View>
          <Spacer size={0.75} />
        </PaddedArea>
      </FormWrap>
    </Expander>
  );
}
