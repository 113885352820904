// @flow

import type { Dispatch } from '../types';

import { Color, Dimen } from '@guesthug/core';
import { GHText, Spacer } from '@guesthug/core/components';
import { logError } from '@guesthug/core/util';
import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Pressable, View } from 'react-native';
import { useDispatch } from 'react-redux';

import { Button } from '../components';
import { useAuthUser } from '../hooks';

type Props = $ReadOnly<{
  error: Error | void,
  errorInfo?: Error,
  invalidate?: () => mixed,
  ...
}>;

export default function ScreenError({ error, errorInfo, invalidate }: Props) {
  const authUser = useAuthUser();
  const dispatch = useDispatch<Dispatch>();

  let shouldLog = false;
  let displayTitle = 'Something went wrong.';
  let displayDesc = 'An unexpected error occured. Please try again later.';

  if (error?.message.match(/(NetworkError|Cross-Origin Request Blocked)/i)) {
    displayTitle = 'Could not connect';
    displayDesc =
      'Could not reach the GuestHug servers.  Please make sure you are connected to the internet.';
  } else if (errorInfo) {
    // logError already called in AppErrorBoundary
    displayDesc =
      'Try refreshing the page, or signing out and signing in again.';
  } else if (error) {
    shouldLog = true;
  }

  React.useEffect(() => {
    if (shouldLog && error) logError(error, 'screenError');
  }, [shouldLog, error]);

  return (
    <View style={styles.container}>
      <GHText size="title" weight="light">
        {displayTitle}
      </GHText>
      <Spacer />
      <GHText color="faint">{displayDesc}</GHText>
      <Spacer />

      {invalidate && (
        <>
          <Button
            colorScheme="vibrantBlue"
            label="Try again"
            onPress={invalidate}
          />
          <Spacer />
        </>
      )}

      {authUser && (
        <Pressable
          onPress={() => {
            dispatch({ type: 'LOGOUT' });
          }}
        >
          {({ hovered }: any) => (
            <GHText color={hovered ? 'interactive' : 'grey'}>Logout</GHText>
          )}
        </Pressable>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: Dimen.spacing,
    height: '100%',
  },
  hover: {
    color: Color.vibrantBlue,
  },
});
