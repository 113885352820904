// @flow

import { Api } from '@guesthug/core';
import { iriToId } from '@guesthug/core/util';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { MidTitleLink, Stars, TitledBox } from '../../components';
import { Dimen } from '../../constants';

type Props = $ReadOnly<{|
  bookings: Array<Api.Booking.V_Analytics>,
|}>;

export default function BoxOverallRatings({ bookings }: Props) {
  const allProperties = bookings.map((b) => {
    const ratings = bookings
      .map((x) => {
        if (x.property.name === b.property.name) return x.ratingOverall;
      })
      .filter(Boolean);
    return {
      id: iriToId(b.property['@id']),
      name: b.property.name,
      averageRating: ratings.length
        ? ratings.reduce((acc, r) => acc + r) / ratings.length
        : 0,
    };
  });

  const propertyRatings = Array.from(
    new Set(allProperties.map((a) => a.id))
  ).map((id) => allProperties.find((a) => a.id === id));

  if (propertyRatings.length === 0) {
    return null;
  }
  return (
    <TitledBox title="Average Overall Listing Ratings" padded>
      {propertyRatings.map(
        (ratedProperty) =>
          ratedProperty && (
            <StyledRow key={ratedProperty.id}>
              <div>
                <MidTitleLink to={`/properties/${ratedProperty.id}`}>
                  {ratedProperty.name}
                </MidTitleLink>
              </div>
              <Stars count={ratedProperty.averageRating} />
            </StyledRow>
          )
      )}
    </TitledBox>
  );
}

const StyledRow = styled('div')`
  margin: ${rem(Dimen.spacing / 4)} 0;
`;
