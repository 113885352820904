// @flow

import type { FormErrors } from 'react-typed-form';

import baseValidate from 'validate.js';

import { values } from '../util';
import { BUSY } from './FieldUpload';

// NB: validate.js checks against the WHOLE string, so add a .* on the end
const calendarProviders = {
  Airbnb: /^https?:\/\/(www\.)?airbnb\.(com?\.)?[a-z]{2,3}\/calendar\/ical.*/i,
  'Booking.com':
    /^https?:\/\/admin.booking.com\/hotel\/hoteladmin\/ical\.html\?t=.*/i,
  HomeAway: /^https?:\/\/(www\.)?homeaway\.com\/icalendar\/[0-9a-f]+\.ics$/i,
  TripAdvisor:
    /^https?:\/\/rentals\.tripadvisor\.com\/calendar\/ical\/\d+\.ics.*/i,
};

baseValidate.validators.calendarUrl = function (value) {
  const match = values(calendarProviders).find((pattern) => {
    return baseValidate.validators.format(value, { pattern }) == null;
  });
  return match
    ? undefined
    : `^Calendar URL not recognised.  Must be from one of our currently supported portals: ${Object.keys(
        calendarProviders
      ).join(', ')}`;
};

baseValidate.validators.passwordFormat = function (value) {
  return baseValidate.validators.format(value, {
    pattern: /(.*([A-Z].*[0-9]|[0-9].*[A-Z]).*)/,
    message:
      '^Password must include at least one capital letter and one number',
  });
};

baseValidate.validators.passwordLength = function (value) {
  return baseValidate.validators.length(value, {
    minimum: 8,
    message: '^Password is too short (minimum is 8 characters)',
  });
};

baseValidate.validators.price = function (value) {
  if (value < 100) {
    return '^Price cannot be less than 1:00';
  }
};

/**
 * Equivalent of built in validator: `presence: { allowEmpty: false }` because
 * that's nearly always what this app wants.
 */
baseValidate.validators.strictPresence = function (
  value,
  userOptions,
  ...rest
) {
  const options = typeof userOptions === 'object' ? userOptions : {};
  if (!options.mustDefine && value === undefined) {
    return;
  }
  return baseValidate.validators.presence(
    value,
    // options might be `true`
    { allowEmpty: false, ...options },
    ...rest
  );
};

baseValidate.validators.time = function (value) {
  if (value && !value.match(/^\d\d?:\d\d$/)) {
    return '^Please use 24-hour format HH:MM (e.g. 12:30)';
  }
};

/**
 * Don't allow in progress uploads to be submitted.
 */
baseValidate.validators.upload = function (value) {
  if (value === BUSY) {
    return '^Image is still uploading';
  }
};

export default function createFormValidator<T: {}>(constraints: {
  ...
}): (values: T) => FormErrors<T> {
  return (v) => baseValidate(v, constraints) || {};
}
