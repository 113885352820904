// @flow

import { Color, Dimen } from '@guesthug/core';
import { GHText } from '@guesthug/core/components';
import { lighten } from 'polished';
import * as React from 'react';
import { Pressable, StyleSheet } from 'react-native';

type Props = $ReadOnly<{|
  label: string,
  colorScheme?: 'darkBlue' | 'faintBlue' | 'red' | 'vibrantBlue',
  onPress: () => mixed,
  disabled?: boolean,
|}>;

export default function Button({
  label,
  colorScheme = 'vibrantBlue',
  onPress,
  disabled,
}: Props) {
  return (
    <Pressable
      onPress={() => {
        onPress();
      }}
      disabled={disabled}
      style={({ hovered }: any) => [
        styles.button,
        disabled && styles.buttonDisabled,
        colorStyles[colorScheme],
        hovered && !disabled && hoveredColorStyles[colorScheme],
      ]}
    >
      <GHText
        color="invertNormal"
        weight="bold"
        numberOfLines={1}
        align="center"
      >
        {label}
      </GHText>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    paddingHorizontal: Dimen.spacing * 2,
    ...({
      transitionProperty: 'all',
      transitionDuration: '250ms',
    }: any),
  },
  buttonDisabled: {
    opacity: 0.5,
  },
});

const colorStyles = StyleSheet.create({
  darkBlue: { backgroundColor: Color.darkBlue },
  faintBlue: { backgroundColor: Color.faintBlue },
  red: { backgroundColor: Color.red },
  vibrantBlue: { backgroundColor: Color.vibrantBlue },
});

const hoveredColorStyles = StyleSheet.create({
  darkBlue: { backgroundColor: lighten(0.05, Color.darkBlue) },
  faintBlue: { backgroundColor: lighten(0.05, Color.faintBlue) },
  red: { backgroundColor: lighten(0.05, Color.red) },
  vibrantBlue: { backgroundColor: lighten(0.05, Color.vibrantBlue) },
});
