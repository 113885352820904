// @flow

import { rem, transparentize } from 'polished';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  to?: string,
  href?: string,
  image?: React.Node,
  caption: string,
  subCaption?: string | null,
  customLayer?: React.Node,
  hoverLabel: string,
  archived?: boolean,
|}>;

const ImageLink = ({
  to,
  href,
  image,
  caption,
  subCaption,
  customLayer,
  hoverLabel,
  archived,
}: Props) => (
  <StyledLink to={to} href={href} $archived={archived}>
    <AreaImage>{image || null}</AreaImage>
    <AreaCaption>
      <strong>{caption}</strong>
      <span>{subCaption}</span>
    </AreaCaption>
    {customLayer}
    <AreaMask>{hoverLabel}</AreaMask>
  </StyledLink>
);

export default ImageLink;

const AreaImage = styled('div')`
  flex: 1;
`;

const AreaCaption = styled('div')`
  height: ${rem(60)};
  padding: 0 ${rem(24)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: ${rem(12)};
  background-color: #e2e7ec;
  font-weight: 300;

  strong {
    display: block;
  }

  strong,
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const AreaMask = styled('div')`
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${transparentize(0.2, '#19252e')};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  transition: opacity 0.25s;
`;

const StyledLink = styled((props) =>
  React.createElement(props.to ? Link : 'a', props)
)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: #d7dee4;
  border-radius: 3px;
  ${(p) => p.$archived && 'opacity: 0.5'};

  &:hover ${AreaMask} {
    opacity: 1;
  }
`;
