// @flow

import { Api } from '@guesthug/core';
import { format, isToday, parseISO } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Color, Dimen } from '../../../constants';

type Props = $ReadOnly<{|
  message: Api.Message.BaseView,
  mySide: boolean,
|}>;

export default function Bubble({ message, mySide }: Props) {
  return (
    <StyledBubble mySide={mySide}>
      <div>{message.content}</div>
      <span>
        {format(
          parseISO(message.createdAt),
          isToday(parseISO(message.createdAt)) ? 'HH:mm' : 'd MMM HH:mm'
        )}
      </span>
    </StyledBubble>
  );
}

const RADIUS = 4;

const StyledBubble = styled('div')`
  margin: ${rem(Dimen.spacing / 4)} 0;
  padding: ${rem(Dimen.spacing / 2)};
  max-width: 60%;
  font-size: ${rem(14)};

  border-top-left-radius: ${RADIUS}px;
  border-top-right-radius: ${RADIUS}px;

  > span {
    color: ${Color.faintBlue};
    font-size: ${rem(11)};
  }

  ${(p) =>
    p.mySide
      ? `
    align-self: flex-end;
    background-color: ${Color.vibrantBlue};
    border-bottom-left-radius: ${RADIUS}px;

    > div { color: #fff }
    > span { color: #ffffff77; }
  `
      : `
    align-self: flex-start;
    background-color: #fff;
    border-bottom-right-radius: ${RADIUS}px;
  `};
`;
