// @flow

import { Style } from '@guesthug/core';
import { GHText, Line, PaddedArea, Spacer } from '@guesthug/core/components';
import * as React from 'react';
import { View } from 'react-native';

import { Switch } from '../../../components';

type Props = $ReadOnly<{|
  name: string,
  description?: string,
  required?: boolean,
  enabled?: boolean,
  setEnabled?: (boolean) => mixed,
  children?: React.Node,
|}>;

export default function PricingControl({
  name,
  description,
  required,
  enabled,
  setEnabled,
  children,
}: Props) {
  return (
    <>
      <PaddedArea horizontal>
        <Spacer size={0.75} />
        {enabled === undefined ? (
          <View style={[Style.row, Style.alignCenter]}>
            <GHText>{name}</GHText>
            {description && (
              <>
                <Spacer size={0.25} />
                <GHText color="grey">|</GHText>
                <Spacer size={0.25} />
                <GHText color="grey" size="small">
                  {description}
                </GHText>
              </>
            )}
            {required && (
              <>
                <Spacer fill />
                <GHText
                  color="interactive"
                  size="small"
                  weight="bold"
                  uppercase
                >
                  Required
                </GHText>
              </>
            )}
          </View>
        ) : (
          <View style={[Style.row, Style.alignCenter]}>
            <Switch value={enabled} onValueChange={setEnabled} />
            <Spacer />
            <GHText>{name}</GHText>
            <Spacer fill />
            <GHText
              size="small"
              weight={enabled ? 'bold' : 'normal'}
              color={enabled ? 'interactive' : 'faint'}
            >
              {enabled ? 'ENABLED' : 'DISABLED'}
            </GHText>
          </View>
        )}

        {enabled && (
          <>
            {description && (
              <View style={{ maxWidth: 640 }}>
                <Spacer size={0.5} />
                <GHText color="grey" size="small">
                  {description}
                </GHText>
              </View>
            )}
            <Spacer size={0.5} />
            {children}
          </>
        )}

        {enabled === undefined && (
          <>
            <Spacer size={0.5} />
            {children}
          </>
        )}

        <Spacer size={0.75} />
      </PaddedArea>
      <Line h />
    </>
  );
}
