// @flow

import type { RouterHistory } from 'react-router-dom';

import { rem } from 'polished';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Dimen } from '../../constants';

type Props = $ReadOnly<{|
  history: RouterHistory,
|}>;

type State = {|
  query: string,
|};

class SearchBar extends React.PureComponent<Props, State> {
  state = {
    query: '',
  };

  render() {
    const { history } = this.props;
    const { query } = this.state;
    return (
      <StyledContainer>
        <StyledIcon src={require('../../../assets/img/ui/search.svg')} />
        <StyledInput
          placeholder="Search"
          value={query}
          onChange={(e) => this.setState({ query: e.target.value })}
          onKeyUp={(e) => {
            if (e.key === 'Enter' && query.length > 0) {
              history.push(`/search/${encodeURIComponent(query)}`);
            }
          }}
        />
      </StyledContainer>
    );
  }
}

export default withRouter(SearchBar);

const StyledContainer = styled('div')`
  flex: 1;
  padding: 0 ${rem(Dimen.spacing)};
  display: flex;
  align-items: center;
`;

const StyledIcon = styled('img')`
  width: ${rem(15)};
  margin-right: ${rem(Dimen.spacing / 4)};
`;

const StyledInput = styled('input')`
  flex: 1;
  box-sizing: border-box;
  border: 0;
  height: ${rem(34)};
`;
