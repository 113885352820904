// @flow

import { Api } from '@guesthug/core';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Expander, SimpleTabs } from '../../../components';
import { Dimen } from '../../../constants';
import CheckInGuide from './CheckInGuide';
import CheckOutGuide from './CheckOutGuide';

type Props = $ReadOnly<{|
  property: Api.Property.V_Full,
  complete: boolean,
  invalidate: () => void,
|}>;

export default function SectionCheckInOutGuide({
  property,
  complete,
  invalidate,
}: Props) {
  const [mode, setMode] = React.useState<'in' | 'out'>('in');

  const Component = mode === 'out' ? CheckOutGuide : CheckInGuide;

  return (
    <Expander title="Check-In/Out Guides" complete={complete} noPadding>
      <SimpleTabs
        tabs={[
          {
            label: 'Check-In',
            onClick: () => setMode('in'),
            selected: mode === 'in',
          },
          {
            label: 'Check-Out',
            onClick: () => setMode('out'),
            selected: mode === 'out',
          },
        ]}
      />
      <StyledAreaMain>
        <Component property={property} invalidate={invalidate} />
      </StyledAreaMain>
    </Expander>
  );
}

const StyledAreaMain = styled('div')`
  padding: ${rem(Dimen.spacing)};
`;
