// @flow

import type { $Optional } from 'react-typed-form';
import type { Dispatch } from '../../../types';

import {
  Api,
  Color,
  Model,
  useApiCallable,
  useApiFormSubmit,
} from '@guesthug/core';
import { GHText, Line, Spacer } from '@guesthug/core/components';
import * as React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { useForm, useFormGroup, useFormGroupItem } from 'react-typed-form';

import { flashShow } from '../../../actions/flash';
import { Expander } from '../../../components';
import {
  FieldInput,
  FieldNumber,
  FieldSelect,
  FieldSwitch,
  FormWrap,
  SubmitButton,
} from '../../../form';
import { capitalize } from '../../../util';
import BedroomForm from './BedroomForm';

type Props = $ReadOnly<{|
  property: Api.Property.V_Full,
  complete: boolean,
  invalidate: () => void,
|}>;

export default function SectionDetails({
  property,
  complete,
  invalidate,
}: Props) {
  const callApi = useApiCallable();
  const dispatch = useDispatch<Dispatch>();

  const form = useForm<any>({
    defaultValues: { ...null },
    pristineValues: property,
    onSubmit: useApiFormSubmit('PUT', `/properties/${property.id}`),
  });

  const formAmenities = useForm<$Optional<Api.PropertyAmenities.W>>({
    defaultValues: property.amenities ? {} : { property: property['@id'] },
    pristineValues: property.amenities,
    onSubmit: useApiFormSubmit(
      property.amenities ? 'PUT' : 'POST',
      property.amenities
        ? `/property-amenities/${property.amenities.id}`
        : '/property-amenities'
    ),
  });

  const formGroup = useFormGroup({
    initialKeys: [
      'property',
      'property-amenities',
      ...property.bedrooms.map((b) => b['@id']),
    ],
  });

  useFormGroupItem({
    formGroup,
    key: 'property',
    form,
  });

  useFormGroupItem({
    formGroup,
    key: 'property-amenities',
    form: formAmenities,
  });

  function amenityName(field: string): string {
    if (field === 'bbq') return 'BBQ';
    if (field === 'tv') return 'TV';
    return capitalize(
      field.replace(
        /([a-z])([A-Z])/g,
        (_, ...matches) => `${matches[0]} ${matches[1].toLowerCase()}`
      )
    );
  }

  return (
    <Expander title="Property Details" complete={complete}>
      <FormWrap
        handleSubmit={() =>
          formGroup.submit({
            onFinish: async ({ hasErrors }) => {
              formGroup.setLoading(true);
              const toDelete = property.bedrooms
                .map((b) => b['@id'])
                .filter((iri) => !formGroup.hasKeyOrAlias(iri));
              for (const iri of toDelete) {
                await callApi(iri, { method: 'DELETE' });
              }
              formGroup.setLoading(false);

              if (!hasErrors) {
                dispatch(flashShow('Changes saved'));
              }
              invalidate();

              callApi(`/properties/${property.id}/content/push`, {
                method: 'POST',
                jsonBody: {},
              });
            },
          })
        }
      >
        <FieldInput
          field={form.getField('name')}
          label="Public listing name"
          hint="Maximum 50 characters for Airbnb"
          required
          noMargin
        />
        <Spacer />

        <FieldInput
          field={form.getField('description')}
          label="Public listing description max word count of 500"
          multiline
          required
          noMargin
        />
        <Spacer />

        <FieldSelect
          field={form.getField('type')}
          choices={Model.Property.PROPERTY_TYPES.map((type) => ({
            value: type,
            label: capitalize(type),
          }))}
          required
        />
        <Spacer />

        <FieldNumber
          field={form.getField('floor')}
          label="Floor number"
          hint="Enter 1 for a whole house, or negative numbers for below ground"
          required
          noMargin
        />
        <Spacer />
        <FieldNumber
          field={form.getField('space')}
          label="Floor area (square meters)"
          required
          noMargin
        />
        <Spacer />
        <Line h />
        <Spacer />
        <GHText size="large" weight="light">
          Bedrooms
          <GHText size="large" weight="bold" style={{ color: Color.red }}>
            {' *'}
          </GHText>
        </GHText>
        <Spacer />

        {formGroup.keys
          .filter((key) => key !== 'property' && key !== 'property-amenities')
          .map((key, i) => (
            <BedroomForm
              key={key}
              propertyIri={property['@id']}
              existing={property.bedrooms.find((b) => b['@id'] === key)}
              formGroup={formGroup}
              formGroupKey={key}
              number={i + 1}
            />
          ))}

        <Spacer size={0.25} />
        <Pressable
          onPress={() =>
            formGroup.addKey(`create-${Math.floor(Math.random() * 10000)}`)
          }
        >
          <GHText color="interactive">+ Add another Bedroom</GHText>
        </Pressable>

        <Spacer />
        <Line h />
        <Spacer />
        <GHText size="large" weight="light">
          Amenities
        </GHText>
        <Spacer />

        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          {Model.PropertyAmenities.AMENITIES.map((amenity) => (
            <View key={amenity} style={styles.amenity}>
              <FieldSwitch field={formAmenities.getField(amenity)} />
              <Spacer size={0.5} />
              <GHText>{amenityName(amenity)}</GHText>
            </View>
          ))}
        </View>

        <Spacer />
        <Line h />
        <SubmitButton
          normalLabel={Model.Property.getSubmitButtonLabel(property)}
          isLoading={formGroup.isLoading}
        />
      </FormWrap>
    </Expander>
  );
}

const styles = StyleSheet.create({
  amenity: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 200,
    marginVertical: 6,
  },
});
