// @flow

import { Api } from '@guesthug/core';
import * as React from 'react';
import styled from 'styled-components';

import {
  ContentLoading,
  CoverImage,
  ImageTile,
  ImageTileList,
  ScreenError,
} from '../../components';
import { useAuthUser } from '../../hooks';
import UnsupportedCurrencyMessage from './UnsupportedCurrencyMessage';

export default function ServicesHostAssistPage() {
  const { id } = useAuthUser();

  const api = Api.User.useReadItem(id);

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ContentLoading />;

  const user = api.data;

  if (user.currency !== 'gbp') {
    return <UnsupportedCurrencyMessage />;
  }

  return (
    <ImageTileList>
      <ImageTile
        href="https://www.ihateironing.com/services/guesthug-laundry-services/"
        image={
          <StyledImage
            src={require('../../../assets/img/host-services/laundry.jpg')}
          />
        }
        caption="Laundry"
        hoverLabel="Visit Service"
      />
      <ImageTile
        href="https://www.fantasticservices.com/guesthug/"
        image={
          <StyledImage
            src={require('../../../assets/img/host-services/cleaning.jpg')}
          />
        }
        caption="Cleaning"
        hoverLabel="Visit Service"
      />
      {/* <ImageTile
        href="https://guesthug.bizzby.io"
        image={
          <StyledImage
            src={require('../../../assets/img/host-services/maintenance.jpg')}
          />
        }
        caption="Maintenance"
        hoverLabel="Visit Service"
      /> */}
      <ImageTile
        href="https://www.guardhog.com/guesthug-insurance-services/"
        image={
          <StyledImage
            src={require('../../../assets/img/host-services/insurance.jpg')}
          />
        }
        caption="Insurance"
        hoverLabel="Visit Service"
      />
    </ImageTileList>
  );
}

const StyledImage = styled(CoverImage)`
  height: 100%;
`;
