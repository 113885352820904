// @flow

import type { Purchase } from './types';

import {
  addMonths,
  format,
  isBefore,
  isSameMonth,
  parseISO,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { darken, rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import {
  VictoryAxis,
  VictoryChart,
  VictoryClipContainer,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
} from 'victory';

import { Dimen } from '../../constants';
import { formatPrice } from '../../util';

type Props = $ReadOnly<{|
  currency: string,
  purchases: Array<Purchase>,
|}>;

export default function SectionChart({ purchases, currency }: Props) {
  const totals = [];

  let current = startOfMonth(subMonths(new Date(), 11));
  while (isBefore(current, new Date())) {
    totals.push({ month: format(current, 'yyyy-MM'), sales: 0 });
    current = addMonths(current, 1);
  }

  purchases.forEach((purchase) => {
    const { createdAt } = purchase;
    const item = totals.find((d) =>
      isSameMonth(parseISO(d.month), parseISO(createdAt))
    );
    if (!item) return;

    purchase.lineItems.forEach((lineItem) => {
      item.sales += lineItem.price * lineItem.quantity;
    });
  });

  const axisStyles = {
    axis: { strokeWidth: 0 },
    ticks: { size: 0 },
    tickLabels: {
      fontFamily: 'inherit',
      fontSize: 14,
      fontWeight: 300,
    },
  };

  return (
    <div>
      <StyledTitle>Annual Sales Overview</StyledTitle>
      <VictoryChart
        theme={VictoryTheme.material}
        width={570}
        height={260}
        padding={{ left: 45, right: 15, top: 10, bottom: 25 }}
      >
        <VictoryAxis
          tickFormat={(x) => format(parseISO(`${x}-01`), 'MMM').toUpperCase()}
          style={axisStyles}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={(d) => formatPrice(d, currency, { round: true })}
          crossAxis={false}
          style={axisStyles}
          tickLabelComponent={<VictoryLabel textAnchor="start" x={0} />}
        />
        <VictoryLine
          data={totals}
          x="month"
          y="sales"
          style={{
            data: { stroke: '#38c59b', strokeWidth: 3 },
            parent: { border: '1px solid #ccc' },
          }}
          groupComponent={<VictoryClipContainer clipPadding={{ bottom: 5 }} />}
        />
        <VictoryScatter
          data={totals}
          x="month"
          y="sales"
          size={6.5}
          style={{
            data: {
              fill: '#38c59b',
              stroke: '#fff',
              strokeWidth: 3,
            },
          }}
          labels={({ datum }) => formatPrice(datum.sales, currency)}
          labelComponent={
            <VictoryTooltip
              flyoutStyle={{
                fill: darken(0.05, '#38c59b'),
                strokeWidth: 0,
              }}
              style={{
                fill: '#fff',
              }}
            />
          }
        />
      </VictoryChart>
    </div>
  );
}

const StyledTitle = styled('strong')`
  display: block;
  margin-bottom: ${rem(Dimen.spacing / 2)};
  font-weight: normal;
  font-size: ${rem(18)};
`;
