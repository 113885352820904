// @flow

import { Api } from '@guesthug/core';
import { isSameMonth, parseISO, subMonths } from 'date-fns';
import * as React from 'react';

import { Figure } from '../../components';
import { calcChangePercent } from '../../util';
import DashboardBox from './DashboardBox';

type Props = $ReadOnly<{|
  bookings: Array<Api.Booking.BaseView>,
|}>;

export default function BoxConfirmedBookings({ bookings }: Props) {
  let numThisMonth = 0;
  let numLastMonth = 0;

  const thisMonth = new Date();
  const lastMonth = subMonths(thisMonth, 1);

  bookings.forEach(({ mainGuest, startDate }) => {
    if (mainGuest && isSameMonth(parseISO(startDate), thisMonth)) {
      numThisMonth += 1;
    }
    if (mainGuest && isSameMonth(parseISO(startDate), lastMonth)) {
      numLastMonth += 1;
    }
  });

  const changePercent = calcChangePercent(numThisMonth, numLastMonth);

  return (
    <DashboardBox title="Bookings Accepted in GuestHug This Month" padded>
      <Figure value={numThisMonth} changePercent={changePercent} />
    </DashboardBox>
  );
}
