// @flow

import { Style } from '@guesthug/core';
import { GHText, Spacer } from '@guesthug/core/components';
import { darken, rem } from 'polished';
import * as React from 'react';
import { View } from 'react-native';
import styled from 'styled-components';

import { Color, Dimen } from '../constants';
import ErrorList from './ErrorList';

export type Props = $ReadOnly<{|
  className?: string,
  errors?: string[],
  external?: boolean,
  hint?: string,
  label?: string | null,
  multiline?: boolean,
  onClick?: () => mixed,
  secureTextEntry?: boolean,
  showSelectArrow?: boolean,
  inputWidth?: number,
  afterInput?: string,
  required?: boolean,
  noMargin?: boolean,

  // Pass through
  defaultValue?: string,
  disabled?: boolean,
  maxLength?: number,
  onBlur?: (SyntheticInputEvent<EventTarget>) => mixed,
  onChange?: (SyntheticInputEvent<EventTarget>) => void,
  onFocus?: (SyntheticInputEvent<EventTarget>) => mixed,
  value?: string,
|}>;

const TextInput = ({
  className,
  errors,
  external,
  hint,
  label,
  multiline,
  onClick,
  secureTextEntry,
  showSelectArrow,
  inputWidth,
  afterInput,
  value,
  noMargin,
  required,
  ...rest
}: Props) => {
  const isButton = !!onClick;
  return (
    <StyledContainer className={className} $noMargin={noMargin}>
      {label && (
        <StyledLabel $noMargin={noMargin}>
          {label}
          {required && (
            <span style={{ color: '#ee4640', fontWeight: 'bold' }}> *</span>
          )}
        </StyledLabel>
      )}
      <View style={[Style.row, Style.alignCenter]}>
        <StyledInput
          isButton={isButton}
          value={isButton ? undefined : value}
          external={external}
          multiline={multiline}
          type={secureTextEntry ? 'password' : 'text'}
          onClick={onClick}
          $width={inputWidth}
          {...rest}
        >
          {isButton ? value : undefined}
        </StyledInput>
        {afterInput && (
          <>
            <Spacer size={0.5} />
            <GHText color="faint">{afterInput}</GHText>
          </>
        )}
      </View>
      {hint && <StyledHint>{hint}</StyledHint>}
      <ErrorList errors={errors} />
      {showSelectArrow && (
        <StyledSelectArrow src={require('../../assets/img/ui/arrow.svg')} />
      )}
    </StyledContainer>
  );
};

export default TextInput;

const StyledContainer = styled((props) =>
  React.createElement(props.onClick ? 'a' : 'div', props)
)`
  margin-bottom: ${(p) => (p.$noMargin ? 0 : rem(Dimen.spacing / 2))};
  position: relative;
`;

const StyledLabel = styled('label')`
  display: block;
  font-size: ${rem(14)};
  font-weight: 300;
  padding: ${(p) => (p.$noMargin ? 0 : '10px')} 20px 10px;
`;

const elType = (multiline, isButton): string => {
  if (isButton) return 'a';
  if (multiline) return 'textarea';
  return 'input';
};

const StyledInput = styled(
  ({
    isButton,
    multiline,
    /* eslint-disable no-unused-vars */
    lastErrorList,
    showSelectArrow,
    external,
    /* eslint-enable no-unused-vars */
    ...rest
  }) => React.createElement(elType(multiline, isButton), rest)
)`
  box-sizing: border-box;
  display: block;
  ${(p) =>
    p.$width
      ? `
    width: ${p.$width}px;
    min-width: ${p.$width}px;
  `
      : `
    width: 100%;
    min-width: 100%;
  `}
  max-width: 100%;
  min-height: ${(p) => rem(26 + (p.external ? 10 : 20) * 2)};
  ${(p) => p.multiline && `height: ${rem(130)}`};
  background-color: ${(p) => (p.external ? '#fff' : '#edf1f4')};
  border-radius: 3px;
  font-size: ${rem(20)};
  font-weight: 300;
  padding: ${(p) => (p.external ? `${rem(10)} ${rem(20)}` : rem(20))};
  transition: background-color 0.25s;
  border: ${(p) => (p.external ? '1px #eeeeee solid' : '0')};

  ${(p) =>
    p.onClick &&
    `
    &:hover {
      background-color: ${darken(0.03, '#edf1f4')};
    }
  `};

  &:focus {
    background-color: ${darken(0.03, '#edf1f4')};
  }

  ${(p) =>
    !p.onClick &&
    `
    &[disabled] {
      color: #999;
    }
  `};
`;

const StyledHint = styled('div')`
  margin-top: ${rem(Dimen.spacing / 2)};
  color: ${Color.faintBlue};
  font-size: ${rem(14)};
  font-weight: 300;
  padding: 0 ${rem(20)};
`;

const StyledSelectArrow = styled('img')`
  position: absolute;
  right: ${rem(20)};
  top: ${rem(57)};
  width: ${rem(20)};
  pointer-events: none;
`;
