// @flow

import type { CalendarApiBooking } from '@guesthug/core';

import { Dimen, useCalendarData } from '@guesthug/core';
import { GHText } from '@guesthug/core/components';
import {
  addDays,
  differenceInDays,
  format,
  formatISO,
  getYear,
  isBefore,
  parseISO,
  subDays,
} from 'date-fns';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { StyleSheet, View, useWindowDimensions } from 'react-native';

import { ContentLoading, PressableLink, Spacer } from '../../../components';
import { Color } from '../../../constants';
import {
  ChevronButton,
  LinkedBookingBar,
  MultiCalendarCell,
  MultiCalendarMonthPicker,
} from '../components';

const COLUMN_WIDTH = 80;
const NAME_BOX_WIDTH = 240;
const SHADOW_GAP_SIZE = 10;

export default function MultiCalendarScreen() {
  const { width: windowWidth } = useWindowDimensions();
  const numVisibleColumns = Math.max(
    7,
    Math.floor(
      (windowWidth -
        Dimen.hostWebLayoutPanelWidth -
        NAME_BOX_WIDTH -
        Dimen.spacing * 2 -
        SHADOW_GAP_SIZE) /
        COLUMN_WIDTH
    )
  );

  const originDate = parseISO('2021-01-01');
  const [currentDate, setCurrentDate] = React.useState<string>(
    formatISO(new Date(), { representation: 'date' })
  );
  const [monthPickerFocusedYear, setMonthPickerFocusedYear] =
    React.useState<number>(getYear(new Date()));

  const [renderStartDate, setRenderStartDate] = React.useState<string>(
    formatISO(subDays(new Date(), 30), { representation: 'date' })
  );
  const [renderEndDate, setRenderEndDate] = React.useState<string>(
    formatISO(addDays(new Date(), 50), { representation: 'date' })
  );

  React.useEffect(() => {
    const MIN_BUFFER = numVisibleColumns;
    const IDEAL_BUFFER = numVisibleColumns * 3;
    const current = parseISO(currentDate);
    const renderStart = parseISO(renderStartDate);
    const renderEnd = parseISO(renderEndDate);
    let newStart, newEnd;
    if (differenceInDays(subDays(current, MIN_BUFFER), renderStart) < 0) {
      newStart = subDays(current, IDEAL_BUFFER);
      newEnd = addDays(current, IDEAL_BUFFER + numVisibleColumns);
    }
    if (
      differenceInDays(
        renderEnd,
        addDays(current, numVisibleColumns + MIN_BUFFER)
      ) < 0
    ) {
      newStart = subDays(current, IDEAL_BUFFER);
      newEnd = addDays(current, numVisibleColumns + IDEAL_BUFFER);
    }
    newStart &&
      setRenderStartDate(formatISO(newStart, { representation: 'date' }));
    newEnd &&
      setRenderEndDate(
        formatISO(newEnd, {
          representation: 'date',
        })
      );
  }, [currentDate, numVisibleColumns]);

  const calendarData = useCalendarData({
    start: currentDate,
    end: formatISO(addDays(parseISO(currentDate), numVisibleColumns - 1)),
  });

  const propertyCalendars = React.useMemo(
    () =>
      calendarData
        ? [...calendarData.propertyCalendars].sort((a, b) =>
            a.name.localeCompare(b.name)
          )
        : null,
    [calendarData]
  );

  const visibleData = React.useMemo(() => {
    if (!propertyCalendars) return null;
    let current = parseISO(renderStartDate);
    const end = parseISO(renderEndDate);
    const result = [];
    while (isBefore(current, end)) {
      const dateString = formatISO(current, { representation: 'date' });
      result.push({
        date: dateString,
        properties: propertyCalendars.map((propertyCalendar) => ({
          id: propertyCalendar.propertyId,
          rate: propertyCalendar.dates[dateString]?.rate ?? null,
          blocked: propertyCalendar.dates[dateString]?.blocked ?? null,
        })),
      });
      current = addDays(current, 1);
    }
    return result;
  }, [propertyCalendars, renderStartDate, renderEndDate]);

  if (!propertyCalendars || !visibleData) return <ContentLoading />;

  const canvasOffset =
    -differenceInDays(parseISO(currentDate), originDate) * 80;
  function offsetForDate(date: string): number {
    return differenceInDays(parseISO(date), originDate) * 80;
  }

  function offsetForProperty(propertyId: string): number {
    const index = propertyCalendars.findIndex(
      (p) => p.propertyId === propertyId
    );
    if (index === -1) throw new Error(`Unknown property ID: ${propertyId}`);
    return index * 100 + 70;
  }

  const bookings: Array<{ propertyId: string, ...CalendarApiBooking }> = [];
  for (const propertyCalendar of propertyCalendars) {
    for (const booking of propertyCalendar.bookings) {
      if (!booking.deletedAt) {
        bookings.push({ propertyId: propertyCalendar.propertyId, ...booking });
      }
    }
  }

  // Hide rates on dates which have bookings
  const hideRateDates = Object.fromEntries(
    propertyCalendars.map((propertyCalendar) => [
      propertyCalendar.propertyId,
      [],
    ])
  );
  for (const booking of bookings) {
    let d = parseISO(booking.startDate);
    while (isBefore(d, parseISO(booking.endDate))) {
      hideRateDates[booking.propertyId].push(
        formatISO(d, { representation: 'date' })
      );
      d = addDays(d, 1);
    }
  }

  return (
    <>
      <Helmet>
        <title>Multi-Calendar</title>
      </Helmet>
      <View
        style={[
          styles.monthPickerWrap,
          { width: numVisibleColumns * COLUMN_WIDTH },
        ]}
      >
        <MultiCalendarMonthPicker
          visibleStartDate={formatISO(parseISO(currentDate), {
            representation: 'date',
          })}
          visibleEndDate={formatISO(
            addDays(parseISO(currentDate), numVisibleColumns),
            { representation: 'date' }
          )}
          focusedYear={monthPickerFocusedYear}
          setFocusedYear={setMonthPickerFocusedYear}
          onPressDate={(date) =>
            setCurrentDate(formatISO(date, { representation: 'date' }))
          }
        />
      </View>
      <Spacer />
      <View>
        <View style={styles.namesPanel}>
          {propertyCalendars.map((propertyCalendar) => {
            const pricingMissing =
              Object.values(propertyCalendar.dates)[0].rate === 0;
            const bookingSettingsMissing =
              Object.values(propertyCalendar.dates)[0].minNights === 0;
            return (
              <PressableLink
                key={propertyCalendar.propertyId}
                to={
                  pricingMissing || bookingSettingsMissing
                    ? `/properties/${propertyCalendar.propertyId}`
                    : `/calendar/${propertyCalendar.propertyId}`
                }
                style={({ hovered }) => [
                  styles.nameBox,
                  hovered && styles.nameBoxHovered,
                ]}
              >
                <GHText size="larger" numberOfLines={1} selectable={false}>
                  {propertyCalendar.name}
                </GHText>
                {(pricingMissing || bookingSettingsMissing) && (
                  <GHText size="small" color="faint">
                    {pricingMissing
                      ? 'Pricing missing'
                      : 'Booking settings missing'}
                  </GHText>
                )}
              </PressableLink>
            );
          })}
        </View>
        <View
          style={[
            styles.calendarHideOverflow,
            {
              height: propertyCalendars.length * 100 + 70,
              width: 80 * numVisibleColumns,
            },
          ]}
        >
          <View
            style={[
              styles.canvas,
              { transform: [{ translateX: canvasOffset }] },
            ]}
          >
            {visibleData.map((item) => (
              <View
                key={item.date}
                style={[styles.dateColumn, { left: offsetForDate(item.date) }]}
              >
                <View style={styles.dateHeading}>
                  <GHText size="small" uppercase selectable={false}>
                    {format(parseISO(item.date), 'iiiiii')}
                  </GHText>
                  <Spacer size={0.2} />
                  <GHText color="grey" size="small" selectable={false}>
                    {format(parseISO(item.date), 'd')}
                  </GHText>
                </View>
                {item.properties.map((itemProperty) => (
                  <MultiCalendarCell
                    key={itemProperty.id}
                    date={item.date}
                    rate={
                      itemProperty.rate === 0 ||
                      hideRateDates[itemProperty.id].includes(item.date)
                        ? null
                        : itemProperty.rate
                    }
                    blocked={itemProperty.blocked}
                    loading={!itemProperty.rate}
                  />
                ))}
              </View>
            ))}

            {bookings.map((booking) => (
              <LinkedBookingBar
                key={booking.id}
                propertyId={booking.propertyId}
                bookingId={booking.id}
                guestName={
                  booking.mainGuest
                    ? `${booking.mainGuest.firstName} ${booking.mainGuest.lastName}`
                    : booking.providerGuestName
                }
                providerSlug={booking.calendar?.provider?.slug ?? null}
                top={offsetForProperty(booking.propertyId) + 15}
                left={offsetForDate(booking.startDate) + COLUMN_WIDTH / 2 - 4}
                width={
                  offsetForDate(booking.endDate) -
                  offsetForDate(booking.startDate) -
                  8
                }
              />
            ))}
          </View>

          <ChevronButton
            dir="left"
            onPress={() => {
              const newDate = subDays(parseISO(currentDate), numVisibleColumns);
              setCurrentDate(formatISO(newDate, { representation: 'date' }));
              setMonthPickerFocusedYear(getYear(newDate));
            }}
            style={{ position: 'absolute', top: 13, left: 0 }}
          />
          <ChevronButton
            dir="right"
            onPress={() => {
              const newDate = addDays(parseISO(currentDate), numVisibleColumns);
              setCurrentDate(formatISO(newDate, { representation: 'date' }));
              setMonthPickerFocusedYear(getYear(newDate));
            }}
            style={{ position: 'absolute', top: 13, right: 0 }}
          />
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  monthPickerWrap: {
    marginLeft: 250,
  },
  namesPanel: {
    position: 'absolute',
    top: 60,
    left: 0,
    width: 250,
    // Room for shadow on every side except right
    paddingTop: SHADOW_GAP_SIZE,
    paddingLeft: SHADOW_GAP_SIZE,
    overflow: 'hidden',
  },
  nameBox: {
    width: NAME_BOX_WIDTH,
    height: 80,
    marginBottom: 20,
    justifyContent: 'center',
    paddingHorizontal: 20,
    backgroundColor: '#fff',
    borderRightWidth: 1,
    borderRightColor: '#e7e7e7',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    shadowColor: '#000000',
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 5,
    ...({
      transitionProperty: 'background-color',
      transitionDuration: '250ms',
    }: any),
  },
  nameBoxHovered: {
    backgroundColor: `${Color.vibrantBlue}22`,
  },
  calendarHideOverflow: {
    marginLeft: 250,
    overflow: 'hidden',
  },
  canvas: ({
    transitionProperty: 'transform',
    transitionDuration: '250ms',
  }: any),
  dateColumn: {
    width: 80,
    position: 'absolute',
    top: 0,
  },
  dateHeading: {
    height: 60,
    paddingTop: 20,
    alignItems: 'center',
  },
});
