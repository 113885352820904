// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  value: string | number,
  changePercent?: number,
  yellow?: boolean,
|}>;

const Figure = ({ value, changePercent, yellow }: Props) => (
  <StyledContainer yellow={yellow}>
    <strong>{value}</strong>
    {changePercent != null && (
      <StyledChangePercent
        positive={changePercent > 0}
        negative={changePercent < 0}
      >
        {changePercent > 0 && (
          <img src={require('../../assets/img/ui/change_up.svg')} />
        )}
        {changePercent < 0 && (
          <img src={require('../../assets/img/ui/change_down.svg')} />
        )}
        {Math.abs(changePercent).toFixed(0)}%
      </StyledChangePercent>
    )}
  </StyledContainer>
);

export default Figure;

const StyledContainer = styled('div')`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  ${(p) => p.yellow && 'color: #f9d181'};

  strong {
    font-size: ${rem(45)};
    font-weight: 400;
    line-height: 1.1;
  }
`;

const StyledChangePercent = styled('span')`
  font-size: ${rem(20)};
  font-weight: 400;
  color: #999;
  display: flex;
  align-items: center;

  ${(p) => p.positive && 'color: #38c59b'};
  ${(p) => p.negative && 'color: #ee4640'};

  img {
    height: ${rem(15)};
    margin-right: ${rem(6)};
  }
`;
