// @flow

import type { Iri } from '../../types';

import { Api } from '@guesthug/core';
import { iriToId } from '@guesthug/core/util';
import { format, parseISO } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { ContentLoading, Expander, ScreenError } from '../../components';
import * as config from '../../config';
import { Color } from '../../constants';
import { useAuthUser } from '../../hooks';
import { planForSku } from '../../plans';
import { capitalize } from '../../util';

type Props = $ReadOnly<{|
  iri: Iri,
|}>;

function readablePlatform(platform: string) {
  return (
    {
      manual: 'GuestHug Team',
      bookingcom: 'Booking.com',
      ios: 'iOS',
    }[platform] || capitalize(platform)
  );
}

export default function SectionSubscriptionActive({ iri }: Props) {
  const { jwt } = useAuthUser();
  const api = Api.Subscription.useReadItem(iriToId(iri));

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ContentLoading />;

  const subscription = api.data;

  const plan = planForSku(subscription.planIdentifier);

  return (
    <Expander title="Subscription" expandOnUrlHash="subscription">
      <StyledSubscription>
        <div>{plan?.name || 'Unknown'}</div>
        <div>
          <span>
            Subscribed{' '}
            {subscription.platform &&
              `via ${readablePlatform(subscription.platform)}`}{' '}
            on {format(parseISO(subscription.startedAt), 'd MMMM y')}
          </span>
        </div>
        {subscription.platform === 'stripe' && (
          <a
            href={`${config.SERVER_ROOT}/stripe/create-portal-session?bearer=${jwt}`}
          >
            Manage my subscription
          </a>
        )}
      </StyledSubscription>
    </Expander>
  );
}

const StyledSubscription = styled.div`
  span {
    color: ${Color.faintBlue};
    font-size: ${rem(14)};
  }

  a {
    display: block;
    margin-top: 15px;
    color: ${Color.vibrantBlue};
    font-size: ${rem(14)};
  }
`;
