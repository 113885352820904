// @flow

import { ellipsis, rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Color } from '../constants';

// eslint-disable-next-line no-unused-vars
export default styled(({ singleLine, ...props }) =>
  React.createElement(props.to ? Link : 'span', props)
)`
  font-size: ${rem(20)};
  font-weight: 400;
  transition: color 0.25s;
  display: block;

  &:hover {
    color: ${Color.vibrantBlue};
  }

  ${(p) => p.singleLine && ellipsis()};
`;
