// @flow

import type { Action } from '../types';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { type Reducer, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import { APP_ENV } from '../config';
import {
  type MessagingLastReadState,
  messagingLastReadReducer,
} from '../pods/messaging';
import auth, { type AuthState } from './auth';
import flash, { type FlashState } from './flash';
import options, { type OptionsState } from './options';
import versions, { type VersionsState } from './versions';

export type RootState = $ReadOnly<{|
  auth: AuthState,
  flash: FlashState,
  messagingLastRead: MessagingLastReadState,
  options: OptionsState,
  versions: VersionsState,
|}>;

// eslint-disable-next-line no-console
console.log(`Persisting GH reducers for ${APP_ENV}`);
const persistConfig = {
  debug: APP_ENV === 'dev',
  keyPrefix: `gh-${APP_ENV}-`,
  storage: AsyncStorage,
};

const reducers: Reducer<RootState, Action> = combineReducers({
  /* eslint-disable flowtype/no-weak-types */
  auth: (persistReducer({ key: 'auth', ...persistConfig }, auth): any),
  flash,
  messagingLastRead: (persistReducer(
    { key: 'messagingLastRead', ...persistConfig },
    messagingLastReadReducer
  ): any),
  options: (persistReducer({ key: 'options', ...persistConfig }, options): any),
  versions: (persistReducer(
    { key: 'versions', ...persistConfig },
    versions
  ): any),
  /* eslint-enable flowtype/no-weak-types */
});

export default reducers;
