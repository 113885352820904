// @flow

import type { Dispatch } from '../../types';

import { rem } from 'polished';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { LayoutBase, Spacer } from '../../components';
import { Color, Dimen } from '../../constants';
import { useAuthUser } from '../../hooks';
import PlanBox from './PlanBox';

const plans = [
  {
    name: 'Starter',
    price: null,
    color: '#fed181',
    description:
      'For hosts wanting to boost guest experience and earn more revenue from every booking',
    features: [
      'iCal powered multi-calendar',
      'Management App for Hosts',
      'Guest WebApp',
      'Integrated upsales platform',
      'Host services marketplace',
      'Centralised guest management',
    ],
    available: true,
  },
  {
    name: 'Premium',
    price: 499,
    color: '#8fd0b8',
    description:
      'For growth stage users wanting to sync pricing & availability across multiple channels',
    features: [
      'API channel manager',
      'Pricing & availability sync',
      'All major channels supported',
      'Manage from App & desktop',
      'Free onboarding support',
      'Import direct bookings',
    ],
    available: true,
  },
  {
    name: 'Professional',
    price: 1499,
    color: '#24a9e1',
    description: 'New features coming soon',
    features: [
      'In-app Guest ID verification',
      'Owner revenue portal',
      'Support team access',
    ],
    available: false,
  },
  {
    name: 'Business',
    price: 1999,
    color: '#ee4640',
    description: 'New features coming soon',
    features: [
      'Keyless intercom integrations',
      'Smartphone property access',
      'Fully automated 24/7 check-ins',
    ],
    available: false,
  },
];

export default function SelectPlanPage() {
  const { email } = useAuthUser();
  const dispatch = useDispatch<Dispatch>();

  const justCancelled = window.location.pathname === '/subscription/cancelled';

  return (
    <LayoutBase>
      <StyledContainer>
        <div>
          {justCancelled && (
            <>
              <StyledCancelled>
                Your subscription has been successfully cancelled.
              </StyledCancelled>
              <Spacer size={1} />
            </>
          )}

          <StyledIntro>
            You don't have an active subscription. Please select one of the
            plans below to get started with your 30 day free trial.
          </StyledIntro>
          <Spacer size={1} />

          <StyledPlans>
            {plans.map((plan, i) => (
              <React.Fragment key={plan.name}>
                <PlanBox {...plan} featuresPlus={plans[i - 1]?.name} />
                {i < plans.length - 1 && <Spacer />}
              </React.Fragment>
            ))}
          </StyledPlans>

          <StyledFooter>
            <a onClick={() => dispatch({ type: 'LOGOUT' })}>
              Log out of {email}
            </a>
          </StyledFooter>
        </div>
      </StyledContainer>
    </LayoutBase>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${Dimen.spacing * 2}px ${Dimen.spacing}px;

  > div {
    max-width: ${rem(1160)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const StyledCancelled = styled.div`
  padding: 15px 30px;
  background-color: #dae0e8;
  border: 1px ${Color.vibrantBlue} solid;
`;

const StyledIntro = styled.p`
  max-width: 780px;
  font-size: 26px;
  text-align: center;
  font-weight: 300;
`;

const StyledPlans = styled('div')`
  display: flex;
  justify-content: center;
  padding-bottom: ${rem(Dimen.spacing * 2)};
`;

const StyledFooter = styled.div`
  a {
    color: ${Color.vibrantBlue};
    transition: color 0.25s;
    &:hover {
      color: ${Color.darkBlue};
    }
  }
`;
