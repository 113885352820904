// @flow

import type { Id } from '../../types';

import { Api } from '@guesthug/core';
import { iriToId } from '@guesthug/core/util';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import {
  EmptyMessageBlock,
  Modal,
  ModalChoice,
  PropertyFeaturedImage,
} from '../../components';
import { Dimen } from '../../constants';
import AnalyticsProperty from './AnalyticsProperty';
import BoxOverallRatings from './BoxOverallRatings';
import BoxOverallServicesRevenue from './BoxOverallServicesRevenue';

type Props = {
  currency: string,
  bookings: Array<Api.Booking.V_Analytics>,
  properties: Array<{
    name: string,
    id: Id,
    image: Api.Image.BaseView | null,
  }>,
};

export default function AnalyticsMain({
  currency,
  bookings,
  properties,
}: Props) {
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [selectedProperty, setSelectedProperty] = React.useState<Id | null>(
    properties.length > 0 ? properties[0].id : null
  );

  if (properties.length === 0) {
    return (
      <EmptyMessageBlock>You don't have any Listings yet</EmptyMessageBlock>
    );
  }

  const selected = properties.find(
    (property) => property.id === selectedProperty
  );

  if (!selected || !selectedProperty) return 'Error';

  return (
    <>
      <StyledMainCols>
        <StyledTitlePrompt onClick={() => setModalVisible(true)}>
          <StyledTitle>{selected?.name}</StyledTitle>
          <img src={require('../../../assets/img/ui/arrow.svg')} />
        </StyledTitlePrompt>
        <div>
          <StyledImage property={{ propertyMainImage: selected.image }} />
        </div>
      </StyledMainCols>

      <StyledMainCols>
        <div>
          <AnalyticsProperty
            currency={currency}
            bookings={bookings.filter(
              (b) => iriToId(b.property['@id']) === selectedProperty
            )}
          />
        </div>
        <div>
          <BoxOverallServicesRevenue bookings={bookings} currency={currency} />
          <BoxOverallRatings bookings={bookings} />
        </div>
      </StyledMainCols>

      {modalVisible && (
        <Modal
          title="Select a Listing"
          onDismiss={() => setModalVisible(false)}
        >
          {properties.map((property) => (
            <ModalChoice
              key={property.id}
              onClick={() => {
                setModalVisible(false);
                setSelectedProperty(property.id);
              }}
            >
              {property.name}
            </ModalChoice>
          ))}
        </Modal>
      )}
    </>
  );
}

const StyledMainCols = styled('div')`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${rem(Dimen.spacing)};
  min-width: ${rem(1000)};

  > *:first-child {
    flex: 1;
  }

  > *:last-child {
    width: ${rem(300)};
    margin-left: ${rem(Dimen.spacing)};
  }
`;

const StyledTitlePrompt = styled('a')`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${rem(Dimen.spacing / 2)} ${rem(Dimen.spacing / 2)};
  transition: background-color 0.25s;
  border-radius: ${rem(5)};

  img {
    width: ${rem(22)};
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const StyledTitle = styled('h1')`
  font-weight: normal;
  font-size: ${rem(45)};
  margin: 0;
`;

const StyledImage = styled(PropertyFeaturedImage)`
  width: ${rem(300)};
  height: ${rem(180)};
`;
