// @flow

import type { Dispatch } from '../types';

import { Api } from '@guesthug/core';
import { lighten, rem } from 'polished';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Avatar, Spacer } from '../components';
import { Color, Dimen } from '../constants';
import { SearchBar } from '../pods/search';

type Props = $ReadOnly<{|
  user: Api.User.V_Full,
  messagingVisible: boolean,
  setMessagingVisible: (boolean) => void,
|}>;

export default function Header({
  user,
  messagingVisible,
  setMessagingVisible,
}: Props) {
  const dispatch = useDispatch<Dispatch>();
  return (
    <Container>
      <Logo>
        <img src={require('../../assets/img/logo_white.svg')} />
      </Logo>
      <AreaMiddle>
        <SearchBar />
        <StyledInfo>
          {user.firstName} {user.lastName}
          <br />
          <a onClick={() => dispatch({ type: 'LOGOUT' })}>Log out</a>
        </StyledInfo>
        <Avatar size="small" user={user} />
        <Spacer size={0.5} />
        <StyledMessagingButton
          onClick={() => setMessagingVisible(!messagingVisible)}
        >
          <img
            src={require('../../assets/img/messaging/top_icon.svg')}
            style={{ width: 22 }}
          />
        </StyledMessagingButton>
      </AreaMiddle>
    </Container>
  );
}

const Container = styled('header')`
  height: ${rem(Dimen.hostWebHeaderHeight)};
  display: flex;
  align-items: stretch;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  z-index: 10;
`;

const Logo = styled('div')`
  width: ${rem(Dimen.hostWebLayoutPanelWidth)};
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #253745;

  img {
    width: ${rem(180)};
  }
`;

const AreaMiddle = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
  padding-right: ${rem(30)};
`;

const StyledInfo = styled('div')`
  text-align: right;
  margin-right: ${rem(20)};

  > a {
    color: #a9a9a9;
    transition: color 0.25s;
    &:hover {
      color: ${Color.vibrantBlue};
    }
  }
`;

const StyledMessagingButton = styled('a')`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: ${Color.darkBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 ${rem(8)} rgba(0, 0, 0, 0.25);
  transition: background-color 0.25s;

  &:hover {
    background-color: ${lighten(0.1, Color.darkBlue)};
  }
`;
