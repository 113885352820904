// @flow

import type { Id } from '../../../types';

import { Api } from '@guesthug/core';
import { compareDesc, parseISO } from 'date-fns';
import * as React from 'react';
import styled from 'styled-components';

import { EmptyMessageBlock } from '../../../components';
import ListThread from './ListThread';

type Props = $ReadOnly<{|
  lastReadByBooking: { [key: Id]: Id },
  hostMessages: Api.User.V_Host,
  guestMessages: Api.User.V_Guest,
  userId: Id,
  onSelectId: (Id | null) => mixed,
|}>;

export default function MessagingList({
  lastReadByBooking,
  userId,
  onSelectId,
  hostMessages,
  guestMessages,
}: Props) {
  const ownerBookings =
    (hostMessages.properties &&
      hostMessages.properties.reduce((carry, p) => {
        return [...carry, ...p.bookings.map((b) => ({ ...b, name: p.name }))];
      }, [])) ||
    [];
  const guestBookings = guestMessages.mainGuestBookings;
  const messagesByBooking = {};

  const bookings = [...ownerBookings, ...guestBookings]
    .map((booking) => {
      messagesByBooking[booking.id] = booking.messages.sort((a, b) =>
        compareDesc(parseISO(a.createdAt), parseISO(b.createdAt))
      );
      return booking;
    })
    .filter((booking) => messagesByBooking[booking.id].length > 0)
    .sort((a, b) =>
      compareDesc(
        parseISO(messagesByBooking[a.id][0].createdAt),
        parseISO(messagesByBooking[b.id][0].createdAt)
      )
    );

  // Calculate num unread for each booking
  const numUnreadByBooking = {};
  bookings.forEach((booking) => {
    const messages = messagesByBooking[booking.id].map((message) => message.id);
    const lastReadId = lastReadByBooking[booking.id] || null;
    let lastReadIndex = lastReadId && messages.indexOf(lastReadId);
    if (lastReadIndex === -1) lastReadIndex = null;
    numUnreadByBooking[booking.id] =
      lastReadIndex === null ? messages.length : lastReadIndex;
  });

  // // Group by read/unread
  const groups = { unread: [], read: [] };
  bookings.forEach((booking) => {
    if (numUnreadByBooking[booking.id] > 0) {
      groups.unread.push(booking);
    } else {
      groups.read.push(booking);
    }
  });

  const renderBooking = (booking: any) => {
    const owner = booking.property ? booking.property.owner : null;
    const mainGuest = booking.mainGuest ?? null;
    let otherUser = null;
    if (owner && owner !== userId) {
      otherUser = owner;
    } else if (mainGuest && mainGuest.id !== userId) {
      otherUser = mainGuest;
    }
    if (!otherUser) return null;
    return (
      <ListThread
        key={booking.id}
        booking={booking}
        otherUser={otherUser}
        onSelectId={onSelectId}
        unreadCount={numUnreadByBooking[booking.id]}
      />
    );
  };

  return (
    <StyledList>
      {bookings.length === 0 ? (
        <EmptyMessageBlock>
          You don't have any messages yet. Use the plus button above to send
          your first.
        </EmptyMessageBlock>
      ) : (
        bookings.map(renderBooking)
      )}
    </StyledList>
  );
}

const StyledList = styled('div')`
  flex: 1;
  overflow-y: auto;
`;
