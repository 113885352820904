// @flow

import type { Store } from '../types';

import { ApiReadConfig } from '@guesthug/core';
import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { configureStoreAsync } from '../store';
import AnalyticsTracker from './AnalyticsTracker';
import App from './App';

export default function AppLoader() {
  const [store, setStore] = React.useState<Store | null>(null);

  React.useEffect(() => {
    (async () => {
      const s = await configureStoreAsync();
      setStore(s);
    })();
  }, []);

  return store ? (
    <Provider store={store}>
      <HelmetProvider>
        <ApiReadConfig>
          <BrowserRouter>
            <AnalyticsTracker />
            <App />
          </BrowserRouter>
        </ApiReadConfig>
      </HelmetProvider>
    </Provider>
  ) : (
    <div>Initialising app</div>
  );
}
