// @flow

import { rem } from 'polished';
import styled from 'styled-components';

import { Dimen } from '../constants';

export default styled('a')`
  padding: ${rem(Dimen.spacing / 2)} ${rem(Dimen.spacing)};
  transition: background-color 0.25s;

  &:hover {
    background-color: #f8f8f8;
  }

  &:not(:first-child) {
    border-top: 1px #e7ebf1 solid;
  }

  &:last-child {
    margin-bottom: ${rem(Dimen.spacing / 2)};
  }
`;
