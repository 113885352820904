// @flow

import { Style } from '@guesthug/core';
import { GHText, Spacer } from '@guesthug/core/components';
import * as React from 'react';
import { View } from 'react-native';

import { PressableLink } from '../../../components';

type Props = $ReadOnly<{|
  current: 'tiles' | 'table',
|}>;

// TODO: Icons
export default function PropertiesModeSwitcher({ current }: Props) {
  return (
    <View style={[Style.row, Style.alignCenter]}>
      <PressableLink to="/properties">
        <GHText
          color={current === 'table' ? 'interactive' : 'faint'}
          weight={current === 'table' ? 'bold' : undefined}
        >
          Table
        </GHText>
      </PressableLink>
      <Spacer size={0.5} />
      <PressableLink to="/properties/list">
        <GHText
          color={current === 'tiles' ? 'interactive' : 'faint'}
          weight={current === 'tiles' ? 'bold' : undefined}
        >
          List
        </GHText>
      </PressableLink>
    </View>
  );
}
