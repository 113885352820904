// @flow

import type { Dispatch, RootState } from '../../types';

import { ghApiRequest } from '@guesthug/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { UntypedFormComponent } from 'react-typed-form';
import styled from 'styled-components';

import { flashShow } from '../../actions/flash';
import { Expander } from '../../components';
import { Color } from '../../constants';
import {
  FieldInput,
  FormWrap,
  SubmitButton,
  createFormValidator,
} from '../../form';
import { authUserSelector } from '../../selectors/user';

type OwnProps = $ReadOnly<{||}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  jwt: string,
  dispatch: Dispatch,
|}>;

class SectionHelp extends React.PureComponent<Props> {
  _expanderRef: { current: React.ElementRef<typeof Expander> | null };

  constructor() {
    super();
    this._expanderRef = React.createRef();
  }

  render() {
    const { jwt, dispatch } = this.props;
    return (
      <Expander title="Get Help" wrappedComponentRef={this._expanderRef}>
        <UntypedFormComponent
          validator={createFormValidator({
            subject: { strictPresence: { mustDefine: true } },
            content: { strictPresence: { mustDefine: true } },
          })}
          onSubmit={async (values, { setLoading }) => {
            setLoading(true);
            const { data } = await ghApiRequest('/help-email', {
              method: 'POST',
              jsonBody: values,
              jwt,
            });

            if (data.success) {
              dispatch(flashShow('Message sent'));
              const { current } = this._expanderRef;
              current && current.collapse();
            } else {
              setLoading(false);
              alert(
                'Something went wrong, and your message may not have been sent.'
              );
            }
          }}
        >
          {({ getField, handleSubmit, isLoading }) => (
            <FormWrap handleSubmit={handleSubmit}>
              <p>
                Answers to our most common questions can be found in the{' '}
                <StyledA
                  href="https://www.guesthug.com/faq/host"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FAQ's section
                </StyledA>{' '}
                of our website. Or, feel free to use the form below to open a
                support ticket with us by email.
              </p>

              <FieldInput field={getField('subject')} />
              <FieldInput
                field={{ ...getField('content'), label: 'Message' }}
                multiline
              />
              <SubmitButton
                isLoading={isLoading}
                normalLabel="Send"
                loadingLabel="Sending"
              />
            </FormWrap>
          )}
        </UntypedFormComponent>
      </Expander>
    );
  }
}

export default connect<Props, OwnProps, _, _, _, _>((state: RootState) => ({
  jwt: authUserSelector(state).jwt,
}))(SectionHelp);

const StyledA = styled('a')`
  color: ${Color.vibrantBlue};
`;
