// @flow

import type { Id } from '../../types';

import { Api } from '@guesthug/core';
import { useApiCallable } from '@guesthug/core';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import {
  ContentLoading,
  Modal,
  ReturnLink,
  ScreenError,
} from '../../components';
import { SubmitButton } from '../../form';
import ServiceForm from './ServiceForm';

type Props = $ReadOnly<{|
  match: { params: { id: Id } },
|}>;

export default function ServiceEditPage({ match }: Props) {
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
  const [deletedOrSaved, setDeletedOrSaved] = React.useState<boolean>(false);
  const [deleteModalVisible, setDeletedModalVisible] =
    React.useState<boolean>(false);

  const callApi = useApiCallable();
  const { id } = match.params;

  const apiService = Api.Service.useReadItem(id);

  if (apiService.error) return <ScreenError {...apiService} />;
  if (!apiService.data) return <ContentLoading />;

  const service = apiService.data;

  if (deletedOrSaved) {
    return <Redirect to="/services/guest" />;
  }

  const returnLink = (
    <ReturnLink to="/services">Return to Services Overview</ReturnLink>
  );

  if (!service) return <Redirect to="/services" />;

  return (
    <>
      <Helmet>
        <title>{service.title}</title>
      </Helmet>

      {deleteModalVisible && (
        <Modal
          title={`Really delete ${service.title}?`}
          padded
          onDismiss={() => setDeletedModalVisible(false)}
        >
          <StyledSubmitButton
            isLoading={isDeleting}
            normalLabel="Delete Service"
            loadingLabel="Deleting"
            onClick={async () => {
              setIsDeleting(true);
              const response = await callApi(`/services/${id}`, {
                method: 'DELETE',
              });
              if (response) {
                setDeletedOrSaved(true);
              }
              setIsDeleting(false);
              setDeletedModalVisible(false);
            }}
          />
        </Modal>
      )}

      {returnLink}
      <ServiceForm
        service={service}
        onSubmit={async (values, { setLoading, addSubmitError }) => {
          setLoading(true);
          const response = await callApi(`/services/${id}`, {
            method: 'PUT',
            jsonBody: values,
          });
          if (response) {
            setDeletedOrSaved(true);
          }
        }}
        onDeleteClick={() => setDeletedModalVisible(true)}
        currency={service.currency}
      />
    </>
  );
}

const StyledSubmitButton = styled(SubmitButton)`
  margin: 0;
  align-self: flex-start;
`;
