// @flow

import type { Dispatch } from '../../types';

import { rem } from 'polished';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Expander } from '../../components';
import { Color } from '../../constants';

export default function SectionSubscriptionNone() {
  const dispatch = useDispatch<Dispatch>();

  return (
    <Expander title="Subscription" expandOnUrlHash="subscription">
      <StyledSubscription>
        <div>Starter</div>
        <div>
          <span>
            Explore our paid plans to unlock more from GuestHug, including a
            full Channel Manager, ID verification, and much more.
          </span>
        </div>
        <a
          onClick={() =>
            dispatch({
              type: 'SET_OPTION_DISMISSED_SELECT_PLAN',
              payload: false,
            })
          }
        >
          Upgrade your plan
        </a>
      </StyledSubscription>
    </Expander>
  );
}

const StyledSubscription = styled.div`
  span {
    color: ${Color.faintBlue};
    font-size: ${rem(14)};
  }

  a {
    display: block;
    margin-top: 15px;
    color: ${Color.vibrantBlue};
    font-size: ${rem(14)};
  }
`;
