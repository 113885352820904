// @flow

import { CalendarCellBlocked, GHText } from '@guesthug/core/components';
import { endOfMonth, isSameDay } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { formatPrice } from '../../../util';

type Props = $ReadOnly<{|
  date: string,
  /**
   * Null for instances where you don't want to show the rate, e.g. when it's
   * blocked by a booking OR when it's loading
   */
  rate: number | null,
  blocked: boolean,
  loading?: boolean,
|}>;

export default React.memo<Props>(function MultiCalendarCell({
  date,
  rate,
  blocked,
  loading,
}: Props) {
  const parsedDate = parseISO(date);
  return (
    <View style={[styles.cellWrap, loading && styles.cellWrapLoading]}>
      {blocked ? (
        <View style={styles.cell}>
          <CalendarCellBlocked />
        </View>
      ) : (
        <View
          style={[
            styles.cell,
            styles.cellNormal,
            isSameDay(parsedDate, endOfMonth(parsedDate)) &&
              styles.cellEndOfMonth,
          ]}
        >
          {rate && (
            <GHText color="grey" size="small" selectable={false}>
              {formatPrice(rate * 100, 'gbp', { round: true })}
            </GHText>
          )}
        </View>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  cellWrap: {
    width: 80,
    height: 100,
    // Room for shadow
    paddingVertical: 10,
    overflow: 'hidden',
    ...({
      transitionProperty: 'opacity',
      transitionDuration: '250ms',
    }: any),
  },
  cellWrapLoading: {
    opacity: 0.6,
  },
  cell: {
    width: 80,
    height: 80,
    marginBottom: 20,
    shadowColor: '#000000',
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 5,
  },
  cellNormal: {
    backgroundColor: '#fff',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: 8,
    borderRightWidth: 1,
    borderRightColor: '#e7e7e7',
  },
  cellEndOfMonth: {
    borderRightWidth: 3,
  },
});
