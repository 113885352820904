// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Color, Dimen } from '../constants';

type Props = $ReadOnly<{|
  type?: 'info' | 'empty',
  children: React.Node,
|}>;

const colors = {
  info: Color.faintBlue,
  empty: Color.fainterBlue,
};

const MessageBlock = ({ type = 'info', children }: Props) => (
  <StyledContainer type={type}>
    <span>{children}</span>
  </StyledContainer>
);
export default MessageBlock;

const StyledContainer = styled('div')`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${rem(Dimen.spacing)};
  text-align: center;
  color: ${(p) => colors[p.type]};
  box-sizing: border-box;

  span {
    max-width: ${rem(500)};
    a {
      color: ${Color.vibrantBlue};
      transition: color 0.25s;
      &:hover {
        color: ${Color.darkBlue};
      }
    }
  }
`;
