// @flow

import type { Purchase } from './types';

import {
  compareDesc,
  endOfMonth,
  format,
  isAfter,
  isSameMonth,
  min,
  parseISO,
  subMonths,
} from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { EmptyMessageBlock, Modal, ModalChoice } from '../../components';
import { Color, Dimen } from '../../constants';
import { formatPrice } from '../../util';

type Props = $ReadOnly<{|
  purchases: Array<Purchase>,
|}>;

export default function SectionTransactions({ purchases }: Props) {
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = React.useState<string>(
    format(new Date(), 'yyyy-MM')
  );

  const allPurchases = purchases
    .filter((purchase) =>
      isSameMonth(parseISO(purchase.createdAt), parseISO(`${selectedMonth}-01`))
    )
    .sort((a, b) => compareDesc(parseISO(a.createdAt), parseISO(b.createdAt)));

  const choiceMonths = [];
  const firstPurchaseAt = purchases.reduce(
    (carry, p) => min([parseISO(p.createdAt), carry]),
    new Date()
  );
  let current = new Date();
  while (isAfter(endOfMonth(current), firstPurchaseAt)) {
    choiceMonths.push(format(current, 'yyyy-MM'));
    current = subMonths(current, 1);
  }
  return (
    <>
      <div>
        <StyledPrompt onClick={() => setModalVisible(true)}>
          <div>
            <span>Transaction History</span>
            <strong>
              {format(parseISO(`${selectedMonth}-01`), 'MMMM yyyy')}
            </strong>
          </div>
          <img src={require('../../../assets/img/ui/arrow.svg')} />
        </StyledPrompt>

        <div>
          {allPurchases.length === 0 && (
            <EmptyMessageBlock>No purchases this month</EmptyMessageBlock>
          )}

          {allPurchases.map((purchase) => {
            const { mainGuest } = purchase;
            const lineItems = purchase.lineItems;

            const total = lineItems.reduce(
              (carry, li) => carry + li.price * li.quantity,
              0
            );
            const currency = lineItems.length ? lineItems[0].currency : 'gbp';
            return (
              <StyledPurchase key={purchase['@id']}>
                <div>
                  <dt>Name</dt>
                  <dd>
                    {mainGuest &&
                      `${mainGuest.firstName} ${mainGuest.lastName}`}
                  </dd>
                </div>
                <div>
                  <dt>Property</dt>
                  <dd>{purchase.name}</dd>
                </div>
                <div>
                  <dt>Items</dt>
                  <dd>
                    {lineItems.map((li) => (
                      <div key={li.id}>
                        {li.name} x {li.quantity}
                      </div>
                    ))}
                  </dd>
                </div>
                <div>
                  <dt>Total</dt>
                  <dd>
                    <b>{formatPrice(total, currency)}</b>
                  </dd>
                </div>
              </StyledPurchase>
            );
          })}
        </div>
      </div>

      {modalVisible && (
        <Modal title="Select a Month" onDismiss={() => setModalVisible(false)}>
          {choiceMonths.map((month) => (
            <ModalChoice
              key={month}
              onClick={() => {
                setModalVisible(false);
                setSelectedMonth(month);
              }}
            >
              {format(parseISO(`${month}-01`), 'MMMM yyyy')}
            </ModalChoice>
          ))}
        </Modal>
      )}
    </>
  );
}

const StyledPrompt = styled('a')`
  padding: ${rem(Dimen.spacing / 2)};
  display: flex;
  align-items: center;
  border-bottom: 1px #e7ebf1 solid;
  transition: background-color 0.25s;

  > div:first-child {
    flex: 1;
  }

  img {
    width: ${rem(22)};
  }

  span {
    display: block;
    font-weight: 300;
    font-size: ${rem(14)};
    color: ${Color.faintBlue};
  }

  strong {
    font-size: ${rem(18)};
    font-weight: normal;
  }

  b {
    font-weight: 600;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const StyledPurchase = styled('div')`
  padding: ${rem(Dimen.spacing / 2)};
  border-bottom: 1px #e7ebf1 solid;
  font-size: ${rem(14)};
  font-weight: 300;

  &:last-child {
    border-bottom: 0;
  }

  > div {
    display: flex;
    margin-bottom: ${rem(6)};
    &:last-child {
      margin-bottom: 0;
    }
  }

  dt {
    color: ${Color.faintBlue};
    width: ${rem(80)};
  }

  dd {
    flex: 1;
  }
`;
