// @flow

import { Api } from '@guesthug/core';
import { GHText } from '@guesthug/core/components';
import { iriToId } from '@guesthug/core/util';
import { rem } from 'polished';
import * as React from 'react';

import { MidTitleLink } from '../../components';
import { values } from '../../util';
import DashboardBox from './DashboardBox';

type Props = $ReadOnly<{|
  bookings: Array<Api.Booking.V_Dashboard>,
|}>;

export default function BoxPopularServices({ bookings }: Props) {
  const dict = {};

  bookings.forEach((booking) => {
    booking.purchases.forEach((purchase) => {
      purchase.lineItems?.forEach((lineItem) => {
        const { name, service } = lineItem;
        if (!name || !service) return;
        if (dict[service] == null) {
          dict[service] = {
            quantity: 0,
            name,
            serviceId: service ? iriToId(service) : null,
          };
        }
        dict[service].quantity += lineItem.quantity;
      });
    });
  });

  const mostPopular = values(dict).reduce(
    (carry, item) => {
      return item.quantity > carry.quantity ? item : carry;
    },
    { quantity: 0, name: null, serviceId: '' }
  );

  return (
    <DashboardBox title="Most Popular Service" padded>
      {mostPopular.serviceId ? (
        <MidTitleLink
          to={
            mostPopular.serviceId
              ? `/services/guest/${mostPopular.serviceId}`
              : undefined
          }
        >
          {mostPopular.name ? mostPopular.name : '-'}
        </MidTitleLink>
      ) : (
        <GHText size="larger">
          {mostPopular.name ? mostPopular.name : '-'}
        </GHText>
      )}
    </DashboardBox>
  );
}
