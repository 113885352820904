// @flow

import type { Dispatch, Id } from '../../../types';

import { Api, Dimen, Model, useApiCallable } from '@guesthug/core';
import { GHText } from '@guesthug/core/components';
import { rem } from 'polished';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { flashShow } from '../../../actions/flash';
import {
  ContentLoading,
  Expander,
  Modal,
  PropertyFeaturedImage,
  ReturnLink,
  ScreenError,
} from '../../../components';
import { Color } from '../../../constants';
import { SubmitButton } from '../../../form';
import { useAuthUser } from '../../../hooks';
import { SectionBookingSettings } from '../components';
import LinkBar from '../components/LinkBar';
import SectionAddress from '../components/SectionAddress';
import SectionCheckInOutGuide from '../components/SectionCheckInOutGuide';
import SectionDetails from '../components/SectionDetails';
import SectionImport from '../components/SectionImport';
import SectionPhotos from '../components/SectionPhotos';
import SectionPricing from '../components/SectionPricing';
import SectionServices from '../components/SectionServices';
import SectionWelcomeMessage from '../components/SectionWelcomeMessage';

type Props = {
  match: {
    params: {
      id: Id,
    },
  },
};

export default function PropertyPage({ match }: Props) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [archiveModalVisible, setArchiveModalVisible] =
    React.useState<boolean>(false);

  const { id } = match.params;

  const callApi = useApiCallable();
  const authUser = useAuthUser();
  const dispatch = useDispatch<Dispatch>();

  const api = Api.Property.useReadItemFull(id, { staleWhileInvalidated: true });
  const apiUser = Api.User.useReadItemFull(authUser.id);

  if (api.error) return <ScreenError {...api} />;
  if (apiUser.error) return <ScreenError {...apiUser} />;
  if (!api.data || !apiUser.data) return <ContentLoading />;

  const property = api.data;
  const { services } = apiUser.data;

  const returnLink = (
    <ReturnLink to="/properties">Return to Listings Overview</ReturnLink>
  );

  if (!property) return <Redirect to="/properties" />;
  console.log(property);

  const completeSections = Model.Property.getCompleteSections(property);

  return (
    <>
      <Helmet>
        <title>{property.name}</title>
      </Helmet>

      {archiveModalVisible && (
        <Modal
          title={`Are you sure you want to ${
            property.isArchived ? 'unarchive' : 'archive'
          } ${property.name}?`}
          padded
          onDismiss={() => setArchiveModalVisible(false)}
        >
          <StyledSubmitButton
            isLoading={loading}
            normalLabel={property.isArchived ? 'Unarchive' : 'Archive Listing'}
            loadingLabel={property.isArchived ? 'Unarchiving' : 'Archiving'}
            onClick={async () => {
              setLoading(true);
              const response = await callApi(`/properties/${property.id}`, {
                method: 'PUT',
                jsonBody: { isArchived: !property.isArchived },
              });
              if (response) {
                api.invalidate();
                setArchiveModalVisible(false);
                dispatch(
                  flashShow(
                    `Property ${
                      property.isArchived ? 'unarchived' : 'archived'
                    }`
                  )
                );
              }
              setLoading(false);
            }}
          />
        </Modal>
      )}

      {/* <HelpPanel title="Learn how to import your bookings here">
        <HelpStep
          title="Step 1"
          description="Login to your host dashboard in your booking portal (Airbnb / Booking.com)"
          imageSrc="/img/help/import/login.png"
        />
        <HelpStep
          title="Step 2"
          description="Navigate to your host calendar and goto 'Availability Settings (Airbnb) or 'Rates & Availability' (Booking.com)"
          imageSrc="/img/help/import/availability.png"
        />
        <HelpStep
          title="Step 3"
          description="Click on and copy the 'Export Calendar' link and paste the URL into 'Import Booking Portal Calendars'"
          imageSrc="/img/help/import/export.png"
        />
      </HelpPanel> */}

      <AreaTop>
        <AreaTopLeft>
          {returnLink}
          <StyledTitle>{property.name}</StyledTitle>
          <StyledArchiveLink onClick={() => setArchiveModalVisible(true)}>
            {property.isArchived ? 'Unarchive' : 'Archive'}
          </StyledArchiveLink>
        </AreaTopLeft>
        <StyledImage property={property} />
      </AreaTop>

      <View
        style={{
          paddingTop: 20,
          paddingLeft: Dimen.spacing,
          paddingBottom: 7,
          marginBottom: 10,
          borderBottomWidth: 1,
          borderBottomColor: Color.border,
        }}
      >
        <GHText color="faint">PMS Settings</GHText>
      </View>
      <SectionDetails
        property={property}
        complete={completeSections.details}
        invalidate={api.invalidate}
      />
      {/* Split out so it can do its own API request invalidation */}
      <Expander title="Property Address" complete={completeSections.address}>
        <SectionAddress propertyId={property.id} invalidate={api.invalidate} />
      </Expander>
      <SectionPricing
        property={property}
        complete={completeSections.pricing}
        invalidate={api.invalidate}
      />
      <SectionBookingSettings
        property={property}
        complete={completeSections.bookingSettings}
        invalidate={api.invalidate}
      />
      <SectionPhotos
        property={property}
        complete={completeSections.photos}
        invalidate={api.invalidate}
      />
      <SectionImport
        property={property}
        complete={completeSections.import}
        invalidate={api.invalidate}
      />

      <View
        style={{
          paddingTop: 20,
          paddingLeft: Dimen.spacing,
          paddingBottom: 7,
          marginBottom: 10,
          borderBottomWidth: 1,
          borderBottomColor: Color.border,
        }}
      >
        <GHText color="faint">Guest App</GHText>
      </View>
      <SectionCheckInOutGuide
        property={property}
        complete={completeSections.checkInOutGuide}
        invalidate={api.invalidate}
      />
      <SectionWelcomeMessage
        property={property}
        complete={completeSections.welcomeMessage}
        invalidate={api.invalidate}
      />
      <SectionServices
        property={property}
        complete={completeSections.services}
        services={services}
        invalidate={api.invalidate}
      />
      <LinkBar
        title="Home Guide"
        complete={completeSections.homeGuide}
        to={`/properties/${property.id}/home-guide`}
      />
      <LinkBar
        title="Local Guide"
        complete={completeSections.localGuide}
        to={`/properties/${property.id}/local-guide`}
        disabledMessage={
          property.addressLatitude
            ? undefined
            : 'To search for nearby places, first complete your property address'
        }
      />
    </>
  );
}

const AreaTop = styled('div')`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${rem(35)};
  flex-shrink: 0;
`;

const AreaTopLeft = styled('div')`
  flex: 1;
`;

const StyledTitle = styled('h1')`
  font-weight: normal;
  font-size: ${rem(45)};
  margin: ${rem(30)} 0 ${rem(15)};
`;

const StyledArchiveLink = styled('a')`
  transition: color 0.25s;
  &:hover {
    color: ${Color.vibrantBlue};
  }
`;

const StyledImage = styled(PropertyFeaturedImage)`
  width: ${rem(300)};
  height: ${rem(180)};
`;

const StyledSubmitButton = styled(SubmitButton)`
  margin: 0;
  align-self: flex-start;
`;
