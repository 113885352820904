// @flow

import type { Id } from '../../../types';

import { Api, Style, useApiCallable } from '@guesthug/core';
import { GHText, Spacer } from '@guesthug/core/components';
import { iriToId } from '@guesthug/core/util';
import { differenceInDays, format, parseISO } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import { View } from 'react-native';
import styled from 'styled-components';

import {
  Avatar,
  Button,
  ContentLoading,
  Modal,
  ScreenError,
} from '../../../components';
import * as config from '../../../config';
import { Color, Dimen, Domain } from '../../../constants';
import { FieldSwitch } from '../../../form';

type Props = $ReadOnly<{|
  id: Id,
  invalidate: () => mixed,
|}>;

export default function PaneBooking({ id, invalidate }: Props) {
  const callApi = useApiCallable();

  const [separateLink, setSeparateLink] = React.useState<boolean | null>(null);

  const [deleteModalVisible, setDeleteModalVisible] =
    React.useState<boolean>(false);
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);

  const api = Api.Booking.useReadItem(id);

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ContentLoading />;

  const booking = api.data;

  const { property, mainGuest } = booking;
  const provider = booking.calendar?.provider;

  const guestFullName: ?string = mainGuest
    ? `${mainGuest.firstName} ${mainGuest.lastName}`
    : booking.providerGuestName;

  const guestDisplayName =
    guestFullName || (provider && `${provider.name} guest`) || 'Guest';

  function getInviteLink() {
    const guestSlug: string | null = guestFullName
      ? guestFullName
          .trim()
          .toLowerCase()
          .replace(/[^a-z\s]/g, '')
          .replace(/\s+/g, '.')
      : null;

    // The ?g= is unused, just to make link look more personalised
    return `${config.DEEP_LINK_ROOT}/invite/${booking.id}?g=${guestSlug ?? ''}`;
  }

  function getMessageContent() {
    let guestFirstName: ?string = mainGuest && mainGuest.firstName;
    if (!guestFirstName && booking.providerGuestName) {
      const matches = booking.providerGuestName.match(/^[^ ]+/);
      if (matches) {
        guestFirstName = matches[0];
      }
    }

    /* const sig = myUser.firstName
                    ? `\n\nBest, ${myUser.firstName}`
                    : ''; */

    const inviteLink = getInviteLink();

    let message = `Hey${
      guestFirstName ? ` ${guestFirstName}` : ''
    }, I'm sharing a link to the free GuestHug app which gives you easy access to all the information about your check-in, my local area recommendations and home guide.

Alternatively, you can download GuestHug straight from the Apple Store or Google Play Store and paste this invite code:

${booking.inviteCode || ''}`;

    if (!separateLink) {
      message = `${message}\n\n${inviteLink}`;
    }

    return message;
  }
  return (
    <>
      <AreaTop>
        <TopBlock>
          <div style={{ flex: 1 }}>
            <HeaderRow>
              <dd>
                {booking.deletedAt ? (
                  <del>{guestDisplayName}</del>
                ) : (
                  guestDisplayName
                )}
              </dd>
            </HeaderRow>
            {booking.deletedAt && (
              <Row>
                <dd>
                  <b>Cancelled</b>
                </dd>
              </Row>
            )}
            <Row>
              <dd>{property.name}</dd>
            </Row>
          </div>
          <StyledBadges>
            {mainGuest && <StyledAvatar user={mainGuest} size="small" />}
          </StyledBadges>
        </TopBlock>
        <Block>
          <Row>
            <dt>Arr.</dt>
            <dd>
              {format(
                parseISO(
                  `${booking.startDate}T${
                    property.arriveAfter || Domain.defaultArriveAfter
                  }`
                ),
                "d MMMM y 'at' HH:mm"
              )}
            </dd>
          </Row>
          <Row>
            <dt>Dep.</dt>
            <dd>
              {format(
                parseISO(
                  `${booking.endDate}T${
                    property.departBefore || Domain.defaultDepartBefore
                  }`
                ),
                "d MMMM y 'at' HH:mm"
              )}
            </dd>
          </Row>
          <Row>
            <dt />
            <dd>
              Duration:{' '}
              {differenceInDays(
                parseISO(booking.endDate),
                parseISO(booking.startDate)
              )}{' '}
              Nights
            </dd>
          </Row>
          {booking.numberOfGuest && (
            <Row>
              <dt />
              <dd>Guests: {booking.numberOfGuest}</dd>
            </Row>
          )}
          {booking.paymentMethod && (
            <Row>
              <dt />
              <dd>Payment: {booking.paymentMethod}</dd>
            </Row>
          )}
        </Block>
        <Block>
          <Row>
            <dt>ID</dt>
            <dd>{iriToId(booking['@id'])}</dd>
            {booking.mainGuest && (
              <StyledConfirmedImg
                src={require('../../../../assets/img/ui/input_valid.svg')}
              />
            )}
            {provider && (
              <StyledProviderImg
                src={require(`../../../../assets/img/providers/${provider.slug}.png`)}
                unconfirmed={!booking.mainGuest}
              />
            )}
          </Row>
        </Block>
      </AreaTop>
      {!booking.deletedAt && (
        <div>
          <LightBlock>
            <p>Booking invite link</p>
            <StyledTextarea
              value={getMessageContent()}
              style={{ height: separateLink ? 178 : 230 }}
            />
            {separateLink && (
              <StyledTextarea
                value={getInviteLink()}
                style={{ height: 44, marginTop: 10 }}
              />
            )}
            <FieldSwitch
              field={{
                value: separateLink,
                handleValueChange: (value) => setSeparateLink(value),
              }}
              description="Separate link (recommended for Airbnb)"
            />
            {/* <SubmitButton
                        isLoading={false}
                        normalLabel="Copy"
                        onClick={() => {
                          const node = this._inputRef.current;
                          if (node) {
                            const isiOSDevice = navigator.userAgent.match(
                              /ipad|iphone/i
                            );
                            if (isiOSDevice) {
                              const editable = node.contentEditable;
                              const readOnly = node.readOnly;

                              node.contentEditable = 'true';
                              node.readOnly = false;

                              const range = document.createRange();
                              range.selectNodeContents(node);

                              const selection = window.getSelection();
                              selection.removeAllRanges();
                              selection.addRange(range);

                              node.setSelectionRange(0, 999999);
                              node.contentEditable = editable;
                              node.readOnly = readOnly;
                            } else {
                              node.select();
                            }
                            document.execCommand('copy');
                            dispatch(flashShow('Link copied to clipboard'));
                          }
                        }}
                      /> */}
          </LightBlock>
          {!booking.calendar && !booking.ota && (
            <LightBlock>
              <View style={Style.alignStart}>
                <Button
                  label="Delete this booking"
                  colorScheme="red"
                  disabled={isDeleting}
                  onPress={() => setDeleteModalVisible(true)}
                />
              </View>

              {deleteModalVisible && (
                <Modal
                  title={`Really delete booking for ${guestDisplayName}?`}
                  padded
                  onDismiss={() => setDeleteModalVisible(false)}
                >
                  <GHText>
                    This action is permanent, and any guests you shared the link
                    with will lose access to relevant content.
                  </GHText>
                  <Spacer />

                  <Button
                    colorScheme="red"
                    disabled={isDeleting}
                    label="Delete Booking"
                    onPress={async () => {
                      setIsDeleting(true);
                      await callApi(`/bookings/${id}`, {
                        method: 'DELETE',
                      });
                      invalidate();
                      api.invalidate();
                      setIsDeleting(false);
                      setDeleteModalVisible(false);
                    }}
                  />
                </Modal>
              )}
            </LightBlock>
          )}
        </div>
      )}
    </>
  );
}

const AreaTop = styled('div')`
  background-color: ${Color.darkBlue};
`;

const Block = styled('div')`
  padding: ${rem(Dimen.spacing / 2)} ${rem(Dimen.spacing)};
  font-weight: 300;
  ${(p) => !p.noBorder && 'border-bottom: 1px #4e5f70 solid'};
`;

const LightBlock = styled(Block)`
  border-bottom-color: ${Color.border};
`;

const TopBlock = styled(Block)`
  display: flex;
  align-items: center;
`;

const Row = styled('div')`
  display: flex;
  align-items: center;
  padding: ${rem(Dimen.spacing / 4)} 0;

  > dt {
    width: ${rem(58)};
    color: #ffffff77;
  }

  > dd {
    flex: 1;
    color: #fff;
  }
`;

const HeaderRow = styled(Row)`
  font-size: ${rem(22)};
  font-weight: 400;
`;

const StyledAvatar = styled(Avatar)``;

const StyledBadges = styled('div')`
  margin-left: ${rem(Dimen.spacing / 4)};
  position: relative;
  display: flex;
  align-items: center;

  ${StyledAvatar} {
    position: relative;
    z-index: 2;
  }
`;

const StyledTextarea = styled('textarea')`
  box-sizing: border-box;
  width: 100%;
  border: 0;
  background-color: #f4f4f4;
  padding: ${rem(3)};
  font-size: ${rem(13)};
  max-width: 100%;
  min-width: 100%;
`;

const StyledConfirmedImg = styled('img')`
  width: ${rem(18)};
`;

const StyledProviderImg = styled('img')`
  width: ${rem(32)};
  margin: -8px 0 -8px ${rem(8)};
  ${(p) => p.unconfirmed && 'opacity: 0.5'};
`;
