// @flow

import { Api } from '@guesthug/core';
import { iriToId } from '@guesthug/core/util';
import {
  addDays,
  compareAsc,
  format,
  formatISO,
  isSameDay,
  isSameWeek,
  isToday,
  parseISO,
} from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Color, Dimen } from '../../constants';
import { ClassicCalendar } from '../../pods/calendar';
import DashboardBox from './DashboardBox';

type Props = $ReadOnly<{|
  bookings: Array<Api.Booking.V_Dashboard>,
|}>;

export default function BoxAgenda({ bookings }: Props) {
  // Get bookings into asc date order so check outs come before check ins
  const allBookings = bookings.sort((a, b) =>
    compareAsc(parseISO(a.startDate), parseISO(b.startDate))
  );

  const weekOptions = { weekStartsOn: 1 }; // Monday
  let currentDate = new Date(); // startOfWeek(new Date(), weekOptions);
  const dates = [];
  while (isSameWeek(currentDate, new Date(), weekOptions)) {
    dates.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }

  return (
    <DashboardBox title={`Showing ${bookings.length} calendars`} tall>
      <StyledAreaDates>
        <ClassicCalendar
          currentDate={formatISO(new Date(), { representation: 'date' })}
          span="week"
          events={allBookings.map((booking) => ({
            start: booking.startDate.substring(0, 10),
            end: booking.endDate.substring(0, 10),
          }))}
          showTrails={false}
          markerType="dot"
          showToday
        />
      </StyledAreaDates>
      <StyledAreaList>
        {dates.map((date) => {
          const events = [];

          allBookings.forEach((booking) => {
            const selectedBooking = bookings.find((p) => p.id === booking.id);

            if (!selectedBooking) {
              throw new Error('Box Agenda: No selected booking found');
            }
            const { mainGuest } = booking;
            const provider = booking.calendar?.provider;

            const opts = {
              to: `/calendar/${iriToId(selectedBooking.property['@id'])}/${
                booking.id
              }`,
              guestName:
                (mainGuest && `${mainGuest.firstName} ${mainGuest.lastName}`) ||
                booking.providerGuestName ||
                (provider && `${provider.name} guest`) ||
                'Guest',
              propertyName: selectedBooking.property.name,
              provider,
            };
            if (isSameDay(date, parseISO(booking.startDate))) {
              events.push({
                ...opts,
                key: `${booking.id}-in`,
                type: 'Check In',
              });
            }
            if (isSameDay(date, parseISO(booking.endDate))) {
              events.push({
                ...opts,
                key: `${booking.id}-out`,
                type: 'Check Out',
              });
            }
          });

          return (
            <StyledRow key={formatISO(date, { representation: 'date' })}>
              <StyledDay today={isToday(date)}>
                <strong>{format(date, 'd')}</strong>
                <span>{format(date, 'iii')}</span>
              </StyledDay>
              <div style={{ flex: 1 }}>
                {events.length === 0 && (
                  <StyledEmpty>Nothing for today</StyledEmpty>
                )}
                {events.map((event) => (
                  <StyledBooking key={event.key} to={event.to}>
                    <div className="left">
                      <div className="name">{event.guestName}</div>
                      <div className="extra">
                        <strong>{event.propertyName}</strong> - {event.type}
                      </div>
                    </div>
                    {event.provider && (
                      <img
                        src={require(`../../../assets/img/providers/${event.provider.slug}.png`)}
                        className="provider"
                      />
                    )}
                  </StyledBooking>
                ))}
              </div>
            </StyledRow>
          );
        })}
      </StyledAreaList>
    </DashboardBox>
  );
}

const StyledAreaDates = styled('div')`
  padding: ${rem(Dimen.spacing / 2)} 0;
  border-top: 1px #e7ebf1 solid;
`;

const StyledAreaList = styled('div')`
  flex: 1;
  background-color: #e2e7ec;
  border-radius: ${rem(4)};

  overflow-y: auto;
  padding: ${rem(20)} 0;
`;

const StyledRow = styled('div')`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${rem(10)};
`;

const StyledDay = styled('div')`
  width: ${rem(30)};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${rem(10)} ${rem(15)};
  border-right: 1px ${(p) => (p.today ? Color.vibrantBlue : Color.faintBlue)}
    solid;

  strong {
    font-size: ${rem(20)};
    font-weight: 400;
    margin-bottom: ${rem(Dimen.spacing / 8)};
    color: ${(p) => (p.today ? Color.vibrantBlue : Color.faintBlue)};
  }

  span {
    font-size: ${rem(12)};
    font-weight: 300;
    color: ${(p) => (p.today ? Color.vibrantBlue : Color.darkBlue)};
  }
`;

const StyledBooking = styled(Link)`
  display: flex;
  align-items: center;
  padding: ${rem(15)} ${rem(15)};

  .left {
    flex: 1;
  }

  .name {
    font-size: ${rem(14)};
    color: ${(p) => (p.today ? Color.vibrantBlue : Color.faintBlue)};
    margin-bottom: ${rem(Dimen.spacing / 8)};
  }

  .extra {
    font-size: ${rem(12)};
    font-weight: 300;
  }

  .provider {
    width: ${rem(30)};
  }
`;

const StyledEmpty = styled('div')`
  color: ${Color.fainterBlue};
  padding: ${rem(20)} ${rem(15)};
`;
