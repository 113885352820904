// @flow

import type { FieldProp } from 'react-typed-form';

import * as React from 'react';

import { TextInput } from '../components';
import { currencySymbol } from '../util';

type Props = $ReadOnly<{|
  ...React.ElementConfig<typeof TextInput>,
  field: FieldProp<number | null>,
  currency: string,
  /**
   * Default mode uses pennies, but this might not be desired
   */
  wholeNumbers?: boolean,
  hideZero?: boolean,
|}>;

function format(
  val: number | null | void,
  currency: string,
  wholeNumbers: boolean,
  hideZero: boolean
): string {
  if (val == undefined || (val === 0 && hideZero)) {
    return '';
  }

  const symbol = currencySymbol(currency);

  if (wholeNumbers) {
    return `${symbol}${val.toString()}`;
  }

  // extract digits. if no digits, fill in a zero.
  const digits = val.toString().match(/\d/g) || ['0'];

  // zero-pad a one-digit input
  if (digits.length === 1) digits.unshift('0');

  // add a decimal point
  digits.splice(digits.length - 2, 0, '.');

  // make a number with 2 decimal places
  return `${symbol}${Number(digits.join('')).toFixed(2)}`;
}

const FieldMoney = ({
  field,
  currency,
  wholeNumbers = false,
  hideZero = false,
  ...rest
}: Props) => {
  return (
    <TextInput
      label={field.label}
      // Controlled
      value={format(field.value, currency, wholeNumbers, hideZero)}
      onChange={(e) => {
        const value = e.target.value.replace(/[^\d]/g, '');

        if (value === '' || isNaN(value) || !isFinite(value)) {
          field.handleValueChange(null);
          return;
        }

        field.handleValueChange(parseInt(value, 10));
      }}
      disabled={field.isLoading}
      errors={field.lastErrorList}
      {...rest}
    />
  );
};
export default FieldMoney;
