// @flow

import type { FieldProp } from 'react-typed-form';

import { rem } from 'polished';
import * as React from 'react';
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps';
import { compose, withProps } from 'recompose';
import styled from 'styled-components';

import { Dimen } from '../constants';

type Props = {
  fieldLatitude: FieldProp<number>,
  fieldLongitude: FieldProp<number>,
};

const defaultPos = [51.507974, -0.12808]; // Trafalgar Square

class FieldMap extends React.PureComponent<Props> {
  _map: any; // eslint-disable-line flowtype/no-weak-types

  render() {
    const { fieldLatitude, fieldLongitude } = this.props;
    return (
      <GoogleMap
        defaultZoom={17}
        defaultCenter={{
          lat: fieldLatitude.value || defaultPos[0],
          lng: fieldLongitude.value || defaultPos[1],
        }}
        mapTypeId="hybrid"
        onCenterChanged={() => {
          const center = this._map.getCenter();
          fieldLatitude.handleValueChange(center.lat());
          fieldLongitude.handleValueChange(center.lng());
        }}
        options={{
          fullscreenControl: false,
          scrollwheel: false,
          streetViewControl: false,
        }}
        ref={(ref) => (this._map = ref)}
      >
        {/* Debug marker */}
        <Marker
          position={{ lat: fieldLatitude.value, lng: fieldLongitude.value }}
        />
      </GoogleMap>
    );
  }
}

const StyledContainer = styled('div')`
  height: 300px;
  margin-bottom: ${rem(Dimen.spacing / 2)};
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 65px;
    background-image: url(/img/ui/pin.png);
    z-index: 1;
    left: 50%;
    bottom: 50%;
    transform: translateX(-50%);
  }
`;

export default compose(
  // $FlowFixMe
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <StyledContainer />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)(FieldMap);
