// @flow

import { rem } from 'polished';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Dimen } from '../constants';

const Icon = ({ source }: { source: (on: boolean) => string }) => (
  <>
    <div className="icon">
      <img className="on" src={source(true)} />
    </div>
    <div className="icon">
      <img className="off" src={source(false)} />
    </div>
  </>
);

const Arrow = () => (
  <>
    <div className="arrow">
      <img className="on" src={require('../../assets/img/nav/arrow_on.svg')} />
    </div>
    <div className="arrow">
      <img
        className="off"
        src={require('../../assets/img/nav/arrow_off.svg')}
      />
    </div>
  </>
);

const Nav = () => (
  <Container>
    <StyledNavLink exact to="/">
      <Icon
        source={(on) =>
          on
            ? require('../../assets/img/nav/dashboard_on.svg')
            : require('../../assets/img/nav/dashboard_off.svg')
        }
      />
      <span>Dashboard</span>
      <Arrow />
    </StyledNavLink>

    <StyledSeparator>Features</StyledSeparator>
    <StyledNavLink to="/channels">
      <Icon
        source={(on) =>
          on
            ? require('../../assets/img/nav/settings_on.svg')
            : require('../../assets/img/nav/settings_off.svg')
        }
      />
      <span>Channels</span>
      <Arrow />
    </StyledNavLink>
    <StyledNavLink to="/calendar">
      <Icon
        source={(on) =>
          on
            ? require('../../assets/img/nav/calendar_on.svg')
            : require('../../assets/img/nav/calendar_off.svg')
        }
      />
      <span>Calendar</span>
      <Arrow />
    </StyledNavLink>
    <StyledNavLink to="/properties">
      <Icon
        source={(on) =>
          on
            ? require('../../assets/img/nav/properties_on.svg')
            : require('../../assets/img/nav/properties_off.svg')
        }
      />
      <span>Listings</span>
      <Arrow />
    </StyledNavLink>
    <StyledNavLink to="/services" hasSub>
      <Icon
        source={(on) =>
          on
            ? require('../../assets/img/nav/services_on.svg')
            : require('../../assets/img/nav/services_off.svg')
        }
      />
      <span>Services</span>
      <Arrow />
    </StyledNavLink>
    <div className="show-on-active">
      <StyledNavLink sub to="/services/guest">
        <span>Guest Services</span>
      </StyledNavLink>
      <StyledNavLink sub to="/services/host-assist">
        <span>Host Assist</span>
      </StyledNavLink>
    </div>
    <StyledNavLink to="/profile">
      <Icon
        source={(on) =>
          on
            ? require('../../assets/img/nav/profile_on.svg')
            : require('../../assets/img/nav/profile_off.svg')
        }
      />
      <span>Profile</span>
      <Arrow />
    </StyledNavLink>
    <StyledNavLink to="/analytics">
      <Icon
        source={(on) =>
          on
            ? require('../../assets/img/nav/analytics_on.svg')
            : require('../../assets/img/nav/analytics_off.svg')
        }
      />
      <span>Analytics</span>
      <Arrow />
    </StyledNavLink>
    <StyledNavLink to="/settings">
      <Icon
        source={(on) =>
          on
            ? require('../../assets/img/nav/settings_on.svg')
            : require('../../assets/img/nav/settings_off.svg')
        }
      />
      <span>Settings</span>
      <Arrow />
    </StyledNavLink>
  </Container>
);

export default Nav;

const Container = styled('div')`
  width: ${rem(Dimen.hostWebLayoutPanelWidth)};
  background-color: #18252e;
  color: #a9a9a9;

  .show-on-active {
    display: none;
    position: relative;
    margin: ${rem(-10)} 0 0;
  }

  .active + .show-on-active {
    display: block;
  }
`;

const StyledSeparator = styled('span')`
  display: block;
  color: #fff;
  font-size: ${rem(14)};
  text-transform: uppercase;
  margin: ${rem(36)} 0 ${rem(20)} ${rem(32)};
`;

// eslint-disable-next-line no-unused-vars
const StyledNavLink = styled(({ sub, hasSub, ...rest }) =>
  React.createElement(NavLink, rest)
).attrs({
  activeClassName: 'active',
})`
  display: flex;
  height: ${(p) => rem(p.sub ? 28 : 32)};
  align-items: center;
  transition: color 0.25s;
  margin: ${(p) => rem(p.sub ? 0 : 10)} ${rem(20)} ${(p) => rem(p.sub ? 0 : 10)}
    ${rem(32)};
  position: relative;
  font-weight: 300;

  &:hover {
    color: #fff;
  }

  .icon {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: ${rem(18)};
    display: flex;
    align-items: center;

    img {
      width: ${rem(18)};
    }
  }

  .arrow {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: ${rem(5)};
    display: flex;
    align-items: center;

    img {
      width: ${rem(5)};
      transition: transform 0.25s;
    }
  }

  &.active {
    color: #ee4640;
    .off {
      opacity: 0;
    }

    .arrow img {
      ${(p) => p.hasSub && `transform: rotate(90deg)`};
    }
  }

  &:not(.active) {
    .on {
      opacity: 0;
    }
  }

  span {
    margin-left: ${rem(40)};
  }
`;
