// @flow

import * as React from 'react';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  onValueChange: (value: boolean) => mixed,
  value: boolean | null,
  disabled?: boolean,
  className?: string,
|}>;

const switchWidth = 48;
const buttonSize = 30;
const onOffset = switchWidth - buttonSize;

/**
 * A controlled on/off switch
 */
const Switch = ({ onValueChange, value, disabled, className }: Props) => (
  <StyledTarget
    className={className}
    disabled={disabled}
    onClick={
      disabled
        ? undefined
        : () => {
            onValueChange(!value);
          }
    }
  >
    <StyledSwitch $on={!!value}>
      <StyledButton $on={!!value} $null={value === null} />
    </StyledSwitch>
  </StyledTarget>
);

export default Switch;

const offColor = '#D7DEE9';

const StyledTarget = styled('a')`
  height: 42px;
  display: flex;
  align-items: center;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
  transition: opacity 0.25s;
`;

const StyledSwitch = styled('div')`
  width: ${switchWidth}px;
  height: ${buttonSize}px;
  position: relative;
  border: 1px solid ${offColor};
  border-radius: ${buttonSize / 2}px;
  transition: all 0.25s;

  ${(p) => p.$on && `border-color: #3389ff55`};

  &:hover {
    opacity: 0.8;
  }
`;

const StyledButton = styled('div')`
  position: absolute;
  left: 0;
  width: ${buttonSize}px;
  height: ${buttonSize}px;
  border-radius: 50%;
  background-color: ${offColor};
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/img/ui/switch_${(p) => (p.$on ? 'on' : 'off')}.png);
  background-size: ${(p) => (p.$on ? '14px 11px' : '10px 10px')};
  transition: all 0.25s;

  ${(p) => p.$null && `opacity: 0`};

  ${(p) =>
    p.$on &&
    `
    background-color: #3389ff;
    left: ${onOffset}px;
  `};
`;
