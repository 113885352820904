// @flow

import type { Id } from '../../../types';

import { format, parseISO } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Avatar } from '../../../components';
import { Color, Dimen } from '../../../constants';

type Props = $ReadOnly<{|
  booking: {
    id: Id,
    name: string,
    endDate: string,
    startDate: string,
  },
  otherUser: {
    firstName: string,
    lastName: string,
    avatarImage: {
      path: string,
    },
  },
  onSelectId: (Id | null) => mixed,
  unreadCount?: number,
|}>;

export default function ListThread({
  booking,
  otherUser,
  onSelectId,
  unreadCount,
}: Props) {
  return (
    <StyledContainer
      onClick={() => onSelectId(booking.id)}
      unread={unreadCount && unreadCount > 0 ? unreadCount : null}
    >
      <Avatar size="small" user={{ avatarImage: otherUser.avatarImage }} />
      <StyledAreaMain>
        <span>
          {otherUser.firstName} {otherUser.lastName}
        </span>
        <strong>{booking.name}</strong>
        {format(parseISO(booking.startDate), 'd')}-
        {format(parseISO(booking.endDate), 'd MMMM y')}
      </StyledAreaMain>
      {unreadCount && unreadCount > 0 ? (
        <StyledUnreadCount>{unreadCount}</StyledUnreadCount>
      ) : null}
    </StyledContainer>
  );
}

const StyledContainer = styled('a')`
  display: flex;
  align-items: center;
  padding: ${rem(Dimen.spacing / 2)};
  padding-left: ${rem(Dimen.spacing)};
  border-bottom: 1px #d2dae4 solid;
  transition: background-color 0.25s;
  ${(p) => p.unread && 'background-color: #fff'};

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

const StyledAreaMain = styled('div')`
  flex: 1;
  font-weight: 300;
  font-size: ${rem(12)};
  margin-left: ${rem(Dimen.spacing / 2)};

  span {
    font-weight: 400;
    color: #849bba;
    font-size: ${rem(14)};
  }

  strong {
    display: block;
  }
`;

const StyledUnreadCount = styled('div')`
  height: ${rem(22)};
  min-width: ${rem(14)};
  padding: 0 ${rem(4)};
  text-align: center;
  line-height: ${rem(22)};
  border-radius: ${rem(11)};
  background-color: ${Color.vibrantBlue};
  color: #fff;
`;
