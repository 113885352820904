// @flow

import { capitalize } from '../../util';

export type HomeGuideType =
  | 'appliance'
  | 'entertainment'
  | 'heating'
  | 'security'
  | 'bins'
  | 'other';

export const HOME_GUIDE_TYPES: HomeGuideType[] = [
  'appliance',
  'entertainment',
  'heating',
  'security',
  'bins',
  'other',
];

export function typeToTitle(type: HomeGuideType): string {
  if (type === 'appliance') return 'Appliances';
  if (type === 'heating') return 'Heating and AC';
  if (type === 'security') return 'Home Security';
  if (type === 'bins') return 'Bins / Waste';
  return capitalize(type);
}
