// @flow

import { rem, transparentize } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Color, Dimen } from '../constants';

type Props = $ReadOnly<{|
  title: string,
  imageSrc?: string,
  description: string,
  completed?: boolean,
|}>;

const HelpStep = ({ title, imageSrc, description, completed }: Props) => (
  <StyledContainer>
    <strong>{title}</strong>
    <div>
      <img src={imageSrc} />
      {completed && (
        <div className="completed">
          <img src={require('../../assets/img/ui/help_step_tick.svg')} />
        </div>
      )}
      <p>{description}</p>
    </div>
  </StyledContainer>
);

export default HelpStep;

const StyledContainer = styled('div')`
  margin: 0 ${rem(Dimen.spacing / 2)};
  color: #fff;

  > strong {
    display: block;
    margin-bottom: ${rem(Dimen.spacing / 2)};
  }

  > div {
    display: flex;
    align-items: top;
    position: relative;

    > img {
      width: ${rem(140)};
      height: ${rem(140)};
      background-color: ${Color.imagePlaceholderBg};
      margin-right: ${rem(Dimen.spacing / 2)};
    }

    .completed {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: ${rem(140)};
      height: ${rem(140)};
      background-color: ${transparentize(0.2, Color.darkBlue)};
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: ${rem(30)};
      }
    }

    p {
      width: ${rem(140)};
      font-weight: 300;
      font-size: ${rem(12)};
      margin: 0;
    }
  }
`;
