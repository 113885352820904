// @flow

import { GHText } from '@guesthug/core/components';
import { endOfYear, formatISO, parseISO, startOfYear } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import ErrorList from './ErrorList';

type Props = {
  // yyyy-MM-dd format
  value: string | null,
  disabled?: boolean,
  onChange: (string | null) => mixed,
  label?: string | null,
  errors?: string[],
  inputWidth?: number,
};

export default function DatePicker({
  value,
  disabled,
  onChange,
  label,
  errors,
  inputWidth,
}: Props) {
  const yearStart = formatISO(
    startOfYear(value ? parseISO(value) : Date.now()),
    { representation: 'date' }
  );
  const yearEnd = formatISO(endOfYear(value ? parseISO(value) : Date.now()), {
    representation: 'date',
  });

  return (
    <View>
      {label && (
        <View style={styles.labelWrap}>
          <GHText size="small" weight="light">
            {label}
          </GHText>
        </View>
      )}
      <input
        value={value ?? null}
        type="date"
        onChange={(ev) => {
          onChange(ev.target.value || null);
        }}
        min={yearStart}
        max={yearEnd}
        disabled={disabled}
        style={{
          display: 'block',
          boxSizing: 'border-box',
          backgroundColor: '#edf1f4',
          width: inputWidth ?? '100%',
          minHeight: 40,
          borderRadius: 3,
          border: 0,
          padding: rem(20),
          fontSize: rem(20),
          fontWeight: 300,
          color: disabled ? '#999' : undefined,
        }}
      />
      <ErrorList errors={errors} />
    </View>
  );
}

const styles = StyleSheet.create({
  labelWrap: {
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
});
