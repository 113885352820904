// @flow

import type { Action } from './types';

import { format } from 'date-fns';

export const flashShow = (message: string): Action => ({
  type: 'FLASH_SHOW',
  payload: {
    key: new Date().getTime().toString(),
    message,
    firedAt: format(new Date(), 't'),
  },
});
