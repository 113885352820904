// @flow

import { ellipsis, lighten, rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  isLoading: boolean,
  normalLabel?: string,
  loadingLabel?: string,
  forceDisabled?: boolean,
  className?: string,
  noMargin?: boolean,

  // Kind of hacky stuff
  onClick?: () => mixed,
|}>;

const SubmitButton = ({
  isLoading,
  normalLabel = 'Save',
  loadingLabel = 'Saving',
  forceDisabled,
  noMargin,
  ...rest
}: Props) => (
  <StyledButton
    type="submit"
    disabled={isLoading || forceDisabled}
    $noMargin={noMargin}
    {...rest}
  >
    {isLoading ? loadingLabel : normalLabel}
  </StyledButton>
);

export default SubmitButton;

const StyledButton = styled('button')`
  margin-top: ${(p) => (p.$noMargin ? 0 : rem(25))};

  display: block;
  height: ${rem(40)};
  line-height: ${rem(40)};
  border: 0;
  border-radius: ${rem(20)};
  font-size: ${rem(16)};
  font-weight: bold;
  color: #fff;
  ${ellipsis()};
  padding: 0 ${rem(60)};
  background-color: ${lighten(0.1, '#3389ff')};
  outline: 0;
  transition: background-color 0.25s;

  &:not([disabled]) {
    background-color: #3389ff;
    cursor: pointer;
    &:hover {
      background-color: ${lighten(0.05, '#3389ff')};
    }
  }
`;
