// @flow

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router-dom';

import { APP_ENV } from '../config';

type Props = {
  location: Location,
};

class AnalyticsTracker extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (typeof window === 'undefined' || typeof window.ga === 'undefined') {
      return;
    }

    const currentPage = prevProps.location.pathname;
    const nextPage = this.props.location.pathname;

    if (currentPage !== nextPage) {
      window.ga('send', 'pageview');
    }
  }

  render() {
    if (APP_ENV !== 'production') {
      return null;
    }

    return (
      <Helmet>
        <script>{`
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', 'UA-127277468-2', 'auto');
        ga('send', 'pageview');
      `}</script>
      </Helmet>
    );
  }
}

export default withRouter(AnalyticsTracker);
