// @flow

import type { Id } from '../../../types';

import { Api } from '@guesthug/core';
import { rem } from 'polished';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ContentLoading, Modal, ScreenError } from '../../../components';
import { Dimen } from '../../../constants';
import MessagingList from './MessagingList';
import MessagingThread from './MessagingThread';
import RecipientModal from './RecipientModal';

type Props = $ReadOnly<{|
  userId: Id,
  visible: boolean,
|}>;

function MessagingRouterInner({ userId }: Props) {
  const [recipientModalVisible, setRecipientModalVisible] =
    React.useState<boolean>(false);
  const [selectedBooking, setSelectedBooking] = React.useState<Id | null>(null);
  const { messagingLastRead } = useSelector((state) => state);

  const apiHost = Api.User.useReadItemMessagesAsHost(userId, {
    staleWhileInvalidated: true,
    invalidateAge: 10,
  });
  const apiGuest = Api.User.useReadItemMessagesAsGuest(userId, {
    staleWhileInvalidated: true,
    invalidateAge: 10,
  });

  if (apiHost.error) return <ScreenError {...apiHost} />;
  if (apiGuest.error) return <ScreenError {...apiGuest} />;
  if (!apiHost.data || !apiGuest.data) return <ContentLoading />;

  const hostMessages = apiHost.data;
  const guestMessages = apiGuest.data;

  return (
    <>
      <TopBar>
        <img src={require('../../../../assets/img/messaging/top_icon.svg')} />
        <span>Messages</span>
        <a onClick={() => setRecipientModalVisible(true)}>
          <img src={require('../../../../assets/img/messaging/top_new.svg')} />
        </a>
      </TopBar>
      {selectedBooking ? (
        <MessagingThread
          bookingId={selectedBooking}
          userId={userId}
          onSelectId={(id) => setSelectedBooking(id)}
        />
      ) : (
        <MessagingList
          userId={userId}
          onSelectId={(id) => setSelectedBooking(id)}
          lastReadByBooking={messagingLastRead.byBooking}
          hostMessages={hostMessages}
          guestMessages={guestMessages}
        />
      )}
      {recipientModalVisible && (
        <Modal
          title="Select Message Recipient"
          onDismiss={() => setRecipientModalVisible(false)}
        >
          <RecipientModal
            hostMessages={hostMessages}
            guestMessages={guestMessages}
            userId={userId}
            onSelectId={(id) => {
              setRecipientModalVisible(false);
              setSelectedBooking(id);
            }}
          />
        </Modal>
      )}
    </>
  );
}

export default function MessagingRouter(props: Props) {
  return (
    <StyledContainer $visible={props.visible}>
      <MessagingRouterInner {...props} />
    </StyledContainer>
  );
}

const StyledContainer = styled('div')`
  position: absolute;
  top: ${rem(Dimen.hostWebHeaderHeight)};
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: ${rem(Dimen.hostWebLayoutPanelWidth)};
  background-color: #e2e7ec;
  transform: translateX(${(p) => (p.$visible ? 0 : 300)}px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  transition: transform 0.25s;
`;

const TopBar = styled('div')`
  display: flex;
  align-items: center;
  height: ${rem(50)};
  background-color: #19252e;
  color: #a9a9a9;
  font-size: ${rem(16)};
  font-weight: 300;
  padding: 0 ${rem(15)} 0 ${rem(Dimen.spacing)};

  img {
    display: block;
    width: ${rem(18)};
  }

  a {
    padding: ${rem(5)};
  }

  span {
    flex: 1;
    padding: 0 ${rem(Dimen.spacing / 2)};
  }
`;
