// @flow

import type { RootState } from '../types';

import * as Sentry from '@sentry/browser';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { LoginPage, OAuthLoginPage } from '../pods/login';
import { RegisterPage } from '../pods/register';
import AppAuthed from './AppAuthed';
import LayoutExternal from './LayoutExternal';

export default function App() {
  const { user } = useSelector<RootState, _>((state) => state.auth);

  React.useEffect(() => {
    Sentry.setUser(user && { email: user.email });
  }, [user]);

  if (!user) {
    return (
      <LayoutExternal>
        <Switch>
          <Route path="/" exact component={LoginPage} />
          <Route path="/register" exact component={RegisterPage} />
          <Route path="/oauth-login" exact component={OAuthLoginPage} />
          <Redirect to="/" />
        </Switch>
      </LayoutExternal>
    );
  } else {
    return <AppAuthed />;
  }
}
