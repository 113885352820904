// @flow

import { Api } from '@guesthug/core';
import * as React from 'react';

import { MidTitleLink, Stars } from '../../components';
import DashboardBox from './DashboardBox';

type Props = $ReadOnly<{|
  bookings: Array<Api.Booking.V_Dashboard>,
|}>;

export default function BoxPopularProperty({ bookings }: Props) {
  const mostPopular = bookings.reduce(
    (carry, booking) => {
      const ratings = bookings.map((b) => b.ratingOverall).filter(Boolean);
      const averageRating = ratings.length
        ? ratings.reduce((c, r) => c + r, 0)
        : 0;
      return averageRating > carry.averageRating
        ? { averageRating, booking }
        : carry;
    },
    { averageRating: 0, booking: null }
  );

  const { averageRating, booking } = mostPopular;
  return (
    <DashboardBox title="Most Popular Listing" padded>
      <div>
        <MidTitleLink
          to={booking ? booking.property['@id'] : '/properties'}
          singleLine
        >
          {booking ? booking.property.name : '-'}
        </MidTitleLink>
      </div>
      {booking && <Stars count={averageRating} />}
    </DashboardBox>
  );
}
