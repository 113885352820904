// @flow

import { lighten, rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Dimen } from '../constants';

type Props = $ReadOnly<{|
  label: string,
  to?: string,
  onClick?: () => mixed,
  plusEffect?: boolean,
|}>;

const AddNewTile = ({ label, to, onClick, plusEffect }: Props) => (
  <StyledAddNew to={to} onClick={onClick} plusEffect={plusEffect}>
    <img src={require('../../assets/img/ui/add_white.svg')} />
    <span>{label}</span>
  </StyledAddNew>
);

export default AddNewTile;

// eslint-disable-next-line no-unused-vars
const StyledAddNew = styled(({ plusEffect, ...props }) =>
  React.createElement(props.to ? Link : 'a', props)
)`
  background-color: #3389ff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: ${rem(20)};
  border-radius: 3px;
  transition: background-color 0.25s;
  box-sizing: border-box;
  padding: ${rem(Dimen.spacing)};

  img {
    width: ${rem(18)};
    transition: all 0.25s;
    margin-right: ${rem(10)};
    opacity: 0;
    transform: translateX(${rem(14)});
  }

  span {
    transition: transform 0.25s;
    transform: translateX(${rem(-14)});
    text-align: center;
  }

  &:hover {
    background-color: ${lighten(0.05, '#3389ff')};

    ${(p) =>
      p.plusEffect &&
      `
      img {
        opacity: 1;
        transform: translateX(0);
      }

      span {
        transform: translateX(0);
      }
    `};
  }
`;
