// @flow

import { Api } from '@guesthug/core';
import { useApiCallable } from '@guesthug/core';
import { rem } from 'polished';
import * as React from 'react';
import { FormComponent } from 'react-typed-form';
import styled from 'styled-components';

import { Expander } from '../../../components';
import { Dimen } from '../../../constants';
import {
  FieldInput,
  FormWrap,
  SubmitButton,
  createFormValidator,
} from '../../../form';
import ImportedCalendarRow from './ImportedCalendarRow';

type Props = $ReadOnly<{|
  property: Api.Property.V_Full,
  complete: boolean,
  invalidate: () => void,
|}>;

export default function SectionImport({
  property,
  complete,
  invalidate,
}: Props) {
  const callApi = useApiCallable();
  const { calendars } = property;

  return (
    <Expander
      title="Add iCal Link"
      complete={complete}
      noPadding
      expandOnUrlHash="import"
    >
      <p>
        Paste iCal link here to connect to other calendars. Note this a
        'one-way' static connection and cannot be edited in GuestHug.
      </p>
      <FormComponent
        validator={createFormValidator({
          url: {
            strictPresence: { mustDefine: true },
            calendarUrl: true,
          },
        })}
        onSubmit={async (values, { addSubmitError, setLoading, getField }) => {
          setLoading(true);
          const response = await callApi('/calendars', {
            method: 'POST',
            jsonBody: {
              property: property['@id'],
              ...values,
            },
          });
          if (response) {
            invalidate();
          }
          setLoading(false);
        }}
      >
        {({ getField, handleSubmit, isLoading }) => (
          <StyledFormWrap handleSubmit={handleSubmit}>
            <FieldInput field={getField('url')} />
            <SubmitButton isLoading={isLoading} normalLabel="Add" />
          </StyledFormWrap>
        )}
      </FormComponent>
      {calendars
        .sort((a, b) =>
          a && b ? a.provider.name.localeCompare(b.provider.name) : 0
        )
        .map(
          (calendar) =>
            calendar && (
              <ImportedCalendarRow
                key={calendar['@id']}
                calendar={calendar}
                property={property}
                invalidate={invalidate}
              />
            )
        )}
    </Expander>
  );
}

const StyledFormWrap = styled(FormWrap)`
  padding: ${rem(Dimen.spacing)};
  padding-top: 0;
`;
