// @flow

import { BookingBar } from '@guesthug/core/components';
import * as React from 'react';

import { PressableLink, ProviderIcon } from '../../../components';

type Props = $ReadOnly<{|
  ...$Diff<React.ElementConfig<typeof BookingBar>, { renderProviderIcon: any }>,
  onHoverIn?: () => mixed,
  onHoverOut?: () => mixed,
|}>;

export default function LinkedBookingBar({
  onHoverIn,
  onHoverOut,
  isHovered,
  top,
  left,
  ...barProps
}: Props) {
  /* if (barProps.deleted) {
    return (
      <View style={{ position: 'absolute', top, left }}>
        <BookingBar
          renderProviderIcon={(props) => <ProviderIcon {...props} />}
          {...barProps}
        />
      </View>
    );
  } */

  return (
    <PressableLink
      to={`/calendar/${barProps.propertyId}/${barProps.bookingId}`}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      style={{ position: 'absolute', top, left }}
    >
      {({ hovered }) => (
        <BookingBar
          isHovered={isHovered ?? hovered}
          renderProviderIcon={(props) => <ProviderIcon {...props} />}
          {...barProps}
        />
      )}
    </PressableLink>
  );
}
