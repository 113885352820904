// @flow

import { rem } from 'polished';
import styled from 'styled-components';

export default styled('div')`
  background-color: #f2f2f3;
  border-radius: ${rem(4)};
  width: 100%;
  max-width: ${rem(410)};
  margin: auto;
`;
