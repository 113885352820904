// @flow

import { Api } from '@guesthug/core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ContentLoading, ScreenError } from '../components';
import { useAuthUser } from '../hooks';
import { AnalyticsPage } from '../pods/analytics';
import * as Calendar from '../pods/calendar';
import * as Channels from '../pods/channels';
import { DashboardPage } from '../pods/dashboard';
import {
  HomeGuideAddPage,
  HomeGuideEditPage,
  HomeGuideListPage,
  HomeGuideWifiPage,
} from '../pods/home-guide';
import {
  LocalGuideAddPage,
  LocalGuideEditPage,
  LocalGuideListPage,
} from '../pods/local-guide';
import { EditProfilePage } from '../pods/profile';
import * as Properties from '../pods/properties';
import { SearchPage } from '../pods/search';
import {
  ServiceAddPage,
  ServiceEditPage,
  ServicesGuestPage,
  ServicesHostAssistPage,
} from '../pods/services';
import {
  MessageTemplateAddPage,
  MessageTemplateEditPage,
  SettingsPage,
} from '../pods/settings';
import { SelectPlanPage } from '../pods/subscription';
import Layout from './Layout';
import SelectModePage from './SelectModePage';
import UseAppPage from './UseAppPage';

export default function AppAuthed() {
  const authUser = useAuthUser();
  const { mode, dismissedSelectPlan } = useSelector((state) => state.options);

  const api = Api.User.useReadItemFull(authUser.id);

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ContentLoading />;

  const user = api.data;

  if (mode === null) {
    return <SelectModePage />;
  }

  if (mode === 'guest') {
    return (
      <UseAppPage
        message="The GuestHug web portal is currently only for Hosts. To continue as a Guest, please use our mobile app:"
        showSwitchToHost
      />
    );
  }

  if (!dismissedSelectPlan && !user.activeSubscription) {
    return <SelectPlanPage />;
  }

  return (
    <Layout user={user}>
      <Switch>
        <Route path="/" exact component={DashboardPage} />
        <Route path="/analytics" exact component={AnalyticsPage} />
        <Route
          path="/calendar"
          exact
          component={Calendar.MultiCalendarScreen}
        />
        <Route
          path="/calendar/:id/:rangeStart~:rangeEnd"
          component={Calendar.SingleCalendarScreen}
        />
        <Route
          path="/calendar/:id/:bookingId?"
          component={Calendar.SingleCalendarScreen}
        />
        <Route path="/channels" exact component={Channels.ChannelsScreen} />
        <Route path="/profile" exact component={EditProfilePage} />
        <Route
          path="/properties"
          exact
          component={Properties.PropertiesTablePage}
        />
        <Route
          path="/properties/list"
          exact
          component={Properties.PropertiesTilesPage}
        />
        <Route
          path="/properties/:id"
          exact
          component={Properties.PropertyPage}
        />
        <Route
          path="/properties/:propertyId/home-guide"
          exact
          component={HomeGuideListPage}
        />
        <Route
          path="/properties/:propertyId/home-guide/add/:type"
          exact
          component={HomeGuideAddPage}
        />
        <Route
          path="/properties/:propertyId/home-guide/:id"
          exact
          component={HomeGuideEditPage}
        />
        <Route
          path="/properties/:propertyId/local-guide"
          exact
          component={LocalGuideListPage}
        />
        <Route
          path="/properties/:propertyId/local-guide/add/:type"
          exact
          component={LocalGuideAddPage}
        />
        <Route
          path="/properties/:propertyId/local-guide/:id"
          exact
          component={LocalGuideEditPage}
        />
        <Route
          path="/properties/:propertyId/wifi"
          exact
          component={HomeGuideWifiPage}
        />
        <Route path="/search/:query" component={SearchPage} />
        <Route path="/services/guest" exact component={ServicesGuestPage} />
        <Route path="/services/guest/add" exact component={ServiceAddPage} />
        <Route path="/services/guest/:id" component={ServiceEditPage} />
        <Route
          path="/services/host-assist"
          exact
          component={ServicesHostAssistPage}
        />
        <Redirect from="/services" to="/services/guest" exact />
        <Route path="/settings" exact component={SettingsPage} />
        <Route
          path="/settings/message-templates/add"
          exact
          component={MessageTemplateAddPage}
        />
        <Route
          path="/settings/message-templates/:id"
          exact
          component={MessageTemplateEditPage}
        />

        <Redirect from="/login" to="/" />
        <Redirect from="/oauth-login" to="/" />
        <Redirect from="/register" to="/" />
        <Redirect
          from="/subscription/success"
          to="/settings#subscription"
          exact
        />
        <Redirect
          from="/subscription/updated"
          to="/settings#subscription"
          exact
        />
      </Switch>
    </Layout>
  );
}
