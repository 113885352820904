// @flow

import { Color, Style } from '@guesthug/core';
import { GHText, PaddedArea, Spacer } from '@guesthug/core/components';
import { useApiRead } from 'api-read-hook';
import { format, parseISO } from 'date-fns';
import * as React from 'react';
import { View } from 'react-native';

import { ContentLoading, ScreenError } from '../../../components';
import PaneSelectedRangeLoaded from './PaneSelectedRangeLoaded';

type Props = $ReadOnly<{|
  start: string,
  end: string,
  propertyId: string,
  mode: 'minNights' | 'rate',
  invalidate: () => mixed,
|}>;

export default function PaneSelectedRange({
  start,
  end,
  propertyId,
  mode,
  invalidate,
}: Props) {
  const startDate = parseISO(start);
  const endDate = parseISO(end);

  const api = useApiRead(
    `/property-calendars?from=${start}&to=${end}&propertyId=${propertyId}`
  );
  if (api.error) return <ScreenError {...api} />;

  return (
    <View>
      <PaddedArea all style={{ backgroundColor: Color.darkBlue }}>
        <GHText color="invertNormal" size="larger">
          Selected Dates
        </GHText>
        <Spacer size={0.5} />
        <View style={Style.row}>
          <GHText size="small" color="faint" style={{ width: 50 }}>
            Start
          </GHText>
          <GHText size="small" color="invertNormal">
            {format(startDate, 'EEEE do MMMM y')}
          </GHText>
        </View>
        <Spacer size={0.5} />
        <View style={Style.row}>
          <GHText size="small" color="faint" style={{ width: 50 }}>
            End
          </GHText>
          <GHText size="small" color="invertNormal">
            {format(endDate, 'EEEE do MMMM y')}
          </GHText>
        </View>
      </PaddedArea>
      {api.data ? (
        <PaneSelectedRangeLoaded
          start={start}
          end={end}
          propertyCalendar={api.data[0]}
          invalidate={() => {
            invalidate();
            api.invalidate();
          }}
          mode={mode}
        />
      ) : (
        <ContentLoading />
      )}
    </View>
  );
}
