// @flow

import type { Dispatch } from '../types';

import { ghApiRequest } from '@guesthug/core';
import { lighten, rem } from 'polished';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Dimen } from '../constants';
import { SubmitButton } from '../form';
import { useAuthUser } from '../hooks';
import { logError } from '../util';
import ExternalPanel from './ExternalPanel';
import LayoutExternal from './LayoutExternal';

export default function SelectModePage() {
  const authUser = useAuthUser();
  const dispatch = useDispatch<Dispatch>();

  async function selectMode(mode: 'guest' | 'host'): Promise<void> {
    dispatch({ type: 'SET_OPTION_MODE', payload: mode });

    try {
      await ghApiRequest(`/users/${authUser.id}`, {
        method: 'PUT',
        jwt: authUser.jwt,
        jsonBody: { onboardModeSelection: mode },
      });
    } catch (err) {
      // Errors aren't important here.  Log it, but don't interrupt the user.
      logError(err, 'SelectModePage');
    }
  }

  return (
    <LayoutExternal>
      <ExternalPanel>
        <StyledAreaTop>
          <img src={require('../../assets/img/ui/logo_glyph_white.svg')} />
          <p>Choose your user profile to finish up</p>
        </StyledAreaTop>
        <StyledAreaBottom>
          <StyledSubmitButton
            isLoading={false}
            normalLabel="Continue as Guest"
            onClick={() => selectMode('guest')}
          />
          <StyledSubmitButton
            isLoading={false}
            normalLabel="Continue as Host"
            onClick={() => selectMode('host')}
          />
        </StyledAreaBottom>
      </ExternalPanel>
    </LayoutExternal>
  );
}

const StyledAreaTop = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: ${rem(Dimen.spacing * 3)};
  padding-bottom: ${rem(Dimen.spacing * 2)};
  border-top-left-radius: ${rem(4)};
  border-top-right-radius: ${rem(4)};
  background-color: #ee4641;
  color: #fff;
  background-image: url('/img/ui/mode_bg.jpg');
  background-size: cover;
  background-position: center center;

  p {
    font-size: ${rem(26)};
    max-width: ${rem(260)};
    text-align: center;
    font-weight: 300;
  }

  img {
    width: ${rem(60)};
    margin-bottom: ${rem(Dimen.spacing)};
  }
`;

const StyledAreaBottom = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: ${rem(Dimen.spacing)} ${rem(Dimen.spacing * 2)};
`;

const StyledSubmitButton = styled(SubmitButton)`
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-top: ${rem(Dimen.spacing / 2)};
    background-color: #253745;
    &:hover {
      background-color: ${lighten(0.05, '#253745')};
    }
  }
`;
