// @flow

import type { Id } from '../../../types';

import { Api, Style, useApiCallable } from '@guesthug/core';
import { GHText, PaddedArea, Spacer } from '@guesthug/core/components';
import * as React from 'react';
import { View } from 'react-native';
import { useForm } from 'react-typed-form';

import { Button, ContentLoading } from '../../../components';
import { FieldSelect, createFormValidator } from '../../../form';

type Props = $ReadOnly<{|
  propertyId: Id,
|}>;

export default function CopyBookingSettingsForm({ propertyId }: Props) {
  const callApi = useApiCallable();

  const form = useForm<any>({
    defaultValues: {},
    validator: createFormValidator({
      propertyId: {
        strictPresence: {
          mustDefine: true,
          message: '^You must select a listing',
        },
      },
    }),
    onSubmit: async (values, { setLoading }) => {
      if (
        confirm(
          'Are you sure you want to import?  This will overwrite all your current Booking Settings.'
        )
      ) {
        setLoading(true);
        await callApi(
          `/copy-property-booking-settings/${values.propertyId}/${propertyId}`,
          {
            method: 'POST',
            jsonBody: values,
          }
        );
        window.location.reload();
      }
    },
  });

  const api = Api.Property.useReadCollectionMine();
  if (api.error) return 'Error!';
  if (!api.data) return <ContentLoading />;

  const copyableProperties = api.data['hydra:member']
    .filter((p) => p.minNights && p.id !== propertyId)
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

  return (
    <PaddedArea bottom style={Style.alignStart}>
      <GHText color="faint">
        Select one of your other properties below to copy its Booking Settings.
        This will overwrite all your current Booking Settings.
      </GHText>
      <Spacer size={0.5} />

      <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
        <View style={{ width: 340 }}>
          <FieldSelect
            field={{
              ...form.getField('propertyId'),
              label: 'Property',
            }}
            choices={copyableProperties.map((copyableProperty) => ({
              value: copyableProperty.id,
              label: copyableProperty.name,
            }))}
            noMargin
          />
        </View>
        <Spacer />

        <View style={{ marginTop: 40 }}>
          <Button
            disabled={form.isLoading}
            label="Copy"
            onPress={() => form.handleSubmit()}
          />
        </View>
      </View>
    </PaddedArea>
  );
}
