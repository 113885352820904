/**
 * @flow
 */

import type { UserState } from '../reducers/auth';
import type { RootState } from '../types';

export const authUserSelector = (state: RootState): UserState => {
  const authUser = state.auth.user;
  if (!authUser) {
    throw new Error(
      'authUserSelector should only be called within a navigator which guarantees auth'
    );
  }
  return authUser;
};
