// @flow

import type { Dispatch, RootState } from '../../types';

import { ghApiRequest } from '@guesthug/core';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Dimen } from '../../constants';
import { authUserSelector } from '../../selectors/user';
import ResultsCol from './ResultsCol';

type OwnProps = $ReadOnly<{|
  match: { params: { query: string } },
|}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  jwt: string,
  dispatch: Dispatch,
|}>;

type State = {|
  data: null | Object, // eslint-disable-line flowtype/no-weak-types
|};

class SearchPage extends React.PureComponent<Props, State> {
  state = {
    data: null,
  };

  componentDidMount() {
    const { query } = this.props.match.params;
    this._handleQuery(query);
  }

  componentDidUpdate(prevProps: Props) {
    const { query } = this.props.match.params;
    if (query !== prevProps.match.params.query) {
      this._handleQuery(query);
    }
  }

  async _handleQuery(query: string) {
    const { jwt } = this.props;

    this.setState({ data: null });
    const { data } = await ghApiRequest(`/search/${query}`, { jwt });
    this.setState({ data });
  }

  render() {
    const { data } = this.state;

    return (
      <StyledCols>
        <ResultsCol
          type="Property"
          flatResults={data && data.results.Property}
        />
        <ResultsCol type="Service" flatResults={data && data.results.Service} />
        <ResultsCol type="Booking" flatResults={data && data.results.Booking} />
        <ResultsCol type="Message" flatResults={data && data.results.Message} />
      </StyledCols>
    );
  }
}

export default connect<Props, OwnProps, _, _, _, _>((state: RootState) => ({
  jwt: authUserSelector(state).jwt,
}))(SearchPage);

const StyledCols = styled('div')`
  display: flex;
  align-items: flex-start;
  margin: 0 -${rem(Dimen.spacing / 4)};
`;
