// @flow

import type { $Optional, FormObject } from 'react-typed-form';

import { Api } from '@guesthug/core';
import * as React from 'react';
import { UntypedFormComponent } from 'react-typed-form';

import { ContentBox } from '../../components';
import {
  FieldInput,
  FormWrap,
  SubmitButton,
  createFormValidator,
} from '../../form';

type FT = $Optional<Api.User.MessageTemplate>;

type Props = $ReadOnly<{|
  messageTemplate?: Api.User.MessageTemplate,
  onSubmit: (FT, FormObject<FT>) => mixed,
|}>;

export default function MessageTemplateForm({
  messageTemplate,
  onSubmit,
}: Props) {
  return (
    <UntypedFormComponent
      pristineValues={messageTemplate}
      validator={createFormValidator({
        name: { strictPresence: { mustDefine: !messageTemplate } },
        content: { strictPresence: { mustDefine: !messageTemplate } },
      })}
      onSubmit={onSubmit}
    >
      {({ getField, handleSubmit, isLoading }) => (
        <ContentBox>
          <FormWrap handleSubmit={handleSubmit}>
            <FieldInput
              field={getField('name')}
              hint="This name is for your own reference, to quickly identify your templates.  It won't be seen by your guests."
            />
            <FieldInput field={getField('content')} multiline />
            <SubmitButton isLoading={isLoading} />
          </FormWrap>
        </ContentBox>
      )}
    </UntypedFormComponent>
  );
}
