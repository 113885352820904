// @flow

import { Api } from '@guesthug/core';
import { useApiCallable } from '@guesthug/core';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';

import { ContentLoading, ReturnLink, ScreenError } from '../../components';
import { useAuthUser } from '../../hooks';
import ServiceForm from './ServiceForm';

export default function ServiceAddPage() {
  const [created, setCreated] = React.useState<boolean>(false);
  const authUser = useAuthUser();
  const callApi = useApiCallable();
  const { id } = authUser;

  const apiUser = Api.User.useReadItem(id);

  if (apiUser.error) return <ScreenError {...apiUser} />;
  if (!apiUser.data) return <ContentLoading />;

  const user = apiUser.data;

  if (created) {
    return <Redirect to="/services/guest" />;
  }
  return (
    <>
      <Helmet>
        <title>Add New Service</title>
      </Helmet>

      <ReturnLink to="/services">Return to Services Overview</ReturnLink>
      <ServiceForm
        currency={user.currency}
        onSubmit={async (values, { addSubmitError, setLoading }) => {
          setLoading(true);
          const response = await callApi('/services', {
            method: 'POST',
            jsonBody: {
              image: values.image ?? null,
              ...values,
            },
          });
          if (response) {
            setCreated(true);
          }
          setLoading(false);
        }}
      />
    </>
  );
}
