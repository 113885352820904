// @flow

import type { $Optional, FormGroup } from 'react-typed-form';

import { Api, Color, Style, useApiFormSubmit } from '@guesthug/core';
import { GHText, Spacer } from '@guesthug/core/components';
import * as React from 'react';
import { Image, Pressable, StyleSheet, View } from 'react-native';
import { useForm, useFormGroupItem } from 'react-typed-form';

import { FieldInput, FieldSelect } from '../../../form';
import { capitalize } from '../../../util';

type Props = $ReadOnly<{|
  propertyIri: string,
  existingOrCreation:
    | Api.Image.BaseView
    | {
        path: string,
        previewUri: string | null,
      },
  formGroup: FormGroup,
  formGroupKey: string,
|}>;

export default function PhotoForm({
  propertyIri,
  existingOrCreation,
  formGroup,
  formGroupKey,
}: Props) {
  const formGroupItem = useFormGroupItem({
    formGroup,
    key: formGroupKey,
  });
  const alias: string | void = formGroupItem.aliases[0];

  const existing = '@id' in existingOrCreation ? existingOrCreation : null;
  const existingIri = existing?.['@id'] ?? alias;
  const onSubmit = useApiFormSubmit(
    existingIri ? 'PUT' : 'POST',
    existingIri ?? '/images',
    {
      onSuccess: (_, data) => {
        if (!existingIri) {
          formGroupItem.addAlias(data['@id']);
        }
      },
    }
  );

  const form = useForm<$Optional<Api.Image.W>>({
    defaultValues:
      '@id' in existingOrCreation
        ? {}
        : {
            property: propertyIri,
            path: existingOrCreation.path,
          },
    pristineValues: existing ?? undefined,
    onSubmit,
  });

  formGroupItem.useSetForm(form);

  const imageUri =
    '@id' in existingOrCreation
      ? existingOrCreation.mediumUrl
      : existingOrCreation.previewUri;

  return (
    // z-index needed for FieldSelect to display properly
    <View style={[Style.row, { position: 'relative' }]}>
      <View>
        {imageUri ? (
          <Image
            source={{ uri: imageUri }}
            style={styles.preview}
            resizeMode="contain"
          />
        ) : (
          <View style={styles.preview} />
        )}
        <Spacer size={0.5} />
        <Pressable onPress={() => formGroupItem.remove()}>
          <GHText color="grey" size="small">
            Delete
          </GHText>
        </Pressable>
      </View>
      <Spacer />
      <View style={Style.fill}>
        <FieldSelect
          field={form.getField('propertyPhotoType')}
          label="Room Type"
          required
          choices={[
            { label: 'Main* [main photo required]', value: 'main' },
            ...[
              'main',
              'bathroom',
              'bedroom',
              'dining room',
              'entrance',
              'exterior',
              'garden',
              'interior',
              'kitchen',
              'living room',
              'other',
              'parking',
              'street',
              'terrace',
              'toilet',
            ].map((x) => ({ label: capitalize(x), value: x })),
          ]}
          noMargin
        />
        <Spacer size={0.5} />
        <FieldInput field={form.getField('caption')} noMargin />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  preview: {
    width: 200,
    height: 170,
    backgroundColor: Color.imagePlaceholderBg,
  },
});
