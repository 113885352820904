// @flow

import type { Id } from '../../../types';

import { Api, Style } from '@guesthug/core';
import { GHText, SingleCalendar, Spacer } from '@guesthug/core/components';
import { addMonths, format, parseISO, subMonths } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Pressable, StyleSheet, View, useWindowDimensions } from 'react-native';
import { Redirect, useHistory } from 'react-router';
import styled from 'styled-components';

import {
  Box,
  ContentLoading,
  ReturnLink,
  ScreenError,
} from '../../../components';
import { Color, Dimen } from '../../../constants';
import {
  LinkedBookingBar,
  PaneBooking,
  PaneGuide,
  PanePendingSelection,
  PaneSelectedRange,
} from '../components';

type Props = $ReadOnly<{|
  match: {
    params: {
      id: Id,
      bookingId?: ?Id,
      rangeStart?: ?string,
      rangeEnd?: ?string,
    },
  },
|}>;

export default function SingleCalendarScreen({ match }: Props) {
  const { id, bookingId, rangeStart, rangeEnd } = match.params;
  const history = useHistory();
  const { width: windowWidth } = useWindowDimensions();

  const [currentMonth, setCurrentMonth] = React.useState<string>(
    format(rangeStart ? parseISO(rangeStart) : new Date(), 'yyyy-MM')
  );
  const [mode, setMode] = React.useState<'minNights' | 'rate'>('rate');
  const [selectedBookingId, setSelectedBookingId] = React.useState<null | Id>(
    match.params.bookingId || null
  );
  const selectedRange = rangeStart && rangeEnd ? [rangeStart, rangeEnd] : null;
  const [pendingSelection, setPendingSelection] = React.useState<string | null>(
    null
  );

  const [hoveredBookingId, setHoveredBookingId] = React.useState<string | null>(
    null
  );

  // We need to refresh the calendar when a mutation is made
  const [invalidateKey, setInvalidateKey] = React.useState('_');
  function invalidate() {
    setInvalidateKey((Math.random() * 1000).toString());
  }

  React.useEffect(() => {
    setSelectedBookingId(bookingId ?? null);
  }, [bookingId]);

  const api = Api.Property.useReadItem(id);

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ContentLoading />;
  const property = api.data;

  function adjustCurrentMonth(future: boolean): void {
    const updated = future
      ? addMonths(parseISO(`${currentMonth}-01`), 1)
      : subMonths(parseISO(`${currentMonth}-01`), 1);
    setCurrentMonth(format(updated, 'yyyy-MM'));
  }

  const clearSelection = () => history.push(`/calendar/${id}`);
  let pane, onClosePane;
  if (pendingSelection) {
    pane = <PanePendingSelection date={pendingSelection} />;
    onClosePane = () => setPendingSelection(null);
  } else if (selectedRange) {
    pane = (
      <PaneSelectedRange
        start={selectedRange[0]}
        end={selectedRange[1]}
        propertyId={id}
        mode={mode}
        invalidate={() => {
          clearSelection();
          invalidate();
        }}
      />
    );
    onClosePane = clearSelection;
  } else if (selectedBookingId) {
    pane = <PaneBooking id={selectedBookingId} invalidate={invalidate} />;
    onClosePane = clearSelection;
  } else {
    pane = <PaneGuide mode={mode} property={property} />;
  }

  if (property.baseRate === null || property.minNights === null) {
    return <Redirect to={`/properties/${property.id}`} />;
  }

  const flexAreaSize =
    windowWidth - Dimen.hostWebLayoutPanelWidth - Dimen.spacing * 2;
  const paneSize = Math.max(300, flexAreaSize * 0.3);

  return (
    <>
      <Helmet>
        <title>{`Calendar (${property.name})`}</title>
      </Helmet>

      <View style={Style.alignStart}>
        <ReturnLink to="/calendar">Return to Calendar Overview</ReturnLink>
      </View>
      <Spacer size={0.5} />

      <View style={[Style.row, Style.spaceBetween, Style.alignCenter]}>
        <View style={Style.row}>
          <Pressable
            style={[
              styles.modeButton,
              mode === 'rate' && styles.modeButtonSelected,
            ]}
            onPress={() => setMode('rate')}
          >
            <GHText
              color={mode === 'rate' ? 'invertNormal' : 'faint'}
              size="small"
            >
              Rate
            </GHText>
          </Pressable>
          <Spacer size={0.5} />
          <Pressable
            style={[
              styles.modeButton,
              mode === 'minNights' && styles.modeButtonSelected,
            ]}
            onPress={() => setMode('minNights')}
          >
            <GHText
              color={mode === 'minNights' ? 'invertNormal' : 'faint'}
              size="small"
            >
              Min Nights
            </GHText>
          </Pressable>
        </View>

        {onClosePane && (
          <Pressable onPress={onClosePane}>
            <GHText color="faint" size="small">
              Close
            </GHText>
          </Pressable>
        )}
      </View>
      <Spacer size={0.5} />

      <StyledContainer>
        <StyledBoxCalendar>
          <AreaCalendarTop>
            <StyledArrow left onClick={() => adjustCurrentMonth(false)}>
              <img left src={require('../../../../assets/img/ui/arrow.svg')} />
            </StyledArrow>
            <div>
              <strong>{property.name}</strong>
              <span>{format(parseISO(`${currentMonth}-01`), 'MMMM y')}</span>
            </div>
            <StyledArrow right onClick={() => adjustCurrentMonth(true)}>
              <img src={require('../../../../assets/img/ui/arrow.svg')} />
            </StyledArrow>
          </AreaCalendarTop>
          <SingleCalendar
            key={invalidateKey}
            propertyId={id}
            currentMonth={currentMonth}
            totalWidth={flexAreaSize - paneSize - Dimen.spacing}
            mode={mode}
            selectedRange={selectedRange}
            pendingSelection={pendingSelection}
            setPendingSelection={setPendingSelection}
            onSelectRange={(range) => {
              history.push(`/calendar/${id}/${range[0]}~${range[1]}`);
            }}
            renderLoading={() => <ContentLoading />}
            renderBookingBar={({ top, left, ...barProps }) => (
              <LinkedBookingBar
                top={top}
                left={left}
                {...barProps}
                onHoverIn={() => setHoveredBookingId(barProps.bookingId)}
                onHoverOut={() => setHoveredBookingId(null)}
                isHovered={hoveredBookingId === barProps.bookingId}
              />
            )}
          />
        </StyledBoxCalendar>
        <StyledBoxBooking>{pane}</StyledBoxBooking>
      </StyledContainer>
    </>
  );
}

const styles = StyleSheet.create({
  modeButton: {
    height: 28,
    borderRadius: 14,
    paddingHorizontal: 20,
    justifyContent: 'center',
    backgroundColor: '#e2e7ec',
    ...({ transitionDuration: '250ms' }: any),
  },
  modeButtonSelected: {
    backgroundColor: Color.darkBlue,
  },
});

const StyledContainer = styled('div')`
  display: flex;
  align-items: flex-start;
`;

const StyledBoxCalendar = styled(Box)`
  flex: 7;
`;

const AreaCalendarTop = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${rem(Dimen.spacing)};
  text-align: center;

  strong {
    display: block;
    font-weight: 400;
    font-size: ${rem(20)};
    margin-bottom: ${rem(Dimen.spacing / 4)};
  }

  span {
    font-size: ${rem(14)};
    color: ${Color.faintBlue};
  }
`;

const StyledBoxBooking = styled(Box)`
  flex: 3;
  min-width: ${rem(300)};
  margin-left: ${rem(Dimen.spacing)};
`;

const StyledArrow = styled('a')`
  user-select: none;

  img {
    width: ${rem(18)};
    transform: rotate(${(p) => (p.left ? '90' : '270')}deg);
    padding: ${rem(5)} ${rem(8)};
    transition: opacity 0.25s;
  }

  &:hover img {
    opacity: 0.5;
  }
`;

const Empty = styled('div')`
  padding: ${rem(Dimen.spacing)};
  color: ${Color.faintBlue};
`;
