// @flow

import { Api } from '@guesthug/core';
import { rem } from 'polished';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import { ContentLoading, ScreenError } from '../../components';
import { Dimen } from '../../constants';
import { useAuthUser } from '../../hooks';
import BoxAgenda from './BoxAgenda';
import BoxCalendar from './BoxCalendar';
import BoxConfirmedBookings from './BoxConfirmedBookings';
import BoxImported from './BoxImported';
import BoxLatestFeedback from './BoxLatestFeedback';
import BoxPopularProperty from './BoxPopularProperty';
import BoxPopularService from './BoxPopularService';
import BoxServicesRevenue from './BoxServicesRevenue';

export default function DashboardPage() {
  const { id } = useAuthUser();

  const api = Api.Booking.useReadCollectionDashboard();
  const apiUser = Api.User.useReadItem(id);
  const apiProperty = Api.Property.useReadCollectionMine();

  if (api.error) return <ScreenError {...api} />;
  if (apiUser.error) return <ScreenError {...apiUser} />;
  if (apiProperty.error) return <ScreenError {...apiProperty} />;

  if (!api.data || !apiUser.data || !apiProperty.data) {
    return <ContentLoading />;
  }

  const user = apiUser.data;
  const bookings = api.data['hydra:member'];
  const properties = apiProperty.data['hydra:member'];

  const { currency } = user;

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <StyledList>
        <BoxConfirmedBookings bookings={bookings} />
        <BoxServicesRevenue bookings={bookings} currency={currency} />
        <BoxPopularService bookings={bookings} />
        <BoxPopularProperty bookings={bookings} />
        <BoxAgenda bookings={bookings} />
        <BoxCalendar bookings={bookings} properties={properties} />
        <BoxLatestFeedback bookings={bookings} />
        <BoxImported properties={properties} />
      </StyledList>
    </>
  );
}

const StyledList = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin: ${rem(-Dimen.spacing / 2)} ${rem(-Dimen.spacing / 2)} 0;
`;
