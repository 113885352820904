// @flow

import type { Dispatch } from '../../types';

import { useApiCallable } from '@guesthug/core';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { FormComponent } from 'react-typed-form';

import { flashShow } from '../../actions/flash';
import { Expander } from '../../components';
import { FieldSelect, FormWrap, SubmitButton } from '../../form';
import { useAuthUser } from '../../hooks';
import { currencySymbol } from '../../util';

type Props = $ReadOnly<{|
  currency: string | null,
|}>;

function readableCurrency(currency: string) {
  return `${currency.toUpperCase()} (${currencySymbol(currency)})`;
}

export default function SectionCurrency({ currency }: Props) {
  const { id } = useAuthUser();
  const dispatch = useDispatch<Dispatch>();
  const callApi = useApiCallable();

  return (
    <Expander title="Currency">
      {currency ? (
        <>
          Your currency is locked as{' '}
          <strong>{readableCurrency(currency)}</strong>, as you already have
          some services and/or purchases from guests in this currency. If you'd
          like to change this, please contact us.
        </>
      ) : (
        <FormComponent
          onSubmit={async (values, { setLoading, addSubmitError }) => {
            setLoading(true);
            const response = await callApi(`/users/${id}`, {
              method: 'PUT',
              jsonBody: values,
            });
            if (response) {
              dispatch(flashShow('Currency set'));
            }
            setLoading(false);
          }}
        >
          {({ getField, handleSubmit, formErrorList, isLoading }) => (
            <FormWrap handleSubmit={handleSubmit} formErrorList={formErrorList}>
              <p>
                Set the currency which will be used for any Services you sell
                through GuestHug. Please note that once this has been set, it
                can't be changed.
              </p>

              <FieldSelect
                field={getField('currency')}
                choices={['gbp', 'eur', 'usd'].map((c) => ({
                  value: c,
                  label: readableCurrency(c),
                }))}
              />

              <SubmitButton isLoading={isLoading} />
            </FormWrap>
          )}
        </FormComponent>
      )}
    </Expander>
  );
}
