// @flow

import { Color, getCoreConfig } from '@guesthug/core';
import * as React from 'react';

import { useAuthUser } from '../../hooks';

export default function ChannelsScreen() {
  const { jwt } = useAuthUser();

  return (
    <iframe
      src={`${
        getCoreConfig().SERVER_ROOT
      }/ru/generate-white-label?bearer=${jwt}`}
      style={{
        flex: '1 0',
        flexShrink: 0,
        borderWidth: 0,
        borderRight: `1px ${Color.border} solid`,
      }}
    />
  );
}
