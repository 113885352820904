// @flow

import { Api } from '@guesthug/core';
import * as React from 'react';
import styled from 'styled-components';

import { CoverImage, ImageTile } from '../../components';
import { formatPrice } from '../../util';

type Props = $ReadOnly<{|
  service: Api.Service.BaseView,
|}>;

export default function ServiceTile({ service }: Props) {
  return (
    <ImageTile
      to={`/services/guest/${service.id}`}
      image={service.image && <StyledImage src={service.image.mediumUrl} />}
      caption={service.title}
      subCaption={formatPrice(service.price, service.currency)}
      hoverLabel="Edit Service"
    />
  );
}

const StyledImage = styled(CoverImage)`
  height: 100%;
`;
