// @flow

import type { FieldProp } from 'react-typed-form';

import { Color, Style } from '@guesthug/core';
import { GHText, Spacer } from '@guesthug/core/components';
import * as React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

type Choice = $ReadOnly<{|
  value: mixed,
  label: string,
  hint?: string,
|}>;

type Props = $ReadOnly<{|
  field: FieldProp<any>,
  choices: Choice[],
  label?: string,
  hint?: string,
  required?: boolean,
|}>;

export default function FieldRadio({
  field,
  choices,
  label,
  hint,
  required,
}: Props) {
  return (
    <View>
      <GHText size="large" weight="light">
        {label ?? field.label}
        {required && (
          <GHText size="large" weight="bold" style={{ color: Color.red }}>
            {' *'}
          </GHText>
        )}
      </GHText>
      {hint && (
        <GHText color="faint" size="small">
          {hint}
        </GHText>
      )}
      <Spacer size={0.25} />

      {choices.map((choice) => {
        const isSelected = choice.value === field.value;
        return (
          <View key={choice.label} style={[Style.alignStart]}>
            <Spacer size={0.5} />
            <Pressable
              style={[Style.row, Style.alignCenter]}
              onPress={() => field.handleValueChange(choice.value)}
            >
              {({ hovered }) => (
                <>
                  <View
                    style={[
                      styles.circle,
                      isSelected && styles.circleSelected,
                      hovered && styles.circleHovered,
                    ]}
                  >
                    <View
                      style={[
                        styles.circleInner,
                        isSelected && styles.circleInnerSelected,
                      ]}
                    />
                  </View>
                  <Spacer size={0.35} />
                  <View>
                    <GHText>{choice.label}</GHText>
                    {choice.hint && (
                      <GHText color="faint" size="small">
                        {choice.hint}
                      </GHText>
                    )}
                  </View>
                </>
              )}
            </Pressable>
          </View>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  circle: {
    width: 28,
    height: 28,
    borderRadius: 14,
    borderWidth: 1,
    backgroundColor: '#f3f3f3',
    borderColor: '#bbb',
    ...({
      transitionProperty: 'all',
      transitionDuration: '250ms',
    }: any),
  },
  circleSelected: {
    borderColor: Color.vibrantBlue,
  },
  circleHovered: {
    backgroundColor: `${Color.vibrantBlue}22`,
  },
  circleInner: {
    width: 26,
    height: 26,
    borderRadius: 13,
    backgroundColor: '#f3f3f3',
    ...({
      transitionProperty: 'all',
      transitionDuration: '250ms',
    }: any),
  },
  circleInnerSelected: {
    backgroundColor: Color.vibrantBlue,
    transform: [{ scale: 0.7 }],
  },
});
