// @flow

import { rem } from 'polished';
import styled from 'styled-components';

import { Color, Dimen } from '../constants';

export default styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    display: block;
    font-size: ${rem(14)};
    transition: color 0.25s;
    padding: ${rem(3)};

    &:first-of-type {
      margin-top: ${rem(Dimen.spacing / 2)};
    }

    &.external {
      color: ${Color.faintBlue};
      margin-top: ${rem(Dimen.spacing)};
    }

    &:hover {
      color: ${Color.vibrantBlue};
    }
  }
`;
