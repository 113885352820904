// @flow

import { ellipsis, rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Box } from '../../../components';
import { Color, Dimen } from '../../../constants';

type Props = $ReadOnly<{|
  title: string,
  complete: boolean,
  to: string,
  disabledMessage?: string,
|}>;

export default function LinkBar({
  title,
  complete,
  to,
  disabledMessage,
}: Props) {
  const inner = (
    <StyledBox disabled={!!disabledMessage}>
      <StyledAreaMain>
        <h2>{title}</h2>
        {disabledMessage && (
          <StyledDisabledMessage>{disabledMessage}</StyledDisabledMessage>
        )}
      </StyledAreaMain>
      {complete && (
        <StyledValid
          src={require('../../../../assets/img/ui/input_valid.svg')}
        />
      )}
      <StyledArrow>
        <img src={require('../../../../assets/img/ui/arrow.svg')} />
      </StyledArrow>
    </StyledBox>
  );
  return disabledMessage ? <a>{inner}</a> : <Link to={to}>{inner}</Link>;
}

const StyledBox = styled(Box)`
  margin-bottom: ${rem(12)};

  display: flex;
  height: ${rem(65)};
  align-items: center;
  cursor: pointer;
  transition: background-color 0.25s;
  user-select: none;
  ${(p) =>
    p.disabled &&
    `
    opacity: 0.7;
    cursor: default;
  `};

  &:hover {
    ${(p) => !p.disabled && 'background-color: #f8f8f8'};
  }
`;

const StyledAreaMain = styled.div`
  flex: 1;
  margin: 0 ${rem(Dimen.spacing)};

  h2 {
    font-weight: 300;
    font-size: ${rem(20)};
    ${ellipsis()};
    margin: 0;
  }
`;

const StyledValid = styled.img`
  width: ${rem(26)};
  margin-right: ${rem(Dimen.spacing)};
`;

const StyledArrow = styled.span`
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(65)};

  img {
    width: ${rem(20)};
    transform: rotate(-90deg);
  }
`;

const StyledDisabledMessage = styled.div`
  margin-top: -4px;
  color: ${Color.faintBlue};
  font-size: ${rem(14)};
`;
