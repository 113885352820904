// @flow

import type { Id } from '../../types';

import { Api } from '@guesthug/core';
import * as React from 'react';
import styled from 'styled-components';

import { CoverImage, ImageTile } from '../../components';
import { placesPhotoUrl } from '../../util';
import { categories, typeToTitle } from './typeHelpers';

type Props = $ReadOnly<{|
  localGuide: Api.LocalGuide.BaseView,
  propertyId: Id,
|}>;

export const LocalGuideListTile = ({ localGuide, propertyId }: Props) => {
  const photoUrl = placesPhotoUrl(localGuide.placesPhotoReference);
  const src = photoUrl || localGuide.image?.mediumUrl;

  return (
    <ImageTile
      to={`/properties/${propertyId}/local-guide/${localGuide.id}`}
      image={src && <StyledImage src={src} />}
      caption={localGuide.name}
      subCaption={
        localGuide.category ? categories[localGuide.category]?.label : null
      }
      // $FlowFixMe
      hoverLabel={`Edit ${typeToTitle(localGuide.type)}`}
    />
  );
};

const StyledImage = styled(CoverImage)`
  height: 100%;
`;
