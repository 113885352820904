// @flow

import { Api } from '@guesthug/core';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import {
  AddNewTile,
  ContentLoading,
  ImageTileList,
  ScreenError,
} from '../../components';
import { useAuthUser } from '../../hooks';
import ServiceTile from './ServiceTile';

export default function ServicesGuestPage() {
  const { id } = useAuthUser();

  const api = Api.User.useReadItemFull(id);

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ContentLoading />;

  const { services } = api.data;

  return (
    <>
      <Helmet>
        <title>Services</title>
      </Helmet>
      <ImageTileList>
        <AddNewTile
          label="Add New Service"
          to="/services/guest/add"
          plusEffect
        />
        {services.map((service) => (
          <ServiceTile key={service.id} service={service} />
        ))}
      </ImageTileList>
    </>
  );
}
