// @flow

import type { Purchase } from './types';

import {
  isSameMonth,
  isSameYear,
  parseISO,
  subMonths,
  subYears,
} from 'date-fns';
import * as React from 'react';
import styled from 'styled-components';

import { Figure, TitledBox } from '../../components';
import { calcChangePercent, formatPrice } from '../../util';

type Props = $ReadOnly<{|
  period: 'month' | 'year',
  purchases: Array<Purchase>,
  currency: string,
|}>;

export default function SectionServicesRevenue({
  period,
  purchases,
  currency,
}: Props) {
  let priceThisPeriod = 0;
  let priceLastPeriod = 0;

  const thisPeriod = new Date();
  const lastPeriod =
    period === 'year' ? subYears(thisPeriod, 1) : subMonths(thisPeriod, 1);

  const isSamePeriod = period === 'year' ? isSameYear : isSameMonth;

  purchases.forEach((purchase) => {
    const { createdAt } = purchase;
    purchase.lineItems.forEach((lineItem) => {
      if (isSamePeriod(parseISO(createdAt), thisPeriod)) {
        priceThisPeriod += lineItem.price * lineItem.quantity;
      }
      if (isSamePeriod(parseISO(createdAt), lastPeriod)) {
        priceLastPeriod += lineItem.price * lineItem.quantity;
      }
    });
  });

  const changePercent = calcChangePercent(priceThisPeriod, priceLastPeriod);

  return (
    <StyledTitledBox
      title={
        <>
          Services Sold <b>This {period === 'year' ? 'Year' : 'Month'}</b>
        </>
      }
      padded
    >
      <Figure
        value={formatPrice(priceThisPeriod, currency, {
          round: true,
        })}
        changePercent={changePercent}
        yellow
      />
    </StyledTitledBox>
  );
}

const StyledTitledBox = styled(TitledBox)`
  flex: 1;
  border-radius: 0;
  box-shadow: none;
  padding: 0 5%;

  &:first-child {
    border-right: 2px #f2f4f8 solid;
  }
`;
