// @flow

import type { $Optional, FormGroup } from 'react-typed-form';

import { Api, Color, Style, useApiFormSubmit } from '@guesthug/core';
import { GHText, Spacer } from '@guesthug/core/components';
import { lighten } from 'polished';
import * as React from 'react';
import { StyleSheet } from 'react-native';
import { Pressable, View } from 'react-native';
import { useForm, useFormGroupItem } from 'react-typed-form';

import { FieldDate, FieldNumberStrict, FieldSwitch } from '../../../form';

type Props = {
  propertyIri: string,
  existing: Api.SeasonalRate.SeasonalRates_BaseView | null,
  formGroup: FormGroup,
  formGroupKey: string,
};

export default function SeasonalRateForm({
  propertyIri,
  existing,
  formGroup,
  formGroupKey,
}: Props) {
  const formGroupItem = useFormGroupItem({
    formGroup,
    key: formGroupKey,
  });
  const alias: string | void = formGroupItem.aliases[0];

  const existingIri = existing?.['@id'] ?? alias;
  const onSubmit = useApiFormSubmit(
    existingIri ? 'PUT' : 'POST',
    existingIri ?? '/seasonal-rates',
    {
      onSuccess: (_, data) => {
        if (!existingIri) {
          formGroupItem.addAlias(data['@id']);
        }
      },
    }
  );

  const form = useForm<$Optional<Api.SeasonalRate.SeasonalRates_W>>({
    defaultValues: {
      property: propertyIri,
      repeatYearly: existing?.repeatYearly ?? false,
    },
    pristineValues: (existing: any) ?? undefined,
    onSubmit,
  });

  formGroupItem.useSetForm(form);

  return (
    <View style={[Style.row]}>
      <FieldDate
        field={form.getField('fromDate')}
        label="From"
        inputWidth={220}
      />
      <Spacer />
      <FieldDate field={form.getField('toDate')} label="To" inputWidth={220} />
      <Spacer />
      <View style={[Style.row]}>
        <View style={{ marginTop: 37, marginRight: 2 }}>
          <Pressable
            style={({ hovered }: any) => [
              styles.adjustButton,
              hovered && styles.adjustButtonHovered,
            ]}
            onPress={() =>
              form
                .getField('rateChange')
                .handleValueChange((form.getField('rateChange').value ?? 0) + 1)
            }
          >
            <GHText
              size="larger"
              color="invertNormal"
              weight="bold"
              selectable={false}
            >
              +
            </GHText>
          </Pressable>
          <Pressable
            style={({ hovered }: any) => [
              styles.adjustButton,
              hovered && styles.adjustButtonHovered,
            ]}
            onPress={() =>
              form
                .getField('rateChange')
                .handleValueChange((form.getField('rateChange').value ?? 0) - 1)
            }
          >
            <GHText
              size="larger"
              color="invertNormal"
              weight="bold"
              selectable={false}
            >
              –
            </GHText>
          </Pressable>
        </View>
        <FieldNumberStrict
          field={form.getField('rateChange')}
          label="Rate Change"
          afterInput="%"
          allowNegative
          hideZero
          inputWidth={120}
        />
      </View>
      <View style={[Style.row, Style.alignCenter, { paddingTop: 20 }]}>
        <Spacer />
        <FieldSwitch field={form.getField('repeatYearly')} />
        <Spacer size={0.25} />
        <GHText color="faint">Repeat yearly</GHText>
        <Spacer />
        <Pressable onPress={() => formGroupItem.remove()}>
          <GHText size="small" color="grey">
            Delete
          </GHText>
        </Pressable>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  adjustButton: {
    width: 40,
    height: 33,
    borderRadius: 4,
    marginBottom: 1,
    backgroundColor: Color.vibrantBlue,
    ...({
      transitionProperty: 'all',
      transitionDuration: '250ms',
    }: any),
    justifyContent: 'center',
    alignItems: 'center',
  },
  adjustButtonHovered: {
    backgroundColor: lighten(0.05, Color.vibrantBlue),
  },
});
