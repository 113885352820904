// @flow

import type { Flash } from '../reducers/flash';
import type { Dispatch, RootState } from '../types';

import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Dimen } from '../constants';

type OwnProps = $ReadOnly<{||}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  flashes: $ReadOnlyArray<Flash>,
  dispatch: Dispatch,
|}>;

type State = {|
  current: null | string,
|};

class FlashDisplayer extends React.PureComponent<Props, State> {
  state = {
    current: null,
  };

  _showTimeout: TimeoutID | null = null;
  _dismissTimeout: TimeoutID | null = null;

  componentDidUpdate(prevProps: Props) {
    const { flashes } = this.props;
    if (flashes !== prevProps.flashes) {
      this.setState({ current: null });
      this._showTimeout && clearTimeout(this._showTimeout);
      this._showTimeout = setTimeout(
        () => this.setState({ current: flashes[flashes.length - 1].key }),
        300
      );

      this._dismissTimeout && clearTimeout(this._dismissTimeout);
      this._dismissTimeout = setTimeout(
        () => this.setState({ current: null }),
        10000
      );
    }
  }

  render() {
    const { flashes } = this.props;
    const { current } = this.state;
    return (
      <StyledContainer>
        {flashes.map((flash) => (
          <StyledFlash key={flash.key} visible={flash.key === current}>
            {flash.message}
          </StyledFlash>
        ))}
      </StyledContainer>
    );
  }
}

export default connect<Props, OwnProps, _, _, _, _>((state: RootState) => ({
  flashes: state.flash.flashes,
}))(FlashDisplayer);

const StyledContainer = styled('div')`
  position: fixed;
  top: ${rem(Dimen.hostWebHeaderHeight)};
  left: ${rem(Dimen.hostWebLayoutPanelWidth)};
  right: 0;
  height: ${rem(50)};
  z-index: 8;
  overflow: hidden;
  pointer-events: none;
`;

const StyledFlash = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: ${rem(50)};
  background-color: #38c59b;
  color: #fff;
  text-align: center;
  transform: translateY(${rem(-50)});
  transition: transform 0.4s;

  ${(p) =>
    p.visible &&
    `
    transform: translateY(0);
  `};
`;
