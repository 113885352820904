// @flow

import type { Id } from '../../types';

import { Api } from '@guesthug/core';
import { useApiCallable } from '@guesthug/core';
import { useInvalidation } from 'api-read-hook';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';

import { ContentLoading, ReturnLink, ScreenError } from '../../components';
import MessageTemplateForm from './MessageTemplateForm';

type Props = $ReadOnly<{|
  match: { params: { id: Id } },
|}>;

export default function MessageTemplateEditPage({ match }: Props) {
  const { id } = match.params;
  const callApi = useApiCallable();
  const { invalidateMatching } = useInvalidation();

  const [saved, setSaved] = React.useState<boolean>(false);

  const apiMessage = Api.MessageTemplate.useReadItem(id);

  if (apiMessage.error) return <ScreenError {...apiMessage} />;
  if (!apiMessage.data) return <ContentLoading />;

  const messageTemplate = apiMessage.data;

  if (saved) {
    return <Redirect to={'/settings'} />;
  }

  const returnLink = (
    <ReturnLink to={'/settings'}>Return to Settings</ReturnLink>
  );

  return (
    <>
      <Helmet>
        <title>{messageTemplate.name}</title>
      </Helmet>
      {returnLink}
      <MessageTemplateForm
        messageTemplate={messageTemplate}
        onSubmit={async (values, { setLoading, addSubmitError }) => {
          setLoading(true);
          const response = await callApi(`/message-templates/${id}`, {
            method: 'PUT',
            jsonBody: values,
          });
          if (response) {
            setSaved(true);
            invalidateMatching('/users');
          }
          setLoading(false);
        }}
      />
    </>
  );
}
