// @flow

import type { Action } from '../types';

export type OptionsState = $ReadOnly<{
  mode: 'host' | 'guest' | null,
  dismissedSelectPlan?: boolean,
}>;

const initialState = {
  mode: null,
  dismissedSelectPlan: false,
};

/**
 * Fairly important user options which should persist across repeat visits.
 * However, they won't persist across a logout.
 */
export default (
  state: OptionsState = initialState,
  action: Action
): OptionsState => {
  switch (action.type) {
    case 'SET_OPTION_MODE':
      return { ...state, mode: action.payload };
    case 'SET_OPTION_DISMISSED_SELECT_PLAN':
      return { ...state, dismissedSelectPlan: action.payload };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
};
