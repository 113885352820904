// @flow

import type { Dispatch } from '../../types';

import { AuthApi, useApiCallable } from '@guesthug/core';
import { rem } from 'polished';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormComponent } from 'react-typed-form';
import styled from 'styled-components';

import { ExternalAreaBottom, ExternalPanel, Hr } from '../../components';
import { Dimen } from '../../constants';
import {
  FieldInput,
  FieldSwitch,
  FormErrorList,
  SubmitButton,
  createFormValidator,
} from '../../form';

class ConcreteForm extends FormComponent<{|
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phone?: string | null,
  plainPassword?: string | null,
|}> {}

export default function RegisterPage() {
  const dispatch = useDispatch<Dispatch>();
  const callApi = useApiCallable();
  const loginSubmit = AuthApi.useLoginSubmit();

  return (
    <ExternalPanel>
      <Helmet>
        <title>Register</title>
      </Helmet>
      <ConcreteForm
        pristineValues={{ phone: '+44' }}
        validator={createFormValidator({
          firstName: { strictPresence: true },
          lastName: { strictPresence: true },
          email: { strictPresence: true, email: true },
          plainPassword: {
            strictPresence: { message: "^Password can't be blank" },
            passwordFormat: true,
            passwordLength: true,
          },
        })}
        onSubmit={async (data, { setLoading, addSubmitError }) => {
          setLoading(true);
          const response = await callApi('/register', {
            method: 'POST',
            jsonBody: data,
          });
          setLoading(false);

          if (!response) return;

          // Always going to be valid as registration validation just passed
          const result = await loginSubmit(
            data.email || '',
            data.plainPassword || ''
          );
          if (result.type !== 'success') {
            throw new Error('Just registered but login details incorrect');
          }
          const { type, ...payload } = result;
          dispatch({ type: 'LOGIN_SUCCESS', payload });
        }}
      >
        {({ formErrorList, getField, handleSubmit, isLoading }) => (
          <StyledForm
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <FormErrorList errors={formErrorList} />

            <FieldInput field={getField('firstName')} external />
            <FieldInput field={getField('lastName')} external />
            <FieldInput field={getField('email')} external />
            <FieldInput field={getField('phone')} external />
            <FieldInput
              field={{ ...getField('plainPassword'), label: 'Password' }}
              secureTextEntry
              external
              hint="Your password must be 8 or more characters long, and include at least one capital letter and one number."
            />
            <Hr />
            <FieldSwitch
              field={getField('acceptsMarketing')}
              description="I'd like to recieve occasional news from GuestHug"
            />
            <ExternalAreaBottom>
              <SubmitButton isLoading={isLoading} normalLabel="Register" />

              <Link to="/">Already have an account?</Link>
            </ExternalAreaBottom>
          </StyledForm>
        )}
      </ConcreteForm>
    </ExternalPanel>
  );
}

const StyledForm = styled('form')`
  padding: ${rem(Dimen.spacing)};
`;
