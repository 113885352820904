// @flow

import { Api, Color, Model } from '@guesthug/core';
import * as React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import styled from 'styled-components';

import { ImageTile, PropertyFeaturedImage } from '../../../components';

type Props = $ReadOnly<{|
  property: Api.Property.V_Full,
|}>;

export default React.memo<Props>(function PropertiesItem({ property }: Props) {
  return (
    <ImageTile
      to={`/properties/${property.id}`}
      image={<StyledImage property={property} filter="medium" />}
      caption={property.name}
      subCaption={Model.Property.getAddressParts(property)?.join(', ')}
      hoverLabel="Edit Listing"
      customLayer={
        <View style={StyleSheet.absoluteFill}>
          {property.isActive && (
            <View style={styles.active}>
              <Image
                source={require('../../../../assets/img/ui/tick_white.svg')}
                style={{ width: 16, height: 11 }}
              />
            </View>
          )}
        </View>
      }
      archived={!!property.isArchived}
    />
  );
});

const styles = StyleSheet.create({
  active: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 28,
    height: 28,
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 0 5px #0002',
    borderRadius: 16,
    backgroundColor: Color.green,
  },
});

const StyledImage = styled(PropertyFeaturedImage)`
  height: 100%;
`;
