// @flow

import { format, parseISO } from 'date-fns';
import { rem, transparentize } from 'polished';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  Box,
  ContentLoading,
  CoverImage,
  EmptyMessageBlock,
} from '../../components';
import * as config from '../../config';
import { Color, Dimen, Domain } from '../../constants';

type Props = $ReadOnly<{|
  type: 'Booking' | 'Message' | 'Property' | 'Service',
  flatResults: null | Object[], // eslint-disable-line flowtype/no-weak-types
|}>;

const ResultsCol = ({ type, flatResults }: Props) => {
  const byPropertyId = [{ propertyId: null, propertyData: null, results: [] }];

  flatResults &&
    flatResults.forEach((result) => {
      const propertyId = result.propertyId || null;

      let match = byPropertyId.find((x) => x.propertyId === propertyId);
      if (!match) {
        match = { propertyId, propertyData: result, results: [] };
        byPropertyId.push(match);
      }

      if (result.id) {
        match.results.push(result);
      }
    });

  let inner;
  if (flatResults === null) {
    inner = <ContentLoading />;
  } else if (flatResults.length == 0) {
    inner = <EmptyMessageBlock>No results</EmptyMessageBlock>;
  } else {
    inner = byPropertyId.map(({ results, propertyId, propertyData }) => (
      <React.Fragment key={propertyId}>
        {propertyData && (
          <StyledLink to={`/properties/${propertyData.propertyId}`}>
            {propertyData.propertyImagePath && (
              <StyledCoverImage
                src={`${config.SERVER_ROOT}/media/cache/resolve/large/${propertyData.propertyImagePath}`}
              />
            )}
            <StyledPropertyInfo>
              <strong>{propertyData.propertyName}</strong>
              {propertyData.propertyAddress && (
                <span>{propertyData.propertyAddress}</span>
              )}
            </StyledPropertyInfo>
          </StyledLink>
        )}

        {results.map((result) => {
          let to;
          if (type === 'Service') {
            to = `/services/guest/${result.id}`;
          } else if (type === 'Booking') {
            to = `/calendar/${result.propertyId}/${result.id}`;
          }

          let { description } = result;
          if (type === 'Booking') {
            description = (
              <>
                <div>
                  <dt>Arr.</dt>
                  <dd>
                    {format(
                      parseISO(
                        `${result.startDate}T${
                          result.arriveAfter || Domain.defaultArriveAfter
                        }`
                      ),
                      "d MMMM y 'at' HH:mm"
                    )}
                  </dd>
                </div>
                <div>
                  <dt>Dep.</dt>
                  <dd>
                    {format(
                      parseISO(
                        `${result.endDate}T${
                          result.departBefore || Domain.defaultDepartBefore
                        }`
                      ),
                      "d MMMM y 'at' HH:mm"
                    )}
                  </dd>
                </div>
              </>
            );
          }

          return (
            <StyledResult key={result.id} to={to}>
              <strong>{result.title}</strong>
              {description && <span>{description}</span>}
            </StyledResult>
          );
        })}
      </React.Fragment>
    ));
  }

  return (
    <StyledBox>
      <StyledHeading>
        <span>{type === 'Property' ? 'Listings' : `${type}s`}</span>

        <img
          src={require(`../../../assets/img/search/col_${type.toLowerCase()}.svg`)}
        />
      </StyledHeading>
      {inner}
    </StyledBox>
  );
};

export default ResultsCol;

const StyledBox = styled(Box)`
  flex: 1 0 0;
  margin: 0 ${rem(Dimen.spacing / 4)};
  border-radius: 4px;
  overflow: hidden;
`;

const StyledHeading = styled('div')`
  display: flex;
  justify-content: space-between;
  background-color: ${Color.darkBlue};
  color: #849bba;
  font-size: ${rem(14)};
  font-weight: 300;
  padding: ${rem(Dimen.spacing / 2)};

  img {
    height: ${rem(18)};
  }
`;

const StyledLink = styled(Link)`
  display: block;
  transition: background-color 0.25s;
  &:hover {
    background-color: ${transparentize(0.8, Color.fainterBlue)};
  }
`;

const StyledCoverImage = styled(CoverImage)`
  height: ${rem(180)};
`;

const StyledPropertyInfo = styled('div')`
  padding: ${rem(Dimen.spacing / 2)};
  border-bottom: 2px #f2f4f8 solid;

  strong {
    display: block;
    font-weight: 400;
    font-size: ${rem(20)};
  }

  span {
    margin-top: ${rem(Dimen.spacing / 4)};
    font-size: ${rem(14)};
    font-weight: 300;
    color: ${Color.faintBlue};
  }
`;

const StyledResult = styled((props) =>
  React.createElement(props.to ? StyledLink : 'div', props)
)`
  padding: ${rem(Dimen.spacing / 2)};
  font-weight: 300;
  font-size: ${rem(14)};
  border-bottom: 2px #f2f4f8 solid;

  strong {
    display: block;
  }

  span {
    display: block;
  }

  dt {
    width: ${rem(34)};
  }

  dt,
  dd {
    display: inline-block;
  }
`;
