// @flow

import * as React from 'react';
import styled from 'styled-components';

import LayoutBase from './LayoutBase';

type Props = $ReadOnly<{|
  children: React.Node,
|}>;

const LayoutExternal = ({ children }: Props) => (
  <LayoutBase>
    <Container>{children}</Container>
  </LayoutBase>
);

export default LayoutExternal;

const Container = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: #253745;
  background-image: url(/img/external_bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  overflow-y: auto;
`;
