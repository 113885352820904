// @flow

import type { ServerImageFilter } from '@guesthug/core';

import { Api } from '@guesthug/core';
import * as React from 'react';
import styled from 'styled-components';

import { CoverImage } from '../components';
import { Color } from '../constants';

type Props = $ReadOnly<{|
  property: {
    propertyMainImage: Api.Image.BaseView | null,
  },
  filter?: ServerImageFilter,
  className?: string,
|}>;

export default function PropertyFeatureImage({
  property,
  filter = 'large',
  className,
}: Props) {
  const image = property.propertyMainImage;
  if (!image || !image.path) {
    return <StyledEmpty className={className} />;
  }

  return <CoverImage className={className} src={image[`${filter}Url`]} />;
}

const StyledEmpty = styled('div')`
  background-color: ${Color.imagePlaceholderBg};
`;
