// @flow

import { rem } from 'polished';
import styled from 'styled-components';

import { Dimen } from '../constants';

export default styled('div')`
  margin: 0 ${rem(-Dimen.spacing / 2)};
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > a {
    height: ${rem(240)};
    margin: ${rem(Dimen.spacing / 2)};

    max-width: ${rem(400)};
    width: calc(25% - (${rem(Dimen.spacing)}));
    @media (max-width: 1880px) {
      width: calc(33.3% - (${rem(Dimen.spacing)}));
    }
    @media (max-width: 1550px) {
      width: calc(50% - (${rem(Dimen.spacing)}));
    }
    @media (max-width: 1220px) {
      width: calc(100% - (${rem(Dimen.spacing)}));
    }
  }
`;
