// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Color } from '../constants';
import Box from './Box';

type Props = $ReadOnly<{|
  title?: React.Node,
  padded?: boolean,
  className?: string,
  children?: React.Node,
|}>;

const TitledBox = ({ title, padded, className, children }: Props) => (
  <Box className={className}>
    {title && <StyledTitle>{title}</StyledTitle>}
    <StyledChildren padded={padded}>{children}</StyledChildren>
  </Box>
);

export default TitledBox;

const StyledTitle = styled('div')`
  padding: ${rem(20)} ${rem(20)} ${rem(10)};
  font-size: ${rem(14)};
  font-weight: 300;
  color: ${Color.faintBlue};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
`;

const StyledChildren = styled('div')`
  flex: 1;
  ${(p) => p.padded && `padding: 0 ${rem(20)} ${rem(15)}`};
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;
