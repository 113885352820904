// @flow

import { Color, Style } from '@guesthug/core';
import { GHText, PaddedArea, Spacer } from '@guesthug/core/components';
import { format, parseISO } from 'date-fns';
import * as React from 'react';
import { View } from 'react-native';

type Props = $ReadOnly<{|
  date: string,
|}>;

export default function PanePendingSelection({ date }: Props) {
  return (
    <PaddedArea all style={{ backgroundColor: Color.darkBlue }}>
      <GHText color="invertNormal" size="larger">
        Selection Started
      </GHText>
      <Spacer size={0.5} />
      <View style={Style.row}>
        <GHText size="small" color="faint" style={{ width: 50 }}>
          Start
        </GHText>
        <GHText size="small" color="invertNormal">
          {format(parseISO(date), 'EEEE do MMMM y')}
        </GHText>
      </View>
      <Spacer size={0.5} />
      <GHText size="small" color="faint">
        Click another non-booked day to complete the selection.
      </GHText>
    </PaddedArea>
  );
}
