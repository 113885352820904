// @flow

import type { Id } from '../../types';

import { Api } from '@guesthug/core';
import { rem } from 'polished';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import {
  AddNewTile,
  ContentLoading,
  CoverImage,
  ImageTile,
  ImageTileList,
  ReturnLink,
  ScreenError,
} from '../../components';
import { Dimen } from '../../constants';
import HomeGuideListTile from './HomeGuideListTile';
import { HOME_GUIDE_TYPES, typeToTitle } from './typeHelpers';

type Props = {
  match: { params: { propertyId: Id } },
};

export default function HomeGuideListPage({ match }: Props) {
  const { propertyId } = match.params;

  const api = Api.Property.useReadItemFull(propertyId);

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ContentLoading />;

  const property = api.data;

  const returnLink = (
    <ReturnLink to={`/properties/${propertyId}`}>
      Return to Property Overview
    </ReturnLink>
  );

  const homeGuides = property.homeGuides.sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  return (
    <>
      <Helmet>
        <title>{`${property.name} - Home Guides`}</title>
      </Helmet>
      {returnLink}
      <div>
        <StyledTypeTitle>
          <img src={require('../../../assets/img/home-guide/wifi.png')} />
          Wifi
        </StyledTypeTitle>
        <ImageTileList>
          <ImageTile
            to={`/properties/${property.id}/wifi`}
            image={
              <StyledTileImage
                src={require('../../../assets/img/home-guide/tile_wifi.jpg')}
              />
            }
            caption="Wifi"
            hoverLabel="Edit Wifi Details"
          />
        </ImageTileList>
      </div>

      {HOME_GUIDE_TYPES.map((type) => (
        <div key={type}>
          <StyledTypeTitle>
            <img src={require(`../../../assets/img/home-guide/${type}.png`)} />
            {typeToTitle(type)}
          </StyledTypeTitle>
          <ImageTileList>
            <AddNewTile
              label={`Add New ${typeToTitle(type)} Guide`}
              to={`/properties/${propertyId}/home-guide/add/${type}`}
            />
            {homeGuides
              .filter((x) => x.type === type)
              .map((homeGuide) => (
                <HomeGuideListTile
                  key={homeGuide.id}
                  homeGuide={homeGuide}
                  propertyId={propertyId}
                />
              ))}
          </ImageTileList>
        </div>
      ))}
    </>
  );
}

const StyledTileImage = styled(CoverImage)`
  height: 100%;
`;

const StyledTypeTitle = styled('h2')`
  margin: ${rem(Dimen.spacing)} 0 ${rem(Dimen.spacing / 4)};
  font-size: ${rem(20)};
  font-weight: 300;
  border-bottom: 1px #e7ebf1 solid;
  padding-bottom: ${rem(Dimen.spacing / 4)};
  display: flex;
  align-items: center;

  img {
    margin-right: ${rem(Dimen.spacing / 2)};
  }
`;
