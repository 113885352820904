// @flow

import type { Action, Id } from '../types';

export type UserState = $ReadOnly<{|
  id: Id,
  email: string,
  jwt: string,
|}>;

/**
 * Can't have UserState | null at the root of the reducer or it breaks
 * redux-persist (which needs an object to assign keys to).
 */
export type AuthState = $ReadOnly<{
  user: UserState | null,
}>;

const initialState = {
  user: null,
};

export default (state: AuthState = initialState, action: Action): AuthState => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { user: action.payload };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
};
