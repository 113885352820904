// @flow

import type { Dispatch, Id } from '../../types';

import { Api } from '@guesthug/core';
import { useApiCallable } from '@guesthug/core';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { flashShow } from '../../actions/flash';
import {
  ContentLoading,
  Modal,
  ReturnLink,
  ScreenError,
} from '../../components';
import { SubmitButton } from '../../form';
import HomeGuideForm from './HomeGuideForm';

type Props = $ReadOnly<{|
  match: { params: { propertyId: Id, id: Id } },
|}>;

export default function HomeGuideEditPage({ match }: Props) {
  const [savedOrDeleted, setSavedOrDeleted] = React.useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] =
    React.useState<boolean>(false);
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);

  const { propertyId, id } = match.params;
  const callApi = useApiCallable();
  const dispatch = useDispatch<Dispatch>();

  const api = Api.HomeGuide.useReadItem(id);

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ContentLoading />;

  const homeGuide = api.data;

  if (savedOrDeleted) {
    return <Redirect to={`/properties/${propertyId}/home-guide`} />;
  }

  const returnLink = (
    <ReturnLink to={`/properties/${propertyId}/home-guide`}>
      Return to Home Guide Overview
    </ReturnLink>
  );

  if (!homeGuide) {
    return <Redirect to={`/properties/${propertyId}/home-guide`} />;
  }
  return (
    <>
      <Helmet>
        <title>{homeGuide.title}</title>
      </Helmet>

      {deleteModalVisible && (
        <Modal
          title={`Really delete ${homeGuide.title}?`}
          padded
          onDismiss={() => setDeleteModalVisible(false)}
        >
          <StyledSubmitButton
            isLoading={isDeleting}
            normalLabel="Delete Guide"
            loadingLabel="Deleting"
            onClick={async () => {
              setIsDeleting(true);
              const response = await callApi(`/home-guides/${homeGuide.id}`, {
                method: 'DELETE',
              });
              if (response) {
                setSavedOrDeleted(true);
              }
              setIsDeleting(false);
            }}
          />
        </Modal>
      )}

      {returnLink}
      <HomeGuideForm
        homeGuide={homeGuide}
        onSubmit={async (values, { setLoading, addSubmitError }) => {
          setLoading(true);
          const response = await callApi(`/home-guides/${homeGuide.id}`, {
            method: 'PUT',
            jsonBody: values,
          });
          if (response) {
            dispatch(flashShow('Changes saved'));
            setSavedOrDeleted(true);
          }
          setLoading(false);
        }}
        onDeleteClick={() => setDeleteModalVisible(true)}
      />
    </>
  );
}

const StyledSubmitButton = styled(SubmitButton)`
  margin: 0;
  align-self: flex-start;
`;
