// @flow

import type { $Optional, FormGroup } from 'react-typed-form';

import { Api, Style, useApiFormSubmit } from '@guesthug/core';
import { GHText, Spacer } from '@guesthug/core/components';
import * as React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { useForm, useFormGroupItem } from 'react-typed-form';

import { FieldNumberStrict } from '../../../form';

type Props = $ReadOnly<{|
  propertyIri: string,
  existing: Api.SeasonalRate.SeasonalRates_BaseView | null,
  formGroup: FormGroup,
  formGroupKey: string,
  number: number,
|}>;

export default function BedroomForm({
  propertyIri,
  existing,
  formGroup,
  formGroupKey,
  number,
}: Props) {
  const formGroupItem = useFormGroupItem({
    formGroup,
    key: formGroupKey,
  });
  const alias: string | void = formGroupItem.aliases[0];

  const existingIri = existing?.['@id'] ?? alias;
  const onSubmit = useApiFormSubmit(
    existingIri ? 'PUT' : 'POST',
    existingIri ?? '/property-bedrooms',
    {
      onSuccess: (_, data) => {
        if (!existingIri) {
          formGroupItem.addAlias(data['@id']);
        }
      },
    }
  );

  const form = useForm<$Optional<Api.PropertyBedroom.W>>({
    defaultValues: {
      property: propertyIri,
      doubleBeds: existing?.doubleBeds ?? 0,
      singleBeds: existing?.singleBeds ?? 0,
    },
    pristineValues: (existing: any) ?? undefined,
    onSubmit,
  });

  formGroupItem.useSetForm(form);

  return (
    <View style={[Style.row]}>
      <View
        style={[Style.row, Style.alignCenter, { width: 40, paddingTop: 20 }]}
      >
        <GHText color="faint">#{number}</GHText>
      </View>
      <View style={styles.bedsField}>
        <FieldNumberStrict field={form.getField('doubleBeds')} />
      </View>
      <Spacer />
      <View style={styles.bedsField}>
        <FieldNumberStrict field={form.getField('singleBeds')} />
      </View>
      <View style={[Style.row, Style.alignCenter, { paddingTop: 20 }]}>
        <Spacer />
        <Pressable onPress={() => formGroupItem.remove()}>
          <GHText size="small" color="grey">
            Delete
          </GHText>
        </Pressable>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bedsField: {
    width: 200,
  },
});
