// @flow

import { Api } from '@guesthug/core';
import { rem } from 'polished';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Dimen } from '../constants';
import { MessagingRouter } from '../pods/messaging';
import FlashDisplayer from './FlashDisplayer';
import Header from './Header';
import LayoutBase from './LayoutBase';
import Nav from './Nav';

type Props = $ReadOnly<{|
  user: Api.User.V_Full,
  children: React.Node,
|}>;

export default function Layout({ children, user }: Props) {
  const location = useLocation();
  const [messagingVisible, setMessagingVisible] =
    React.useState<boolean>(false);
  const noContentWrapper = location.pathname.startsWith('/channels');

  return (
    <LayoutBase>
      <Container>
        <Header
          user={user}
          messagingVisible={messagingVisible}
          setMessagingVisible={setMessagingVisible}
        />
        <AreaMain>
          <Nav />
          <FlashDisplayer />
          {noContentWrapper ? (
            children
          ) : (
            <Content>
              {children}
              <BottomPadding />
            </Content>
          )}
          <MessagingRouter userId={user.id} visible={messagingVisible} />
        </AreaMain>
      </Container>
    </LayoutBase>
  );
}

const Container = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  background-color: #fafbfd;
`;

const AreaMain = styled('div')`
  display: flex;
  flex-shrink: 0;
  flex: 1;
  min-height: 0;
  align-items: stretch;
`;

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  flex-shrink: 0;
  min-height: 0;
  overflow-y: scroll;
  padding: ${rem(Dimen.spacing + 50)} ${rem(Dimen.spacing)} 0;
  position: relative;
`;

// Space for padding plus HelpPanel
const BottomPadding = styled('div')`
  height: ${rem(Dimen.spacing + 50)};
  flex-shrink: 0;
`;
