// @flow

import * as React from 'react';

import { EmptyMessageBlock } from '../../components';
import { logAnalyticsEvent } from '../../util';

export default function UnsupportedCurrencyMessage() {
  React.useEffect(() => {
    logAnalyticsEvent('host_services', 'unsupported_currency');
  }, []);

  return (
    <EmptyMessageBlock>
      We're not in your area yet. Send your request to contact@guesthug.com and
      we'll let you know when we're live.
    </EmptyMessageBlock>
  );
}
