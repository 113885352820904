// @flow

import { Api } from '@guesthug/core';
import { format, formatISO, isThisMonth, parseISO } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Dimen } from '../../constants';
import { ClassicCalendar } from '../../pods/calendar';
import DashboardBox from './DashboardBox';
import PropertySelect from './PropertySelect';

type Props = $ReadOnly<{|
  bookings: Array<Api.Booking.V_Dashboard>,
  properties: Array<Api.Property.V_Mine>,
|}>;

export default function BoxCalendar({ bookings, properties }: Props) {
  let firstMatch = bookings.find((b) =>
    isThisMonth(parseISO(b.startDate))
  )?.property;

  if (!firstMatch && properties.length > 0) {
    firstMatch = properties[0];
  }
  const [selected, setSelected] = React.useState<string | null>(
    firstMatch ? firstMatch['@id'] : null
  );

  const selectedBookings =
    selected && bookings.filter((b) => b.property['@id'] === selected);

  return (
    <DashboardBox tall>
      <PropertySelect
        properties={properties}
        onSelect={(id) => setSelected(id)}
        selected={selected}
        subTitle={selectedBookings && format(new Date(), 'MMMM y')}
      />
      <StyledAreaCalendar>
        <StyledClassicCalendar
          currentDate={formatISO(new Date(), { representation: 'date' })}
          events={
            selectedBookings
              ? selectedBookings.map((booking) => ({
                  start: booking.startDate.substring(0, 10),
                  end: booking.endDate.substring(0, 10),
                  //TODO: change this to navigate properly once navigation is set up
                  onClick: selectedBookings ? () => null : null,
                }))
              : []
          }
        />
      </StyledAreaCalendar>
    </DashboardBox>
  );
}

const StyledAreaCalendar = styled('div')`
  flex: 1;
  padding: ${rem(Dimen.spacing / 2)} 0;
`;

const StyledClassicCalendar = styled(ClassicCalendar)`
  height: 100%;
`;
