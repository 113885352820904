// @flow

import { Api } from '@guesthug/core';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { ContentLoading, ScreenError } from '../../components';
import { useAuthUser } from '../../hooks';
import SectionCurrency from './SectionCurrency';
import SectionHelp from './SectionHelp';
import SectionMessageTemplates from './SectionMessageTemplates';
import SectionSubscriptionActive from './SectionSubscriptionActive';
import SectionSubscriptionNone from './SectionSubscriptionNone';

export default function SettingsPage() {
  const { id } = useAuthUser();
  const apiUser = Api.User.useReadItemFull(id);

  if (apiUser.error) return <ScreenError {...apiUser} />;
  if (!apiUser.data) return <ContentLoading />;

  const user = apiUser.data;
  const { activeSubscription, messageTemplates, currency } = user;

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      {activeSubscription ? (
        <SectionSubscriptionActive iri={activeSubscription} />
      ) : (
        <SectionSubscriptionNone />
      )}
      <SectionCurrency currency={currency} />
      <SectionMessageTemplates
        messageTemplates={messageTemplates}
        invalidate={apiUser.invalidate}
      />
      <SectionHelp />
    </>
  );
}
