// @flow

import { Api } from '@guesthug/core';
import { isSameYear, parseISO, subYears } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Figure, TitledBox } from '../../components';
import { Dimen } from '../../constants';
import { calcChangePercent, formatPrice } from '../../util';

type Props = $ReadOnly<{|
  bookings: Array<Api.Booking.V_Analytics>,
  currency: string,
|}>;

export default function BoxOverallServicesRevenue({
  bookings,
  currency,
}: Props) {
  let priceThisYear = 0;
  let priceLastYear = 0;

  const thisYear = new Date();
  const lastYear = subYears(thisYear, 1);

  bookings.forEach((booking) => {
    booking.purchases.forEach((purchase) => {
      const { createdAt } = purchase;
      purchase.lineItems.forEach((lineItem) => {
        if (isSameYear(parseISO(createdAt), thisYear)) {
          priceThisYear += lineItem.price * lineItem.quantity;
        }
        if (isSameYear(parseISO(createdAt), lastYear)) {
          priceLastYear += lineItem.price * lineItem.quantity;
        }
      });
    });
  });

  const changePercent = calcChangePercent(priceThisYear, priceLastYear);

  return (
    <StyledTitledBox
      title={
        <>
          Overall Services Sold <b>This Year</b>
        </>
      }
      padded
    >
      <Figure
        value={formatPrice(priceThisYear, currency, { round: true })}
        changePercent={changePercent}
        yellow
      />
    </StyledTitledBox>
  );
}

const StyledTitledBox = styled(TitledBox)`
  margin-bottom: ${rem(Dimen.spacing)};
`;
