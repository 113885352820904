// @flow

import type { Dispatch, Id } from '../../types';

import { Api } from '@guesthug/core';
import { useApiCallable } from '@guesthug/core';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { flashShow } from '../../actions/flash';
import {
  ContentLoading,
  Modal,
  ReturnLink,
  ScreenError,
} from '../../components';
import { SubmitButton } from '../../form';
import LocalGuideForm from './LocalGuideForm';

type Props = $ReadOnly<{|
  match: { params: { propertyId: Id, id: Id } },
|}>;

export default function LocalGuideEditPage({ match }: Props) {
  const [savedOrDeleted, setSavedOrDeleted] = React.useState<boolean>(false);
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] =
    React.useState<boolean>(false);

  const { propertyId, id } = match.params;
  const callApi = useApiCallable();
  const dispatch = useDispatch<Dispatch>();

  const api = Api.LocalGuide.useReadItem(id);

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ContentLoading />;

  const localGuide = api.data;

  if (savedOrDeleted) {
    return <Redirect to={`/properties/${propertyId}/local-guide`} />;
  }

  const returnLink = (
    <ReturnLink to={`/properties/${propertyId}/local-guide`}>
      Return to Local Guide Overview
    </ReturnLink>
  );

  if (!localGuide) {
    return <Redirect to={`/properties/${propertyId}/local-guide`} />;
  }
  return (
    <>
      <Helmet>
        <title>{localGuide.name}</title>
      </Helmet>
      {deleteModalVisible && (
        <Modal
          title={`Really delete ${localGuide.name}?`}
          padded
          onDismiss={() => setDeleteModalVisible(false)}
        >
          <StyledSubmitButton
            isLoading={isDeleting}
            normalLabel="Delete Guide"
            loadingLabel="Deleting"
            onClick={async () => {
              setIsDeleting(true);
              const response = await callApi(`/local-guides/${id}`, {
                method: 'DELETE',
              });
              if (response) {
                setSavedOrDeleted(true);
              }
              setIsDeleting(false);
            }}
          />
        </Modal>
      )}

      {returnLink}
      <LocalGuideForm
        localGuide={localGuide}
        type={localGuide.type}
        onSubmit={async (values, { addSubmitError, setLoading }) => {
          setLoading(true);
          const response = await callApi(`/local-guides/${id}`, {
            method: 'PUT',
            jsonBody: {
              ...values,
              type: localGuide.type,
              property: propertyId,
            },
          });
          if (response) {
            setSavedOrDeleted(true);
            dispatch(flashShow('Local Guide Saved'));
          }
          setLoading(false);
        }}
        onDeleteClick={() => setDeleteModalVisible(true)}
      />
    </>
  );
}

const StyledSubmitButton = styled(SubmitButton)`
  margin: 0;
  align-self: flex-start;
`;
