// @flow

import type { FieldProp } from 'react-typed-form';

import * as React from 'react';

import FieldMoney from './FieldMoney';

type Props = $ReadOnly<{|
  ...React.ElementConfig<typeof FieldMoney>,
  field: FieldProp<number>,
|}>;

export default function FieldMoneyStrict(props: Props) {
  const { field } = props;
  return (
    <FieldMoney
      onChange={(e) => {
        const value = e.target.value.replace(/[^\d]/g, '');

        if (value === '' || isNaN(value) || !isFinite(value)) {
          field.handleValueChange(0);
          return;
        }

        field.handleValueChange(parseInt(value, 10));
      }}
      {...props}
      field={(field: any)}
    />
  );
}
