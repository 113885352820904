// @flow

import type { Dispatch } from '../../../types';

import { Api, Model } from '@guesthug/core';
import { useApiCallable } from '@guesthug/core';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { FormComponent } from 'react-typed-form';

import { flashShow } from '../../../actions/flash';
import {
  ContentLoading,
  Expander,
  GooglePlacesLookup,
  ScreenError,
} from '../../../components';
import {
  FieldInput,
  FieldMap,
  FormWrap,
  GroupPairFields,
  SubmitButton,
} from '../../../form';
import { logError } from '../../../util';

type Props = $ReadOnly<{|
  propertyId: string,
  invalidate: () => mixed,
|}>;

export default function SectionAddress({ propertyId, invalidate }: Props) {
  const [stage, setStage] = React.useState<'find' | 'form'>('find');

  const callApi = useApiCallable();
  const dispatch = useDispatch<Dispatch>();

  const api = Api.Property.useReadItemFull(propertyId);

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ContentLoading />;
  const property = api.data;

  const selectPostcode = async (placeId: string) => {
    // eslint-disable-next-line no-undef
    const service = new google.maps.places.PlacesService(
      document.createElement('div')
    );

    service.getDetails({ placeId }, (result: Object, status) => {
      // eslint-disable-next-line no-undef
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        logError(
          new Error(`getPlacePredictions returned ${status}`),
          'SectionAddress',
          { extras: { result } }
        );
        return;
      }
      const comp = (keys: string[]): string | null => {
        for (const key of keys) {
          for (const part of result.address_components) {
            if (part.types.includes(key)) {
              return part.long_name;
            }
          }
        }
        return null;
      };
      const loc = result.geometry.location;

      const updates = {
        addressCountry: comp(['country']),
        addressPostcode: comp(['postal_code']),
        addressHouseNumber: comp(['street_number']),
        addressHouseName: null,
        addressStreet: comp(['route']),
        addressTown: comp(['postal_town', 'neighborhood', 'locality']),
        addressLatitude: loc.lat(),
        addressLongitude: loc.lng(),
      };
      async function updateApi(values: $Shape<Api.Property.W>) {
        const response = await callApi(`/properties/${property.id}`, {
          method: 'PUT',
          jsonBody: values,
        });
        if (response) {
          api.invalidate();
          invalidate();
          setStage('form');
          dispatch(flashShow('Changes saved'));
        }

        await callApi(`/properties/${property.id}/content/push`, {
          method: 'POST',
          jsonBody: {},
        });
      }
      updateApi(updates);
    });
  };

  return (
    <FormComponent
      pristineValues={property}
      onSubmit={async (values, { addSubmitError, setLoading }) => {
        setLoading(true);
        const response = await callApi(`/properties/${property.id}`, {
          method: 'PUT',
          jsonBody: values,
        });
        if (response) {
          api.invalidate();
          invalidate();
          dispatch(flashShow('Changes saved'));
          setStage('find');
        }
        setLoading(false);

        await callApi(`/properties/${property.id}/content/push`, {
          method: 'POST',
          jsonBody: {},
        });
      }}
    >
      {({ getField, handleSubmit, isLoading, addSubmitError, setLoading }) => (
        <FormWrap handleSubmit={handleSubmit}>
          {stage === 'find' && (
            <GooglePlacesLookup
              placeType="geocode"
              onPredictionPress={(placeId) => {
                selectPostcode(placeId);
              }}
              initialQuery={property.addressPostcode || undefined}
            />
          )}

          {stage === 'form' && (
            <>
              <FieldMap
                fieldLatitude={getField('addressLatitude')}
                fieldLongitude={getField('addressLongitude')}
              />

              <GroupPairFields
                left={
                  <FieldInput
                    field={{
                      ...getField('addressCountry'),
                      label: 'Country',
                    }}
                  />
                }
                right={
                  <FieldInput
                    field={{
                      ...getField('addressPostcode'),
                      label: 'Postcode',
                    }}
                  />
                }
              />
              <GroupPairFields
                left={
                  <FieldInput
                    field={{
                      ...getField('addressHouseNumber'),
                      label: 'House / Flat Number',
                    }}
                  />
                }
                right={
                  <FieldInput
                    field={{
                      ...getField('addressHouseName'),
                      label: 'House / Flat Name',
                    }}
                  />
                }
              />
              <GroupPairFields
                left={
                  <FieldInput
                    field={{
                      ...getField('addressStreet'),
                      label: 'Street',
                    }}
                  />
                }
                right={
                  <FieldInput
                    field={{
                      ...getField('addressTown'),
                      label: 'Town',
                    }}
                  />
                }
              />

              <SubmitButton
                normalLabel={Model.Property.getSubmitButtonLabel(property)}
                isLoading={isLoading}
              />
            </>
          )}
        </FormWrap>
      )}
    </FormComponent>
  );
}
