// @flow

import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Color, Dimen } from '../constants';

type TabProps = $ReadOnly<{
  label: string,
  onClick: () => void,
  selected: boolean,
}>;

type Props = $ReadOnly<{|
  tabs: $ReadOnlyArray<TabProps>,
|}>;

const SimpleTabButton = ({ label, onClick, selected }: TabProps) => (
  <StyledTab onClick={onClick} selected={selected}>
    {label}
  </StyledTab>
);

const SimpleTabs = ({ tabs }: Props) => (
  <StyledContainer>
    {tabs.map((tab) => (
      <SimpleTabButton {...tab} key={tab.label} />
    ))}
  </StyledContainer>
);
export default SimpleTabs;

const TAB_HEIGHT = 48;

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: row;
  padding: ${rem(Dimen.spacing / 2)} ${rem(Dimen.spacing)} ${rem(Dimen.spacing)};
  border-bottom: 1px #e7ebf1 solid;
`;

const StyledTab = styled('a')`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(TAB_HEIGHT)};
  border-radius: ${rem(TAB_HEIGHT / 2)};
  background-color: ${(p) => (p.selected ? Color.faintBlue : '#EAF1FB')};
  color: ${(p) => (p.selected ? '#fff' : Color.fainterBlue)};
  margin: 0 ${rem(Dimen.spacing / 2)};
`;
