// @flow

import type { Dispatch } from '../../types';

import { AuthApi } from '@guesthug/core';
import { rem } from 'polished';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormComponent } from 'react-typed-form';
import styled from 'styled-components';

import { ExternalAreaBottom, ExternalPanel } from '../../components';
import * as config from '../../config';
import { Dimen } from '../../constants';
import {
  FieldInput,
  FormWrap,
  SubmitButton,
  createFormValidator,
} from '../../form';

class ConcreteForm extends FormComponent<{|
  email?: string | null,
  password?: string | null,
|}> {}

export default function LoginPage() {
  const dispatch = useDispatch<Dispatch>();
  const loginSubmit = AuthApi.useLoginSubmit();

  return (
    <ExternalPanel>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <AreaLogo>
        <img src={require('../../../assets/img/ui/logo_darkblue.svg')} />
      </AreaLogo>
      <AreaMain>
        <ConcreteForm
          validator={createFormValidator({
            email: { strictPresence: true, email: true },
            password: { strictPresence: true, length: { minimum: 8 } },
          })}
          alwaysRevalidateOnChange
          onSubmit={async (fields, { setLoading, addSubmitError }) => {
            setLoading(true);

            const result = await loginSubmit(fields.email, fields.password);
            if (result.type === 'success') {
              const { type, ...payload } = result;
              dispatch({
                type: 'LOGIN_SUCCESS',
                payload,
              });
            } else {
              setLoading(false);
              console.log(result);
              if (result.errorMessage) {
                addSubmitError('_form', 'Incorrect email or password');
              }
            }
          }}
        >
          {({ formErrorList, getField, handleSubmit, isLoading }) => (
            <FormWrap handleSubmit={handleSubmit} formErrorList={formErrorList}>
              <FieldInput field={getField('email')} external />
              <FieldInput
                field={getField('password')}
                secureTextEntry
                external
              />
              <ExternalAreaBottom>
                <SubmitButton
                  isLoading={isLoading}
                  normalLabel="Log In"
                  loadingLabel="Loading"
                />

                {/* <ConnectBookingcomButton /> */}

                <Link to="/register">Don't have an account?</Link>

                {/* <Link to="/forgot-password">Forgot password?</Link> */}

                <a href={config.MARKETING_ROOT} className="external">
                  Back to website
                </a>
              </ExternalAreaBottom>
            </FormWrap>
          )}
        </ConcreteForm>
      </AreaMain>
    </ExternalPanel>
  );
}

const AreaLogo = styled('div')`
  background-color: #fff;
  padding: ${rem(20)};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 ${rem(40)} rgba(0, 0, 0, 0.15);
  border-top-left-radius: ${rem(4)};
  border-top-right-radius: ${rem(4)};

  img {
    user-select: none;
    pointer-events: none;
    width: 100%;
    max-width: ${rem(180)};
    padding-top: ${rem(10)};
  }
`;

const AreaMain = styled('div')`
  padding: ${rem(Dimen.spacing)};
`;
