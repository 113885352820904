// @flow

import { Api, Color, Style } from '@guesthug/core';
import {
  GHText,
  IconNights,
  PaddedArea,
  Spacer,
} from '@guesthug/core/components';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { PressableLink } from '../../../components';

type Props = $ReadOnly<{|
  mode: 'minNights' | 'rate',
  property: Api.Property.BaseView,
|}>;

export default function PaneGuide({ mode, property }: Props) {
  const [introExpanded, setIntroExpanded] = React.useState<boolean>(false);

  return (
    <View>
      <PaddedArea all style={{ backgroundColor: Color.darkBlue }}>
        <GHText color="invertNormal" size="larger">
          Multi-calendar guide
        </GHText>
        <Spacer size={0.5} />
        <GHText size="small" color="faint">
          Bookings from all connected channels are displayed on your
          multi-calendar.{' '}
          {introExpanded ? (
            <GHText size="small" color="faint">
              Price per night and minimum stay settings can be selected for a
              date range and distributed to connected channels. Pricing and min
              stay edits set in multi-calendar will overrride the{' '}
              <PressableLink to={`/properties/${property.id}#pricing`}>
                <GHText size="small" color="interactive">
                  master rate plans
                </GHText>
              </PressableLink>
              .
            </GHText>
          ) : (
            <GHText
              size="small"
              color="interactive"
              onPress={() => setIntroExpanded(true)}
            >
              Read more...
            </GHText>
          )}
        </GHText>
      </PaddedArea>
      <PaddedArea all>
        {mode === 'minNights' ? (
          <View style={Style.row}>
            <View style={styles.legendSymbol}>
              <GHText color="grey" size="small">
                5
              </GHText>
              <Spacer size={0.15} />
              <IconNights />
            </View>
            <Spacer size={0.5} />
            <GHText color="faint" size="small">
              <GHText color="faint" size="small" weight="bold">
                Min stay
              </GHText>{' '}
              distributed to all connected portals for selected date range. Set
              automated orphan day controls{' '}
              <PressableLink to={`/properties/${property.id}#pricing`}>
                <GHText size="small" color="interactive">
                  here
                </GHText>
              </PressableLink>
              .
            </GHText>
          </View>
        ) : (
          <View style={Style.row}>
            <View style={styles.legendSymbol}>
              <GHText color="grey" size="small">
                £100
              </GHText>
            </View>
            <Spacer size={0.5} />
            <GHText color="faint" size="small">
              <GHText color="faint" size="small" weight="bold">
                Price per night
              </GHText>{' '}
              distributed to all connected portals before occupancy based
              adjustments and promtional discounts. The base price can be marked
              up to reflect channel specific comissions{' '}
              <PressableLink to={`/channels`}>
                <GHText size="small" color="interactive">
                  here
                </GHText>
              </PressableLink>
            </GHText>
          </View>
        )}
        <Spacer size={0.5} />

        <View style={Style.row}>
          <View style={styles.legendSymbol}>
            <View
              style={[styles.legendLine, { backgroundColor: Color.green }]}
            />
          </View>
          <Spacer size={0.5} />
          <GHText color="faint" size="small">
            {mode === 'minNights' ? (
              <>Date range with minimum stay override applied.</>
            ) : (
              <>Date range with manual pricing override applied.</>
            )}
          </GHText>
        </View>
        <Spacer size={0.5} />

        <View style={Style.row}>
          <View style={styles.legendSymbol}>
            <View
              style={[
                styles.legendLine,
                { backgroundColor: Color.vibrantBlue },
              ]}
            />
          </View>
          <Spacer size={0.5} />
          <GHText color="faint" size="small">
            Date range where pricing and min stay settings can be applied. To
            set property level default settings goto{' '}
            <PressableLink to={`/properties/${property.id}#pricing`}>
              <GHText size="small" color="interactive">
                manage pricing
              </GHText>
            </PressableLink>
          </GHText>
        </View>
      </PaddedArea>
    </View>
  );
}

const styles = StyleSheet.create({
  legendSymbol: {
    width: 30,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  legendLine: {
    width: 15,
    height: 3,
  },
});
