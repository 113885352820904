// @flow

import type { $Optional, FormObject } from 'react-typed-form';
import type { Choice } from '../../form/FieldSelect';
import type { LocalGuideType } from './typeHelpers';

import { Api } from '@guesthug/core';
import { ghApiRequest } from '@guesthug/core';
import * as React from 'react';
import { UntypedFormComponent } from 'react-typed-form';
import styled from 'styled-components';

import { ContentBox, GooglePlacesLookup, Hr } from '../../components';
import { Color } from '../../constants';
import {
  FieldInput,
  FieldMap,
  FieldSelect,
  FieldUpload,
  FormWrap,
  GroupPairFields,
  SubmitButton,
  createFormValidator,
} from '../../form';
import { entries } from '../../util';
import { categories } from './typeHelpers';

type FT = $Optional<Api.LocalGuide.BaseView>;

type Props = $ReadOnly<{|
  localGuide?: Api.LocalGuide.BaseView,
  property?: Api.Property.BaseView,
  type: LocalGuideType,
  onSubmit: (FT, FormObject<FT>) => mixed,
  onDeleteClick?: () => mixed,
|}>;

const hotspotCategoryChoices = (entries(categories).map(([key, val]) => ({
  value: key,
  label: val.label,
  iconSource: require(`../../../assets/img/local-guide/categories/${key}.png`),
})): $ReadOnlyArray<Choice>);

const selectPlace = async ({ placeId, getField, setLoading }) => {
  setLoading(true);
  // Proxy to the server for this call, as the JS SDK doesn't allow include a
  // Places photo refernce for some reason (only URL).
  const { result } = (await ghApiRequest(`/google-places-details/${placeId}`))
    .data;
  const photoRef =
    result.photos && result.photos.length
      ? result.photos[0].photo_reference
      : null;

  getField('placeId').handleValueChange(placeId);
  getField('placesPhotoReference').handleValueChange(photoRef);
  getField('websiteUrl').handleValueChange(result.website);
  getField('name').handleValueChange(result.name);
  getField('latitude').handleValueChange(result.geometry.location.lat);
  getField('longitude').handleValueChange(result.geometry.location.lng);
  setLoading(false);
};

export default function LocalGuideForm({
  localGuide,
  onSubmit,
  onDeleteClick,
  type,
  property,
}: Props) {
  const [manual, setManual] = React.useState<boolean>(
    localGuide ? !localGuide.placeId : false
  );
  return (
    <UntypedFormComponent
      pristineValues={{
        latitude: property?.addressLatitude,
        longitude: property?.addressLongitude,
        ...localGuide,
      }}
      validator={createFormValidator({
        name: { strictPresence: { mustDefine: !localGuide } },
        image: { upload: true },
      })}
      onSubmit={onSubmit}
    >
      {({ getField, handleSubmit, isLoading, setLoading }) => (
        <ContentBox>
          {onDeleteClick && (
            <StyledDeleteLink>
              <a onClick={onDeleteClick}>Delete this Guide</a>
            </StyledDeleteLink>
          )}
          <FormWrap handleSubmit={handleSubmit}>
            {!manual && !localGuide && (
              <>
                <GooglePlacesLookup
                  onPredictionPress={(placeId) =>
                    selectPlace({ placeId, getField, setLoading })
                  }
                  location={
                    property?.addressLatitude && property.addressLongitude
                      ? {
                          latitude: property.addressLatitude,
                          longitude: property.addressLongitude,
                        }
                      : undefined
                  }
                  radius={10000}
                />
                <Hr />
              </>
            )}

            {!manual && !getField('placeId').value && (
              <>
                <p>
                  Can't find your spot?{' '}
                  <StyledManualPrompt onClick={() => setManual(true)}>
                    Manually enter details
                  </StyledManualPrompt>
                </p>
                <Hr />
              </>
            )}

            {manual && (
              <>
                <FieldInput field={getField('name')} />
                <FieldMap
                  fieldLatitude={getField('latitude')}
                  fieldLongitude={getField('longitude')}
                />
                <FieldUpload
                  field={getField('image')}
                  existingImage={localGuide ? localGuide.image : null}
                />
                <Hr />
              </>
            )}

            <GroupPairFields
              left={
                <FieldSelect
                  field={getField('price')}
                  choices={[...Array(5)].map((_, i) => ({
                    value: i + 1,
                    label: '£'.repeat(i + 1),
                  }))}
                />
              }
              right={
                type === 'hotspot' ? (
                  <FieldSelect
                    field={getField('category')}
                    choices={hotspotCategoryChoices}
                  />
                ) : undefined
              }
            />
            <FieldInput field={getField('description')} multiline />
            <SubmitButton isLoading={isLoading} />
          </FormWrap>
        </ContentBox>
      )}
    </UntypedFormComponent>
  );
}

const StyledDeleteLink = styled('div')`
  display: flex;
  justify-content: flex-end;
  a {
    transition: color 0.25s;
    &:hover {
      color: ${Color.vibrantBlue};
    }
  }
`;

const StyledManualPrompt = styled('a')`
  color: ${Color.vibrantBlue};
  transition: color 0.25s;
  &:hover {
    color: ${Color.faintBlue};
  }
`;
